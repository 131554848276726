import { useState, useRef, Fragment, useEffect, useMemo } from 'react';
import Menu from "../components/menu";

import "bootstrap/dist/css/bootstrap.min.css";
// import "bootstrap/dist/js/bootstrap.bundle.min.js";
import "bootstrap/dist/js/bootstrap.min.js";
import DatePicker from "../components/datepick";

import { OverlayTrigger, Popover } from 'react-bootstrap';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTrash, faDownload, faRefresh, faFilter, faMinus, faCalendar, faAngleUp, faAngleDown, faTimesCircle, faLineChart, faEye, faCheck, faArrowUp, faArrowDown, faCircle, faCircleCheck,
  faTruckMedical
} from "@fortawesome/free-solid-svg-icons";

import {showLoading, hideLoading } from 'react-redux-loading-bar';
import Head from "../components/loading-bar";
import { useDispatch } from "react-redux";

import {
    CartesianGrid,
    Legend,
    Line,
    AreaChart,
    Area,
    LineChart,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis,
    BarChart,
    Bar,
    Cell,
    ComposedChart
  } from "recharts";
  import moment from "moment";
  import { scaleTime } from "d3-scale";
import { getAuthToken } from '../util/token';
import { autoType } from 'd3';
import apiClient from '../api/client';



  const data = [
    {
      date: 1683651600000, //9/5
      rank: 31
    },
    {
      date: 1684170000000, //15/5
      rank: 31
    },
    {
      date: 1684947600000, //24/5
      rank: 28
    },
    {
        date: 1685898000000, //4/6
        rank: 27
      },
    {
    date: 1686762000000, //14/6
    rank: 28
    },
    {
      date: 1687366800000, //21/6
      rank: 35
    },
    {
        date: 1687712400000, //25/6
        rank: 33
      },
    {
        date: 1688317200000, //2/7
        rank: 32
      },
    {
        date: 1688922000000,
        rank: 34
      },
  ];
  
function RankTracking() {
    const [rankDetail, setRankDetail] = useState([]);
    const [filteredRank, setFilteredRank] = useState([]);
    const [filter, setFilter] = useState([]);
    const [ date, setDate] = useState(['msg','']);
    const [filterRange, setFilterRange] = useState(['','']);
    const [searchKey, setSearchKey] = useState('');
    const [sort,setSort] = useState('');
    const [showResult, setShowResult] = useState(10);
    const [pagination, setPagination] = useState([]);
    const [selectedPage, setSelectedPage] = useState(1);
    const [currentPageRange, setCurrentPageRange] = useState(1);
    const [ selectedCategory, setSelectedCategory] = useState('');
    const [ appliedCategory, setAppliedCategory] = useState('');
    const [ searchURL, setSearchURL] = useState('');
    const [ rankingProgress, setRankingProgress] = useState([]);
    const [ kwRankProgress, setKwRankProgress ] = useState([]);
    const [kwRankingHist, setKwRankingHist] = useState([]);

    const [startDate, setStartDate] = useState((new Date()).getTime());
    const [endDate, setEndDate] = useState((new Date()).getTime());
    const [startDateComp, setStartDateComp] = useState((new Date()).getTime() - 86400*1000);
    const [endDateComp, setEndDateComp] = useState((new Date()).getTime() - 86400*1000);

    const lowerBound = useRef();
    const upperBound = useRef();
    const keySearch = useRef();
    const urlSearch = useRef();
    const [rankingDist, setRankingDist] = useState([]);
    const [ country, setCountry ] = useState('Country');
    const [ appliedCountry, setAppliedCountry ] = useState('Country');

    //date picker
    const [ domain, setDomain] = useState('gleads.vn');
    const [ trackingDomains, setTrackingDomains ] = useState([]);
    const [comparisonMode, setComparisonMode] = useState(true);
    const [ activePeriod, setActivePeriod] = useState('today')

    //Ranking history loading
    const [isLoading, setIsLoading] = useState(false);

    //split category control
    const [ splitByCat, setSplitByCat] = useState('Split by');
    const [displayPeriod, setDisplayPeriod] = useState('Daily');
    const [displayCategories, setDisplayCategories] = useState([]);
    const [category,setCategory] = useState([]);
    const [dcountry,setDCountry] = useState([]);
    const [freqChart, setFreqChart] = useState([]);
    const [disFreqChart, setDisFreqChat] = useState([]);

    //avg position and top-tier box
    const [compRankingProgress, setCompRankingProgress] = useState([]);
    const [compRankingDist, setCompRankingDist] = useState([]);
    // volatility index
    const [serpVolatility, setSerpVolatility] = useState([]);
    const [serpFreqChart, setSerpFreqChart] = useState([]);

    //get auth token
    const token = getAuthToken();
    const userName = localStorage.getItem('username');

    const dispatch = useDispatch();



    function applyDateRangeHandler(start, end, startcomp, endcomp, domain, comparisonMode, activePeriod) {
      // console.log(new Date(start), (new Date((new Date(start).toUTCString()))).getTime());
      setActivePeriod(activePeriod);
      setComparisonMode(comparisonMode);
      setDomain(domain);

      // console.log('end date :', end);
      // UTC time
      let unix_start_time = (new Date(Date.UTC(new Date(start).getFullYear(), new Date(start).getMonth(), new Date(start).getDate(),0,0,0))).getTime();
      let unix_end_time = (new Date(Date.UTC(new Date(end).getFullYear(), new Date(end).getMonth(), new Date(end).getDate(),0,0,0))).getTime();

      let unix_start_comp = (new Date(Date.UTC(new Date(startcomp).getFullYear(), new Date(startcomp).getMonth(), new Date(startcomp).getDate(),0,0,0))).getTime();
      let unix_end_comp = (new Date(Date.UTC(new Date(endcomp).getFullYear(), new Date(endcomp).getMonth(), new Date(endcomp).getDate(),0,0,0))).getTime();

      if (startcomp === '' || endcomp === ''){
        unix_start_comp = '';
        unix_end_comp = '';
      } else if (!comparisonMode){
        unix_start_comp = '';
        unix_end_comp = '';
      };
      
      setStartDate(c => unix_start_time);
      setEndDate(c => unix_end_time);
      setStartDateComp( c => unix_start_comp);
      setEndDateComp( c => unix_end_comp);
      // console.log(document.getElementById("startEnd"));
      document.getElementById("startEnd").value = `${start} - ${end}`;

      if(isNaN(unix_start_time) && isNaN(unix_end_time)) {
        unix_start_time = '';
        unix_end_time = '';
      }else if(isNaN(unix_end_time)){
        unix_end_time = '';
      } else if(isNaN(unix_start_time)){
        unix_start_time = '';
      };
      console.log(unix_start_time, unix_end_time, unix_start_comp, unix_end_comp);
      dispatch(showLoading());
      
      if (!comparisonMode){
        Promise.all(
          [
            apiClient.get(`/api/ranking-distribution?start=${unix_start_time}&end=${unix_end_time}&domain=${domain}`, {headers: {'X-User': userName}})
            .then (
                  ({data}) =>{
                    data.sort((a,b) => a.date - b.date)
                    // console.log('ranking dist', data)
                    setRankingDist(data);
                  }
              ),
          apiClient.get(`/api/ranking?start=${unix_start_time}&end=${unix_end_time}&domain=${domain}`, {headers: {'X-User': userName}})
          .then (
                ({data}) =>{
                  data.sort((a,b) => a.date - b.date)
                  console.log('ranking progress: ', data);
                  setRankingProgress(data);
                }
            ),
          apiClient.get(`/api/ranking/detail?start=${unix_start_time}&end=${unix_end_time}&startcomp=${unix_start_comp}&endcomp=${unix_end_comp}&domain=${domain}`, {headers: {'X-User': userName}})
          .then (
                  ({data}) =>{
                    console.log(data.length);
                    const d = data.map(item => {
                      if (item.Ranking !== null && item.Ranking !== 'Out of 100'){
                        item.Ranking = parseFloat(item.Ranking);
                      } else {
                        item.Ranking = 101;
                      };
                      return item
                    })
                      console.log(d);
                      setRankDetail(d);
                      setFilteredRank(d);
                      let newPagination = [];
                      for (let i = 1; i <= Math.ceil(data.length/showResult); i++){
                        newPagination.push(
                          <a class="page-link" href="#" onClick={(e) => {e.preventDefault();
                            setSelectedPage(i);
                            const el = document.querySelectorAll('li[class*=active]')[0];
                            el.className = 'page-item';
                            document.getElementById(`page-${i}`).className = 'page-item active';
                            }}>{i}
                          </a>
                        )
                      };
                      setPagination(newPagination);
                      const cat = [...new Set(data.map(item => item.Category))];
                      setCategory(['All Category', ...cat]);
                      if(domain === 'bbcincorp.com'){
                        const country = [...new Set(data.map(item => item.Country))];
                        setDCountry(['Clear All', ...country]);
                      };
                  }),
                  apiClient.post(`/api/serp-index`, 
                  {
                    start: unix_start_time,
                    end: unix_end_time,
                    domain: domain
                  }, {headers: {'X-User': userName}})
                  .then (
                      ({data}) =>{
                        console.log(data);
                        data.sort((a,b) => a.date - b.date);
                        setSerpVolatility(data);
                      })
                ]).then(
                    () => {return dispatch(hideLoading())}
                  ); 
      }else {
        Promise.all(
          [
            apiClient.get(`/api/ranking-distribution?start=${unix_start_time}&end=${unix_end_time}&domain=${domain}`, {headers: {'X-User': userName}})
            .then (
                  ({data}) =>{
                    data.sort((a,b) => a.date - b.date)
                    // console.log('ranking dist', rankingDist)
                    setRankingDist(data);
                  }
              ),
          apiClient.get(`/api/ranking-distribution?start=${unix_start_comp}&end=${unix_end_comp}&domain=${domain}`, {headers: {'X-User': userName}})
          .then (
                  ({data}) =>{
                    data.sort((a,b) => a.date - b.date)
                    setCompRankingDist(data);
                  }
              ),
          apiClient.get(`/api/ranking?start=${unix_start_time}&end=${unix_end_time}&domain=${domain}`, {headers: {'X-User': userName}})
          .then (
                ({data}) =>{
                  data.sort((a,b) => a.date - b.date)
                  setRankingProgress(data);
                }
            ),
          apiClient.get(`/api/ranking?start=${unix_start_comp}&end=${unix_end_comp}&domain=${domain}`, {headers: {'X-User': userName}})
          .then (
                ({data}) =>{
                  data.sort((a,b) => a.date - b.date)
                  setCompRankingProgress(data);
                }
            ),
          apiClient.get(`/api/ranking/detail?start=${unix_start_time}&end=${unix_end_time}&startcomp=${unix_start_comp}&endcomp=${unix_end_comp}&domain=${domain}`, {headers: {'X-User': userName}})
          .then (
                  ({data}) =>{
                    console.log(data.length);
                    const d = data.map(item => {
                      if (item.Ranking !== null && item.Ranking !== 'Out of 100'){
                        item.Ranking = parseFloat(item.Ranking);
                      } else {
                        item.Ranking = 101;
                      };
                      return item
                    })
                      setRankDetail(d);
                      setFilteredRank(d);
                      let newPagination = [];
                      for (let i = 1; i <= Math.ceil(data.length/showResult); i++){
                        newPagination.push(
                          <a class="page-link" href="#" onClick={(e) => {e.preventDefault();
                            setSelectedPage(i);
                            const el = document.querySelectorAll('li[class*=active]')[0];
                            el.className = 'page-item';
                            document.getElementById(`page-${i}`).className = 'page-item active';
                            }}>{i}
                          </a>
                        )
                      };
                      setPagination(newPagination);
                      const cat = [...new Set(data.map(item => item.Category))];
                      setCategory(['All Category', ...cat]);
                      if(domain === 'bbcincorp.com'){
                        const country = [...new Set(data.map(item => item.Country))];
                        setDCountry(['Clear All', ...country]);
                      };
                  }),
                  apiClient.post(`/api/serp-index`,
                    {
                      start: unix_start_time,
                      end: unix_end_time,
                      domain: domain
                    }, {headers: {'X-User': userName}})
                  .then (
                      ({data}) =>{
                        // console.log(data);
                        data.sort((a,b) => a.date - b.date);
                        setSerpVolatility(data);
                      })
                ]).then(
                    () => {return dispatch(hideLoading())}
                  );
      }
       
    };

    const datePicker = (
      <Popover id="popover-positioned-top" title="Popover bottom" style={{"max-width":"1000px", 'border': 'none'}}>
          <DatePicker 
              applyDateRangeHandler = {applyDateRangeHandler}
              start = {startDate}
              end = {endDate}
              startComp = {startDateComp}
              endComp = {endDateComp}
              trackingDomains = {trackingDomains}
              domain={domain}
              comparisonMode = {comparisonMode}
              activePeriod = {activePeriod}
          />
      </Popover>
    );

    useEffect(() => {

      let c_date = new Date();

      let c_day = c_date.getDate();
      let c_month = c_date.toLocaleString('en-US', {month: 'short'});
      let c_year = c_date.getFullYear();

      let c = `${c_month} ${c_day}, ${c_year}`

      document.getElementById("startEnd").value = `${c} - ${c}`;

      apiClient.get(`/api/tracking-domains`, {headers: {'X-User': userName}})
      .then (
                ({data}) =>{
                setTrackingDomains(data);
                }
            );
      apiClient.get(`/api/ranking-distribution?start=${startDate}&end=${endDate}&domain=${domain}`, {headers: {'X-User': userName}})
      .then (
            ({data}) =>{
              data.sort((a,b) => a.date - b.date)
              setRankingDist(data);
            }
        );
      apiClient.get(`/api/ranking?start=${startDate}&end=${endDate}&domain=${domain}`, {headers: {'X-User': userName}})
      .then (
            (data) =>{
              data.sort((a,b) => a.date - b.date)
              setRankingProgress(data);
            }
        );
      apiClient.get(`/api/ranking/detail?start=${startDate}&end=${endDate}&startcomp=${startDateComp}&endcomp=${endDateComp}&domain=${domain}`, {headers: {'X-User': userName}})
      .then (
          ({data}) =>{
              setRankDetail(data);
              setFilteredRank(data);
              let newPagination = [];
              for (let i = 1; i <= Math.ceil(data.length/showResult); i++){
                // console.log(i);
                newPagination.push(
                  <a class="page-link" href="#" onClick={(e) => {e.preventDefault();
                    setSelectedPage(i);
                    const el = document.querySelectorAll('li[class*=active]')[0];
                    el.className = 'page-item';
                    document.getElementById(`page-${i}`).className = 'page-item active';
                    }}>{i}
                  </a>
                )
              };
              setPagination(newPagination);
              const cat = [...new Set(data.map(item => item.Category))];
              setCategory(['All Category', ...cat]);
          });
    },[]);

    // useEffect(() => {
    //   // let start = Math.floor(new Date(startDate.current.value).getTime());
    //   // let end = Math.floor(new Date(endDate.current.value).getTime())
    //   dispatch(showLoading());
    //   let start = startDate;
    //   let end = endDate;
    //   console.log(appliedCountry, appliedCategory);
    //   if(isNaN(start) && isNaN(end)) {
    //     start = '';
    //     end = '';
    //   }else if(isNaN(end)){
    //     end = '';
    //   } else if(isNaN(start)){
    //     start = '';
    //   }

    //   Promise.all([fetch(`${process.env.REACT_APP_HOST_IP}/api/ranking-distribution?start=${start}&end=${end}&domain=${domain}&country=${appliedCountry}&category=${appliedCategory}`).then(
    //         response => {
    //             return response.json()
    //         }
    //     ).then (
    //         (data) =>{
    //           data.sort((a,b) => a.date - b.date)
    //           setRankingDist(data);
    //         }
    //     ),
    //   fetch(`${process.env.REACT_APP_HOST_IP}/api/ranking?start=${start}&end=${end}&domain=${domain}&country=${appliedCountry}&category=${appliedCategory}`).then(
    //     response => {
    //         return response.json()
    //     }
    //     ).then (
    //         (data) =>{
    //           data.sort((a,b) => a.date - b.date)
    //           setRankingProgress(data);
    //         }
    //     )]).then (
    //       () => {return dispatch(hideLoading())}
    //     );
    // },[appliedCountry, appliedCategory])

    useEffect(() => {
      setCurrentPageRange(1);
      setSelectedPage(1);
      let newPagination = [];
      const dummy_var = rankDetail.filter((item, index) => applyFilterHandler(item))
      setFilteredRank(dummy_var);
      // console.log(dummy_var.length);
      for (let i = 1; i <= Math.ceil(dummy_var.length/showResult); i++){
        newPagination.push(
          <a class="page-link" href="#" onClick={(e) => {e.preventDefault();
            setSelectedPage(i);
            const el = document.querySelectorAll('li[class*=active]')[0];
            el.className = 'page-item';
            document.getElementById(`page-${i}`).className = 'page-item active';
            }}>{i}
          </a>
        )
      };
      setPagination(newPagination);
    },[filterRange, searchKey, searchURL, showResult, selectedCategory, country]);


    const getXAxisArgsForTimeBasedGraph = (numericValues) => {
        const maxValue = Math.max(...numericValues);
        const minValue = Math.min(...numericValues);
        const timeScale = scaleTime().domain([minValue, maxValue]).nice(5);
      
        return {
          scale: timeScale,
          type: "number",
          domain: timeScale.domain(),
          tickFormatter: (unixTime) => moment(unixTime).format('YYYY-MM-DD'),
          ticks: timeScale.ticks(5)
        };
      };

    const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
        // console.log(payload);
        return (
        <div className="custom-tooltip">
            <p className="label">{`Date: ${moment(label).format('YYYY-MM-DD')}`}</p>
            {
                (payload.length === 1 && payload[0].name !== 'update_index') ?
                  <p className="desc">{`${payload[0].name}: ${payload[0].value === 101 ? 'Out top 100' : payload[0].value}`}</p> :
                (payload.length === 1 && payload[0].name === 'update_index') ?
                  payload.map(item => {
                    return (
                      <>
                      {item.payload.google_updates.map(it => (
                        <p className="desc">{`${it.update_name}:`}<br />{`${it.duration}`}</p>
                      ))}
                      </>
                  )
                  })
                : 
                    <>
                      <p className="desc">{`${payload.filter(item => item.name !== 'update_index')[0].name}: ${payload.filter(item => item.name !== 'update_index')[0].value}`}</p>
                      {
                        payload.filter(item => item.name === 'update_index').map(item => {
                          return item.payload.google_updates.map(it => (
                            <p className="desc">{`${it.update_name}:`}<br />{`${it.duration}`}</p>
                          ))
                        })
                      }
                    </>
            }
            
        </div>
        );
        }
    };

    const toPercent = (decimal, fixed = 0) => `${(decimal * 100).toFixed(fixed)}%`;

    const getPercent = (value, total) => {
        const ratio = total > 0 ? value / total : 0;

        return toPercent(ratio, 2);
    };

    const renderTooltipContent = (o) => {
        const { payload = [], label } = o;
        const total = payload.reduce(
          (result, entry) => result + entry.value,
          0
        );
      
        return (
          <div className="customized-tooltip-content">
            <p className="total">{`${moment(label).format('YYYY-MM-DD')} (Total: ${total})`}</p>
            <ul className="list">
              {payload.map((entry, index) => (
                <li key={`item-${index}`} style={{ color: entry.color }}>
                  {`${entry.name}: ${entry.value}(${getPercent(entry.value, total)})`}
                </li>
              ))}
            </ul>
          </div>
        );
      };

      const renderLineTooltipContent = (o) => {
        const { payload = [], label } = o;      
        return (
          <div className="customized-tooltip-content">
            <p className="total">{`${moment(label).format('YYYY-MM-DD')}`}</p>
            <ul className="list">
              {payload !== null &&
              payload.map((entry, index) => (
                <li key={`item-${index}`} style={{ color: entry.color }}>
                  {`${entry.name}: ${entry.value === 101 ? 'Out top 100' : entry.value}`}
                </li>
              ))}
            </ul>
          </div>
        );
      };

    const filterHandler = (e, mode) => {
      e.preventDefault();
      setFilter(current => [...current, mode]);
      // console.log(filterRange, searchKey);
      // if (mode === 'Keywords'){
      //     setFilterRange(['','']);
      // }else if (mode === 'No. of Keywords'){
      //     setSearchKey('');
      // }
    };

    const sortHandler = (mode) => {
      if (mode === 'kw'){
        if (sort !== 'keyword_inc' && sort !== 'keyword_dec'){
          setSort('keyword_inc');
          filteredRank.sort((a,b) => (b.Keyword > a.Keyword) ? -1 : (b.Keyword < a.Keyword) ? 1 : 0);
        } else if(sort === 'keyword_dec'){
          setSort('keyword_inc');
          filteredRank.sort((a,b) => (b.Keyword > a.Keyword) ? -1 : (b.Keyword < a.Keyword) ? 1 : 0);
        } else if(sort === 'keyword_inc'){
          setSort('keyword_dec');
          filteredRank.sort((a,b) => (b.Keyword > a.Keyword) ? 1 : (b.Keyword < a.Keyword) ? -1 : 0);
        };
      } else if (mode === 'pos') {
        console.log(filteredRank);
        if (sort !== 'pos_inc' && sort !== 'pos_dec'){
          setSort('pos_inc');
          filteredRank.sort((a,b) => {
            if(a.Ranking === 'Out of 100') return 1;
            if(b.Ranking === 'Out of 100') return -1;
            return (b.Ranking > a.Ranking) ? -1 : (b.Ranking < a.Ranking) ? 1 : 0
          });
        } else if(sort === 'pos_inc'){
          setSort('pos_dec');
          filteredRank.sort((a,b) => {
            if(a.Ranking === 'Out of 100') return -1;
            if(b.Ranking === 'Out of 100') return 1;
            return (b.Ranking > a.Ranking) ? 1 : (b.Ranking < a.Ranking) ? -1 : 0
          });
        } else if(sort === 'pos_dec'){
          setSort('pos_inc');
          filteredRank.sort((a,b) => {
            if(a.Ranking === 'Out of 100') return 1;
            if(b.Ranking === 'Out of 100') return -1;
            return (b.Ranking > a.Ranking) ? -1 : (b.Ranking < a.Ranking) ? 1 : 0
          });
        };
      } else if (mode === 'vol') {
        if (sort !== 'vol_inc' && sort !== 'vol_dec'){
          setSort('vol_inc');
          filteredRank.sort((a,b) => {
            if(a.Volume === null) return 1;
            if(b.Volume === null) return -1;
            return (b.Volume > a.Volume) ? -1 : (b.Volume < a.Volume) ? 1 : 0
          });
        } else if(sort === 'vol_inc'){
          setSort('vol_dec');
          filteredRank.sort((a,b) => {
            if(a.Volume === null) return -1;
            if(b.Volume === null) return 1;
            return (b.Volume > a.Volume) ? 1 : (b.Volume < a.Volume) ? -1 : 0
          });
        } else if(sort === 'vol_dec'){
          setSort('vol_inc');
          filteredRank.sort((a,b) => {
            if(a.Volume === null) return 1;
            if(b.Volume === null) return -1;
            return (b.Volume > a.Volume) ? -1 : (b.Volume < a.Volume) ? 1 : 0
          });
        };
      } else if (mode === 'prev_pos') {
        if (sort !== 'prev_pos_inc' && sort !== 'prev_pos_dec'){
          setSort('prev_pos_inc');
          filteredRank.sort((a,b) => {
            if(a.Prev === null) return 1;
            if(b.Prev === null) return -1;
            return (b.Prev > a.Prev) ? -1 : (b.Prev < a.Prev) ? 1 : 0
          });
        } else if(sort === 'prev_pos_inc'){
          setSort('prev_pos_dec');
          filteredRank.sort((a,b) => {
            if(a.Prev === null) return -1;
            if(b.Prev === null) return 1;
            return (b.Prev > a.Prev) ? 1 : (b.Prev < a.Prev) ? -1 : 0
          });
        } else if(sort === 'prev_pos_dec'){
          setSort('prev_pos_inc');
          filteredRank.sort((a,b) => {
            if(a.Prev === null) return 1;
            if(b.Prev === null) return -1;
            return (b.Prev > a.Prev) ? -1 : (b.Prev < a.Prev) ? 1 : 0
          });
        };
      } else {
        // console.log('other sort');
        if (sort !== `${mode}_inc` && sort !== `${mode}_dec`){
          setSort(`${mode}_inc`);
          filteredRank.sort((a,b) => {
            if(a[mode] === 'N/A') return 1;
            if(b[mode] === 'N/A') return -1;
            return (b[mode] > a[mode]) ? -1 : (b[mode] < a[mode]) ? 1 : 0
          });
        } else if(sort === `${mode}_inc`){
          setSort(`${mode}_dec`);
          filteredRank.sort((a,b) => {
            if(a[mode] === 'N/A') return -1;
            if(b[mode] === 'N/A') return 1;
            return (b[mode] > a[mode]) ? 1 : (b[mode] < a[mode]) ? -1 : 0
          });
        } else if(sort === `${mode}_dec`){
          setSort(`${mode}_inc`);
          filteredRank.sort((a,b) => {
            if(a[mode] === 'N/A') return 1;
            if(b[mode] === 'N/A') return -1;
            return (b[mode] > a[mode]) ? -1 : (b[mode] < a[mode]) ? 1 : 0
          });
        };
      }
  };

  const clearFilterHandler = (e, mode) =>{
    const newFilter = filter.filter(item => item !== mode);
    setFilter(newFilter);
    if(mode === 'Category'){
      setSelectedCategory('');
      // setAppliedCategory('');
    }else if (mode === 'Position'){
      if (filterRange[0] === '' && filterRange[1] === '') return;
      setFilterRange(['','']);
    }else if (mode === 'Keywords'){
      setSearchKey('');
    }else if (mode === 'URL'){
      setSearchURL('');
    } else if (mode === 'Country'){
      setCountry('Country');
      // setAppliedCountry('Country');
      // console.log(rankingDist, appliedCountry);
    }
  };

  const changeShowResultHandler= (result) => {
    setCurrentPageRange(1);
    setSelectedPage(1);
    setShowResult(result);
  };

  // function to escape special characters in regex search
  function escapeRegExp(text) {
    return text.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
  }


  const filterModeHandler = () => {
    // setFilterMode(current => !current);
    
    if(filter.includes('Position') === true) setFilterRange(current => [lowerBound.current.value,upperBound.current.value]);
    if(filter.includes('Keywords') === true) {
      const search_key_mode = document.getElementById("kw-search-mode").innerHTML;
      let search_key = keySearch.current.value;
      if (search_key_mode === 'E') {
        search_key = `^${keySearch.current.value}$`;
      }
      setSearchKey(c => search_key)
    };
    if(filter.includes('URL') === true) {
      const search_url_mode = document.getElementById("url-search-mode").innerHTML;
      let search_url = urlSearch.current.value;
      if (search_url_mode === 'E') {
        search_url = `^${search_url}$`;
      };
      // console.log(search_url);
      setSearchURL(c => search_url)
    };
    // if(filter.includes('Country') === true) setAppliedCountry(country);
    // if(filter.includes('Category') === true) setAppliedCategory(selectedCategory);
  };


  const applyFilterHandler = (item) => {
    console.log('re render');
    return(
      (domain === 'bbcincorp.com' && (country === 'Offshore' || country ==='HK' || country ==='SG' || country ==='VN') ? item.Folder === country : true) &&
      (domain === 'bbcincorp.com' && country !== '' && country !== 'Country' && country !== 'Offshore' && country !=='HK' && country !=='SG' && country !=='VN' ? item.Country === country : true) &&
      (selectedCategory !== '' && selectedCategory !== 'All Category' ? item.Category === selectedCategory : true) &&
      (searchURL !== '' ? item.URL.search(new RegExp(searchURL, 'i')) !== -1 : true) &&
      (searchKey !== '' ? item.Keyword.search(new RegExp(searchKey, 'i')) !== -1 : true) &&
      ((filterRange[0] !== '' && item.Ranking !== null) ? item.Ranking >= filterRange[0] : (filterRange[0] !== '' && item.Ranking === null) ? false : true) &&
      ((filterRange[1] !== '' && item.Ranking !== null) ? item.Ranking < filterRange[1] : (filterRange[1] !== '' && item.Ranking === null) ? false : true));
      // setRankDetail(cuurent => filteredArray);
  };

  const updateRankingHandler = (start, end) => {
    // console.log(start, end);
    if(isNaN(start) && isNaN(end)) {
      start = '';
      end = '';
    }else if(isNaN(end)){
      end = '';
    } else if(isNaN(start)){
      start = '';
    }
    apiClient.get(`/api/ranking-distribution?start=${start}&end=${end}&domain=${domain}`, {headers: {'X-User': userName}})
    .then (
            ({data}) =>{
              data.sort((a,b) => a.date - b.date)
              setRankingDist(data);
            }
        );
    apiClient.get(`/api/ranking?start=${start}&end=${end}&domain=${domain}`, {headers: {'X-User': userName}})
    .then (
          ({data}) =>{
            data.sort((a,b) => a.date - b.date)
            setRankingProgress(data);
          }
      );
    apiClient.get(`/api/ranking/detail?start=${start}&end=${end}&domain=${domain}`, {headers: {'X-User': userName}})
    .then (
            ({data}) =>{
              // console.log(data.length);
                setRankDetail(data);
                let newPagination = [];
                for (let i = 1; i <= Math.ceil(data.length/showResult); i++){
                  newPagination.push(
                    <a class="page-link" href="#" onClick={(e) => {e.preventDefault();
                      setSelectedPage(i);
                      const el = document.querySelectorAll('li[class*=active]')[0];
                      el.className = 'page-item';
                      document.getElementById(`page-${i}`).className = 'page-item active';
                      }}>{i}
                    </a>
                  )
                };
                setPagination(newPagination);
                const cat = [...new Set(data.map(item => item.Category))];
                setCategory(['All Category', ...cat]);
            })
  };

  const popoverChart = (
    <Popover id="popover-positioned-top" title="Popover bottom" style={{"width":"300px", 'height': '180px'}} className="d-flex">
      <ResponsiveContainer width="100%" height="110%">
          <AreaChart
          data={kwRankProgress}
          margin={{
              top: 10,
              right: 10,
              left: -30,
              bottom: 10,
          }}
          >
            <defs>
              <linearGradient id="color1" x1="0" y1="0" x2="0" y2="1">
                <stop offset="5%" stopColor="#82ca9d" stopOpacity={0.8}/>
                <stop offset="95%" stopColor="#82ca9d" stopOpacity={0}/>
              </linearGradient>
            </defs>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis
                dataKey="date"
                name = "date"
                // tickFormatter = {(unixTime) => moment(unixTime).format('HH:mm YYYY-MM-DD')}
                {...getXAxisArgsForTimeBasedGraph(data.map((item) => item.date))}

            />
            <YAxis 
                domain={[0, 100]}
                reversed
            />
            <Tooltip 
                content={splitByCat ? renderLineTooltipContent : <CustomTooltip />}
                wrapperStyle={{ backgroundColor: '#f8f9fa', padding: '2px' }}
            />
            <Area type="monotone" isAnimationActive={false} dataKey="rank" stroke="#82ca9d" fillOpacity={1} fill="url(#color1)"/>
          </AreaChart>
      </ResponsiveContainer>
    </Popover>
    );
  
    const KwRankHandler = (kw, url) => {
      // let start = Math.floor(new Date(startDate.current.value).getTime());
      // let end = Math.floor(new Date(endDate.current.value).getTime())
      let start = startDate;
      let end = endDate;

      // console.log(appliedCountry, appliedCategory);
      if(isNaN(start) && isNaN(end)) {
        start = '';
        end = '';
      }else if(isNaN(end)){
        end = '';
      } else if(isNaN(start)){
        start = '';
      }
      const input = {
        'start': start,
        'end': end,
        'keyword': kw,
        'url': url,
        'domain': domain
      }
      // console.log(input)
      apiClient.post(`/api/keyword-rank`, input, {headers: {'X-User': userName}})
      .then (
            ({data}) =>{
              data.sort((a,b) => a.date - b.date);
              console.log('kw rank: ', data);
              setKwRankProgress(data);
            }
        )
    };

    const downloadRankReportHandler = (rank) => {
      if(rank.length === 0){
        return;
      } else{
        dispatch(showLoading());
        // console.log(outline);
        apiClient.post(`/api/download/ranking-report`, rank, {headers: {'X-User': userName}})
        .then(
          response => {
            dispatch(hideLoading());
            return response.data;
          }).then(blob => {
            const url = window.URL.createObjectURL(blob);
            // console.log(blob);
            const link = document.createElement("a");
            link.download = 'ranking_report.xlsx';
            link.href = url;
            link.click();
          }).catch(error => {
            console.error(error);
        });
      };
    };

    const viewRankingHistHandler = (kw, url, ranking_page) => {

      setKwRankingHist([]);
      const input = {
        keyword: kw,
        url: url,
        ranking_page: ranking_page,
        domain: domain
      };
      // console.log(input);
      setIsLoading(true);
      apiClient.post(`/api/ranking/history`, input, {headers: {'X-User': userName}})
      .then (
          ({data}) =>{
            // console.log(data);
            data.sort((a,b) => a.Date.localeCompare(b.Date));
            const d = data.map(item => {
              if (item.Ranking !== null && item.Ranking !== 'Out of 100'){
                item.Ranking = parseFloat(item.Ranking);
              } else {
                item.Ranking = 101;
              };
              return item
            });
            setIsLoading(false);
            setKwRankingHist(c => d);
          }
      ).catch(error => {
            setIsLoading(false);
            console.error(error);
        });
    };

    const changeDisplayFreqHandler = (start, end, freq) => {
      console.log(freq);
      setDisplayPeriod(freq);
      if (freq === 'Weekly') {
        const msInWeek = 1000*60*60*24*7;
        const curYear = new Date().getFullYear();
        const first_week = Math.ceil((start - new Date(curYear, 0, 1).getTime())/msInWeek);
        const total_week = Math.round(Math.abs(start - end)/msInWeek);
        let weekly_detail = [];
        let weekly_dist_detail = [];
        let weekly_serp_detail = [];
        for (let i = 0 ; i <=total_week;  i++){
          let week_object = {
            // ...t,
            date: new Date(curYear, 0, 1).getTime() + (first_week + i)*msInWeek
          };
          rankingProgress.filter(item => (
            (Math.ceil( (item.date - new Date(curYear, 0, 1).getTime()) /msInWeek ) >= (first_week + i)) && 
            (Math.ceil( (item.date - new Date(curYear, 0, 1).getTime()) /msInWeek) < (first_week + i+1)) )).map((item, index) => {
            // console.log(item.date, Math.ceil((item.date - new Date(curYear, 0, 1))/msInWeek),(first_week + i+1));
            Object.keys(item).map(it => {
              if (it !== 'date'){
                if (!(it in Object.keys(week_object))) {
                  week_object[it] = [item[it]];
                } else {
                  week_object[it].push(item[it]);
                };
              }
            });
          });
          weekly_detail.push(week_object);
          
          // serp volatility chart
          let week_serp_object = {
            // ...t,
            date: new Date(curYear, 0, 1).getTime() + (first_week + i)*msInWeek
          };
          serpVolatility.filter(item => (
            (Math.ceil( (item.date - new Date(curYear, 0, 1).getTime()) /msInWeek ) >= (first_week + i)) && 
            (Math.ceil( (item.date - new Date(curYear, 0, 1).getTime()) /msInWeek) < (first_week + i+1)) )).map((item, index) => {
            // console.log(item.date, Math.ceil((item.date - new Date(curYear, 0, 1))/msInWeek),(first_week + i+1));
            Object.keys(item).map(it => {
              if (it !== 'date'){
                if (!(it in Object.keys(week_serp_object))) {
                  week_serp_object[it] = [item[it]];
                } else {
                  week_serp_object[it].push(item[it]);
                };
              }
            });
          });
          weekly_serp_detail.push(week_serp_object);

          let week_dist_object = {
            // ...t,
            date: new Date(curYear, 0, 1).getTime() + (first_week + i)*msInWeek
          };
          rankingDist.filter(item => (
            (Math.ceil( (item.date - new Date(curYear, 0, 1).getTime()) /msInWeek ) >= (first_week + i)) && 
            (Math.ceil( (item.date - new Date(curYear, 0, 1).getTime()) /msInWeek) < (first_week + i+1)) )).map((item, index) => {
            // console.log(item.date, Math.ceil((item.date - new Date(curYear, 0, 1))/msInWeek),(first_week + i+1));
            Object.keys(item).map(it => {
              if (it !== 'date' && [...category, ...dcountry].includes(it)){
                if (!(it in Object.keys(week_dist_object))) week_dist_object[it] = {};
                Object.keys(item[it]).map(top_tier => {
                  if (!(top_tier in Object.keys(week_dist_object[it]))) week_dist_object[it][top_tier] = [];
                  week_dist_object[it][top_tier].push(item[it][top_tier]);
                })
              } else if (it !== 'date') {
                if (!(it in Object.keys(week_dist_object))) {
                  week_dist_object[it] = [item[it]];
                } else {
                  week_dist_object[it].push(item[it]);
                }
              }
            });
          });
          weekly_dist_detail.push(week_dist_object);
        };

        weekly_detail = weekly_detail.filter(item => Object.keys(item).length > 1);
        weekly_detail.map(item => {
            Object.keys(item).map(it => {
              if (it !== 'date'){
                if (item[it].length > 0) item[it] = item[it].reduce((a,b) => a + b, 0)/item[it].length;
              }
            })
        });
        // console.log(weekly_detail);
        setFreqChart(weekly_detail);

        // serp volatility
        weekly_serp_detail = weekly_serp_detail.filter(item => Object.keys(item).length > 1);
        weekly_serp_detail.map(item => {
            Object.keys(item).map(it => {
              if (it !== 'date'){
                if (item[it].length > 0) item[it] = item[it].reduce((a,b) => a + b, 0)/item[it].length;
              }
            })
        });
        // console.log(weekly_detail);
        setSerpFreqChart(weekly_serp_detail);
        
        weekly_dist_detail = weekly_dist_detail.filter(item => Object.keys(item).length > 1);
        weekly_dist_detail.map(item => {
          Object.keys(item).map(it => {
            if (it !== 'date' && [...category, ...dcountry].includes(it)){
              Object.keys(item[it]).map(top_tier => {
                if (item[it][top_tier].length > 0) item[it][top_tier] = item[it][top_tier].reduce((a,b) => a + b, 0)/item[it][top_tier].length;
              })
              if (item[it].length > 0) item[it] = item[it].reduce((a,b) => a + b, 0)/item[it].length;
            } else if (it !== 'date'){
              if (item[it].length > 0) item[it] = item[it].reduce((a,b) => a + b, 0)/item[it].length;
            }
          });
        });
        // console.log(weekly_dist_detail);
        setDisFreqChat(weekly_dist_detail);
        
      } else if(freq === 'Monthly') {
        const startMonth = new Date(start).getMonth();
        const startYear = new Date(start).getFullYear();
        const endMonth = new Date(end).getMonth();
        const endYear = new Date(end).getFullYear();
        const totalMonth = endMonth - startMonth + 12*(endYear - startYear);
        console.log(
        endMonth - startMonth + 12*(endYear - startYear)
        );
        let monthly_detail = [];
        let monthly_dist_detail = [];
        let monthly_serp_detail = [];
        for (let i = 0 ; i <=totalMonth;  i++){
          let monthly_object = {
            // ...t,
            date: new Date(startYear, startMonth + i, 1).getTime()
          };
          rankingProgress.filter(item => (
            (item.date >= new Date(startYear, startMonth + i, 1).getTime()) && 
            (item.date < new Date(startYear, startMonth + i + 1, 1).getTime()) )).map((item, index) => {
            // console.log(item.date, Math.ceil((item.date - new Date(curYear, 0, 1))/msInWeek),(first_week + i+1));
            Object.keys(item).map(it => {
              if (it !== 'date'){
                if (!(it in Object.keys(monthly_object))) {
                  monthly_object[it] = [item[it]];
                } else {
                  monthly_object[it].push(item[it]);
                };
              }
            });
          });
          // console.log(week_object);
          monthly_detail.push(monthly_object);

          // serp volatility
          let monthly_serp_object = {
            // ...t,
            date: new Date(startYear, startMonth + i, 1).getTime()
          };
          serpVolatility.filter(item => (
            (item.date >= new Date(startYear, startMonth + i, 1).getTime()) && 
            (item.date < new Date(startYear, startMonth + i + 1, 1).getTime()) )).map((item, index) => {
            // console.log(item.date, Math.ceil((item.date - new Date(curYear, 0, 1))/msInWeek),(first_week + i+1));
            Object.keys(item).map(it => {
              if (it !== 'date'){
                if (!(it in Object.keys(monthly_serp_object))) {
                  monthly_serp_object[it] = [item[it]];
                } else {
                  monthly_serp_object[it].push(item[it]);
                };
              }
            });
          });
          // console.log(week_object);
          monthly_serp_detail.push(monthly_serp_object);

          let month_dist_object = {
            // ...t,
            date: new Date(startYear, startMonth + i, 1).getTime()
          };
          rankingDist.filter(item => (
            (item.date >= new Date(startYear, startMonth + i, 1).getTime()) && 
            (item.date < new Date(startYear, startMonth + i + 1, 1).getTime()) )).map((item, index) => {
            // console.log(item.date, Math.ceil((item.date - new Date(curYear, 0, 1))/msInWeek),(first_week + i+1));
            Object.keys(item).map(it => {
              if (it !== 'date' && [...category, ...dcountry].includes(it)){
                if (!(it in Object.keys(month_dist_object))) month_dist_object[it] = {};
                Object.keys(item[it]).map(top_tier => {
                  if (!(top_tier in Object.keys(month_dist_object[it]))) month_dist_object[it][top_tier] = [];
                  month_dist_object[it][top_tier].push(item[it][top_tier]);
                })
              } else if (it !== 'date') {
                if (!(it in Object.keys(month_dist_object))) {
                  month_dist_object[it] = [item[it]];
                } else {
                  month_dist_object[it].push(item[it]);
                }
              }
            });
          });
          monthly_dist_detail.push(month_dist_object);
        };

        monthly_detail = monthly_detail.filter(item => Object.keys(item).length > 1);
        monthly_detail.map(item => {
          Object.keys(item).map(it => {
            if (it !== 'date'){
              if (item[it].length > 0) item[it] = item[it].reduce((a,b) => a + b, 0)/item[it].length;
            }
          });
        });
        // console.log(monthly_detail);
        setFreqChart(monthly_detail);

        // serp volatility
        monthly_serp_detail = monthly_serp_detail.filter(item => Object.keys(item).length > 1);
        monthly_serp_detail.map(item => {
          Object.keys(item).map(it => {
            if (it !== 'date'){
              if (item[it].length > 0) item[it] = item[it].reduce((a,b) => a + b, 0)/item[it].length;
            }
          });
        });
        // console.log(monthly_detail);
        setSerpFreqChart(monthly_serp_detail);

        monthly_dist_detail = monthly_dist_detail.filter(item => Object.keys(item).length > 1);
        monthly_dist_detail.map(item => {
          Object.keys(item).map(it => {
            if (it !== 'date' && [...category, ...dcountry].includes(it)){
              Object.keys(item[it]).map(top_tier => {
                if (item[it][top_tier].length > 0) item[it][top_tier] = item[it][top_tier].reduce((a,b) => a + b, 0)/item[it][top_tier].length;
              })
              if (item[it].length > 0) item[it] = item[it].reduce((a,b) => a + b, 0)/item[it].length;
            } else if (it !== 'date'){
              if (item[it].length > 0) item[it] = item[it].reduce((a,b) => a + b, 0)/item[it].length;
            }
          });
        });
        // console.log(monthly_dist_detail);
        setDisFreqChat(monthly_dist_detail);
      }
    };

  const convertAreaChart = (data) => {
    if (splitByCat === 'Category' || splitByCat === 'Country') {
      const convertData = [];
      data.map(item => {
        convertData.push({
          date: item.date,
          ...item[displayCategories.slice(-1)]
        })
      });
      return convertData
    };
   return data;
  };

  const get_total_kw = () => {
    if (rankingDist.length > 0  && displayCategories.length > 0 && (splitByCat === 'Category' || splitByCat === 'Country')){
      let total = 0;
      Object.keys(rankingDist.slice(-1)[0][displayCategories.slice(-1)]).map(item =>{
        total += rankingDist.slice(-1)[0][displayCategories.slice(-1)][item];
      });
      return total
    } else if (rankingDist.length > 0) {
      let total = 0;
      Object.keys(rankingDist.slice(-1)[0]).map(item => {
        if (item !== 'date' && typeof rankingDist.slice(-1)[0][item] !== 'object'){
          total += rankingDist.slice(-1)[0][item];
        }
      });
      return total
    }
  };

  const compareRanking = (mode, top_tier) => {
    if(mode === 'general'){
      if (rankingProgress.length > 0  && displayCategories.length > 0 && (splitByCat === 'Category' || splitByCat === 'Country')){
        let newRank = rankingProgress.slice(-1)[0][displayCategories.slice(-1)];
        let oldRank = compRankingProgress.slice(-1)[0][displayCategories.slice(-1)];
        return (oldRank - newRank).toFixed(1)
      } else if (rankingProgress.length > 0){
        let newRank = rankingProgress.slice(-1)[0]['rank'];
        let oldRank = compRankingProgress.slice(-1)[0]['rank'];
        return (oldRank - newRank).toFixed(1)
      }                      
    } else if (mode === 'segment'){
      if (rankingProgress.length > 0  && displayCategories.length > 0 && (splitByCat === 'Category' || splitByCat === 'Country')){
        let newRank = rankingDist.slice(-1)[0][displayCategories.slice(-1)][top_tier];
        let oldRank = compRankingDist.slice(-1)[0][displayCategories.slice(-1)][top_tier];
        return (oldRank - newRank)
      } else if (rankingDist.length > 0){
        let newRank = rankingDist.slice(-1)[0][top_tier];
        let oldRank = compRankingDist.slice(-1)[0][top_tier];
        return (oldRank - newRank)
      }
    };
  }


    return (
        <div className="d-flex flex-column">
            <h1 className="text-center mt-4 mb-5">Rank Tracking</h1>
            <div class="d-flex dropdown mb-5 align-self-end">
                <div className='d-flex align-items-center'>
                        <FontAwesomeIcon icon={faCircle} style={{'color':'green'}}/>
                        <span className='ms-2 fw-bold fs-6'>{domain}</span>
                </div>
                <div class="input-group ps-3 w-auto">
                  <span class="input-group-text" id="basic-addon1">Date range</span>
                  <OverlayTrigger trigger={["click"]} placement="bottom" overlay={datePicker} rootClose container={this} containerPadding={20}>
                      <input type="text" className="form-control" placeholder="from - to" id="startEnd"/>
                  </OverlayTrigger>
                </div>
            </div>
            <div className='d-flex mb-4 mt-3 ms-auto'>
              <div className={`btn-group me-4 ${['Split by','None'].includes(splitByCat) && 'd-none'}`}>
                <div class="btn-group">
                  <button type="button" class="btn btn-light dropdown-toggle" data-bs-toggle="dropdown" data-bs-auto-close="outside" aria-expanded="false">
                    {`${splitByCat === 'Category' ? 'Select category' : 'Select country'}`} ({displayCategories.includes('All Category') || displayCategories.includes('All Country') ? category.length - 1 : displayCategories.length})
                  </button>
                  <ul class="dropdown-menu" style={{'max-height': '300px', 'overflow': 'auto'}}>
                    {splitByCat === 'Category' ?
                        category.map((item, index) => {
                          return (
                            <li key={index} onClick={e => {
                              e.target.text === 'All Category' && displayCategories.includes(e.target.text) ?
                              setDisplayCategories([]) :
                              e.target.text === 'All Category' && !displayCategories.includes(e.target.text) ?
                              setDisplayCategories(category) :
                              displayCategories.includes(e.target.text) ?
                              setDisplayCategories(displayCategories.filter(item => item !== e.target.text)) :
                              setDisplayCategories(c => [...c, e.target.text])
                              console.log(displayCategories);
                              }}>
                              <a class="dropdown-item" href="#">
                                <FontAwesomeIcon icon={faCircleCheck} className={`me-2 ${!displayCategories.includes(item) && 'd-none'}`} style={{color: 'green'}}/>
                                {item}
                              </a>
                            </li>
                          )
                        }) :
                        dcountry.map((item, index) => {
                          return (
                            <li key={index} onClick={e => {
                              e.target.text === 'Clear All'?
                              setDisplayCategories([]) :
                              displayCategories.includes(e.target.text) ?
                              setDisplayCategories(displayCategories.filter(item => item !== e.target.text)) :
                              setDisplayCategories(c => [...c, e.target.text])
                              console.log(displayCategories);
                              }}>
                              <a class="dropdown-item" href="#">
                                <FontAwesomeIcon icon={faCircleCheck} className={`me-2 ${!displayCategories.includes(item) && 'd-none'}`} style={{color: 'green'}}/>
                                {item}
                              </a>
                            </li>
                          )
                        })
                      }
                    
                  </ul>
                </div>
              </div>
              {/* <div className='d-flex align-items-center me-4'>
                <div class={`form-check ${rankingProgress.length === 0 && 'd-none'}`}>
                  <input class="form-check-input" type="checkbox" value="" id="defaultCheck1" onChange={e => setSplitByCat(current => !current)}/>
                  <label class="form-check-label" for="defaultCheck1">
                    Split by Category
                  </label>
                </div>
              </div> */}
              <div class="dropdown me-4">
                <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                  {splitByCat}
                </button>
                <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                  <li onClick={e => {setDisplayCategories([]);setSplitByCat(e.target.text)}}><a class="dropdown-item" href="#">None</a></li>
                  <li onClick={e => {setDisplayCategories([]); setSplitByCat(e.target.text)}}><a class="dropdown-item" href="#">Category</a></li>
                  {domain === 'bbcincorp.com' &&
                    <li onClick={e => {setDisplayCategories([]); setSplitByCat(e.target.text)}}><a class="dropdown-item" href="#">Country</a></li>}
                </ul>
              </div>
              <div class="btn-group" role="group" aria-label="Basic radio toggle button group">
                <input type="radio" class="btn-check" name="btnradio" id="btnradio1" value='Daily' autocomplete="off" onChange={e => changeDisplayFreqHandler(startDate, endDate, e.target.value)}/>
                <label class="btn btn-outline-primary" for="btnradio1">Daily</label>

                <input type="radio" class="btn-check" name="btnradio" value='Weekly' id="btnradio2" autocomplete="off" onChange={e => changeDisplayFreqHandler(startDate, endDate, e.target.value)}/>
                <label class="btn btn-outline-primary" for="btnradio2">Weekly</label>

                <input type="radio" class="btn-check" name="btnradio" value='Monthly' id="btnradio3" autocomplete="off" onChange={e => changeDisplayFreqHandler(startDate, endDate, e.target.value)}/>
                <label class="btn btn-outline-primary" for="btnradio3">Monthly</label>
              </div>
            </div>
            <div className={`d-flex flex-column ${rankingProgress.length === 0 && 'd-none'}`}>
                <div className="row h-100">
                    <div className="d-flex flex-column col-md-6" style={{'height': '400px'}}>
                        <h5 className="mb-4">Ranking Distribution</h5>
                        <ResponsiveContainer width="100%" height="80%">
                            <AreaChart
                            width={500}
                            height={300}
                            data={displayPeriod === 'Daily' ? convertAreaChart(rankingDist) : convertAreaChart(disFreqChart)}
                            stackOffset="expand"
                            syncId="ProgressDist"
                            syncMethod={(tick, datum) => {
                              let d = tick.filter(item => item.value === datum.activeLabel);
                              if (d.length > 0 && Object.keys(d[0]).includes('index')){
                                return d[0].index
                              }
                            }}
                            margin={{
                                top: 5,
                                right: 30,
                                left: 20,
                                bottom: 5,
                            }}
                            >
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis
                                dataKey="date"
                                name = "date"
                                // tickFormatter = {(unixTime) => moment(unixTime).format('HH:mm YYYY-MM-DD')}
                                {...getXAxisArgsForTimeBasedGraph(data.map((item) => item.date))}

                            />
                            <YAxis 
                                tickFormatter={(decimal) => `${(decimal * 100).toFixed(0)}%`}
                            />
                            <Tooltip 
                                content={renderTooltipContent}
                                wrapperStyle={{ backgroundColor: '#f8f9fa', padding: '2px' }}
                            />
                            <Legend />
                            
                            <Area type="monotone" dataKey="Top 5" stackId="1" stroke="#8884d8" fill="#8884d8" dot={{ stroke: '#8884d8', strokeWidth: 1, r: 2,strokeDasharray:''}}/>
                            <Area type="monotone" dataKey="Top 10" stackId="1" stroke="#82ca9d" fill="#82ca9d" dot={{ stroke: '#82ca9d', strokeWidth: 1, r: 2,strokeDasharray:''}}/>
                            <Area type="monotone" dataKey="Top 20" stackId="1" stroke="#ffc658" fill="#ffc658" dot={{ stroke: '#ffc658', strokeWidth: 1, r: 2,strokeDasharray:''}}/>
                            <Area type="monotone" dataKey="Top 50" stackId="1" stroke="#d4d884" fill="#d4d884" dot={{ stroke: '#d4d884', strokeWidth: 1, r: 2,strokeDasharray:''}}/>
                            <Area type="monotone" dataKey="Top 100" stackId="1" stroke="#d88884" fill="#d88884" dot={{ stroke: '#d88884', strokeWidth: 1, r: 2,strokeDasharray:''}}/>
                            <Area type="monotone" dataKey="Out of 100" stackId="1" stroke="#c6504a" fill="#c6504a" dot={{ stroke: '#c6504a', strokeWidth: 1, r: 2,strokeDasharray:''}}/>
                                  
                            </AreaChart>
                        </ResponsiveContainer>
                        
                    </div>
                    <div className=" d-flex flex-column col-md-6" style={{'height': '400px'}}>
                        <h5 className="mb-4">Ranking Progress</h5>                              
                        <ResponsiveContainer width="100%" height="80%">
                            <AreaChart
                            width={500}
                            height={300}
                            data={displayPeriod === 'Daily' ? rankingProgress : freqChart}
                            syncId="ProgressDist"
                            syncMethod={(tick, datum) => {
                              let d = tick.filter(item => item.value === datum.activeLabel);
                              if (d.length > 0 && Object.keys(d[0]).includes('index')){
                                return d[0].index
                              }
                            }}
                            margin={{
                                top: 5,
                                right: 30,
                                left: 20,
                                bottom: 5,
                            }}
                            >
                              <defs>
                                <linearGradient id="color1" x1="0" y1="0" x2="0" y2="1">
                                  <stop offset="5%" stopColor="#8884d8" stopOpacity={0.8}/>
                                  <stop offset="95%" stopColor="#8884d8" stopOpacity={0}/>
                                </linearGradient>
                                <linearGradient id="color2" x1="0" y1="0" x2="0" y2="1">
                                  <stop offset="5%" stopColor="#82ca9d" stopOpacity={0.8}/>
                                  <stop offset="95%" stopColor="#82ca9d" stopOpacity={0}/>
                                </linearGradient>
                                <linearGradient id="color3" x1="0" y1="0" x2="0" y2="1">
                                  <stop offset="5%" stopColor="#ffc658" stopOpacity={0.8}/>
                                  <stop offset="95%" stopColor="#ffc658" stopOpacity={0}/>
                                </linearGradient>
                                <linearGradient id="color4" x1="0" y1="0" x2="0" y2="1">
                                  <stop offset="5%" stopColor="#d4d884" stopOpacity={0.8}/>
                                  <stop offset="95%" stopColor="#d4d884" stopOpacity={0}/>
                                </linearGradient>
                                <linearGradient id="color5" x1="0" y1="0" x2="0" y2="1">
                                  <stop offset="5%" stopColor="#d88884" stopOpacity={0.8}/>
                                  <stop offset="95%" stopColor="#d88884" stopOpacity={0}/>
                                </linearGradient>
                              </defs>
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis
                                dataKey="date"
                                name = "date"
                                // tickFormatter = {(unixTime) => moment(unixTime).format('HH:mm YYYY-MM-DD')}
                                {...getXAxisArgsForTimeBasedGraph(data.map((item) => item.date))}

                            />
                            <YAxis 
                                domain={[0, 'auto']}
                                reversed
                            />
                            <Tooltip 
                                content={!['Split by', 'None'].includes(splitByCat) ? renderLineTooltipContent : <CustomTooltip />}
                                wrapperStyle={{ backgroundColor: '#f8f9fa', padding: '2px' }}
                            />
                            <Legend legendType='none'/>
                            {splitByCat === 'Category' && Object.keys(rankingProgress[0]).includes('') === false ?   
                                // console.log('rank', rankingProgress) :
                                  category.filter((item) => displayCategories.includes(item)).map((item, index) => {
                                    let colorRange = ['#8884d8','#82ca9d','#ffc658', '#d4d884', '#d88884']
                                    return(
                                      <Area type="monotone" dataKey={item} stroke={colorRange[index]} fillOpacity={1} fill="url(#color0)"/>
                                    )
                                  }) :
                                  splitByCat === 'Country' && Object.keys(rankingProgress[0]).includes('') === false ?
                                  dcountry.filter((item) => displayCategories.includes(item)).map((item, index) => {
                                    let colorRange = ['#8884d8','#82ca9d','#ffc658', '#d4d884', '#d88884']
                                    return(
                                      <Area type="monotone" dataKey={item} stroke={colorRange[index]} fillOpacity={1} fill="url(#color0)"/>
                                    )
                                  }) :
                                  <Area type="monotone" dataKey="rank" stroke="#8884d8" fillOpacity={1} fill="url(#color1)"/>
                                
                              }
                            </AreaChart>
                        </ResponsiveContainer>
                    </div>
                </div>
            </div>
            {
              serpVolatility.length > 0 &&
              <div className='d-flex flex-column mb-4'>
                <div className='d-flex flex-column col-md-6 w-100' style={{height: '200px'}}>
                  <h5 className="mb-4">Volatility Index</h5>
                    <ResponsiveContainer width="100%" height="100%">
                      <ComposedChart
                      width={500}
                      height={300}
                      data={displayPeriod === 'Daily' ? serpVolatility : serpFreqChart}
                      margin={{
                          top: 5,
                          right: 30,
                          left: 20,
                          bottom: 5,
                      }}
                      syncId="ProgressDist"
                      syncMethod={(tick, datum) => {
                        let d = tick.filter(item => item.value === datum.activeLabel);
                          if (d.length > 0 && Object.keys(d[0]).includes('index')){
                            return d[0].index
                          }
                      }}
                      >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis
                            dataKey="date"
                            name = "date"
                            tickFormatter = {(unixTime) => moment(unixTime).format('DD/MM')}
                            // {...getXAxisArgsForTimeBasedGraph(data.map((item) => item.date))}

                        />
                        <YAxis 
                            domain={[0, 'auto']}
                            yAxisId="left"
                        />
                        <YAxis 
                            domain={[0, 10]}
                            yAxisId="right"
                            orientation="right"
                            hide={true}
                        />
                        <Tooltip 
                            content={<CustomTooltip />}
                            wrapperStyle={{ backgroundColor: '#f8f9fa', padding: '2px' }}
                        />
                        {/* <Legend legendType='none'/> */}
                        <Bar dataKey="update_index" fill='#8884d8' barSize={10} yAxisId="right"/>
                        <Area dataKey="index" yAxisId="left" connectNulls>
                          {serpVolatility.map((entry, index) => (
                            <Cell fill='#8884d8' key={`cell-${index}`} />
                          ))}
                        </Area>
                      </ComposedChart>
                  </ResponsiveContainer>
                </div>
              </div>
            }
            <div className="row my-5">
            <div class="d-flex flex-column col border shadow-sm rounded mx-3 py-2 px-3">
                <b>Average Position</b>
                <h2 className='align-self-center my-3'>
                {rankingProgress.length > 0  && displayCategories.length > 0 && (splitByCat === 'Category' || splitByCat === 'Country') ?
                  rankingProgress.slice(-1)[0][displayCategories.slice(-1)]:
                  rankingProgress.length > 0 ?
                  rankingProgress.slice(-1)[0]['rank']:
                  null
                  }
                </h2>
                <span style={{minHeight: '21px'}}>
                {comparisonMode && rankingProgress.length > 0 && compRankingProgress.length > 0 && compareRanking('general') > 0 ?
                    <FontAwesomeIcon icon={faArrowUp} style={{color:'green'}} className={'me-2'}/> :
                    comparisonMode && rankingProgress.length > 0 && compRankingProgress.length > 0 && compareRanking('general') < 0 ?
                    <FontAwesomeIcon icon={faArrowDown} style={{color:'red'}} className={'me-2'}/> :
                    comparisonMode && rankingProgress.length > 0 && compRankingProgress.length > 0 ?
                    <FontAwesomeIcon icon={faMinus} style={{color:'orange'}} className={'me-2'}/>:
                    null
                    }
                  {comparisonMode && rankingProgress.length > 0 && compRankingProgress.length > 0 && Math.abs(compareRanking('general'))}
                </span>
              </div>
              <div class="d-flex flex-column col border shadow-sm rounded mx-3 py-2 px-3">
                <b>Top 5</b>
                <h2 className='align-self-center my-3'>
                  {rankingDist.length > 0  && displayCategories.length > 0 && (splitByCat === 'Category' || splitByCat === 'Country') ?
                  rankingDist.slice(-1)[0][displayCategories.slice(-1)]['Top 5'] :
                  rankingDist.length > 0 ?
                  rankingDist.slice(-1)[0]['Top 5']:
                  null
                  }
                  <span style={{fontSize: '12px',}}> /
                  {get_total_kw()}
                  </span>
                </h2>
                <span style={{minHeight: '21px'}}>
                {comparisonMode && rankingDist.length > 0 && compRankingDist.length > 0 && compareRanking('segment','Top 5') < 0 ?
                    <FontAwesomeIcon icon={faArrowUp} style={{color:'green'}} className={'me-2'}/> :
                    comparisonMode && rankingDist.length > 0 && compRankingDist.length > 0 && compareRanking('segment','Top 5') > 0 ?
                    <FontAwesomeIcon icon={faArrowDown} style={{color:'red'}} className={'me-2'}/> :
                    comparisonMode && rankingDist.length > 0 && compRankingDist.length > 0 ?
                    <FontAwesomeIcon icon={faMinus} style={{color:'orange'}} className={'me-2'}/>:
                    null
                    }
                  {comparisonMode && rankingDist.length > 0 && compRankingDist.length > 0 && Math.abs(compareRanking('segment','Top 5'))}
                </span>
              </div>
              <div class="d-flex flex-column col border shadow-sm rounded mx-3 py-2 px-3">
                <b>Top 10</b>
                <h2 className='align-self-center my-3'>
                {rankingDist.length > 0  && displayCategories.length > 0 && (splitByCat === 'Category' || splitByCat === 'Country') ?
                  rankingDist.slice(-1)[0][displayCategories.slice(-1)]['Top 10'] + rankingDist.slice(-1)[0][displayCategories.slice(-1)]['Top 5']:
                  rankingDist.length > 0 ?
                  rankingDist.slice(-1)[0]['Top 10'] + rankingDist.slice(-1)[0]['Top 5']:
                  null
                  }
                  <span style={{fontSize: '12px',}}> /
                  {get_total_kw()}
                  </span>
                </h2>
                <span style={{minHeight: '21px'}}>
                {comparisonMode && rankingDist.length > 0 && compRankingDist.length > 0 && compareRanking('segment','Top 10') < 0 ?
                    <FontAwesomeIcon icon={faArrowUp} style={{color:'green'}} className={'me-2'}/> :
                    comparisonMode && rankingDist.length > 0 && compRankingDist.length > 0 && compareRanking('segment','Top 10') > 0 ?
                    <FontAwesomeIcon icon={faArrowDown} style={{color:'red'}} className={'me-2'}/> :
                    comparisonMode && rankingDist.length > 0 && compRankingDist.length > 0 ?
                    <FontAwesomeIcon icon={faMinus} style={{color:'orange'}} className={'me-2'}/>:
                    null
                    }
                  {comparisonMode && rankingDist.length > 0 && compRankingDist.length > 0 && Math.abs(compareRanking('segment','Top 10'))}
                </span>
              </div>
              <div class="d-flex flex-column col border shadow-sm rounded mx-3 py-2 px-3">
                <b>Top 20</b>
                <h2 className='align-self-center my-3'>
                {rankingDist.length > 0  && displayCategories.length > 0 && (splitByCat === 'Category' || splitByCat === 'Country') ?
                  rankingDist.slice(-1)[0][displayCategories.slice(-1)]['Top 20'] + rankingDist.slice(-1)[0][displayCategories.slice(-1)]['Top 10'] + rankingDist.slice(-1)[0][displayCategories.slice(-1)]['Top 5']:
                  rankingDist.length > 0 ?
                  rankingDist.slice(-1)[0]['Top 20'] + rankingDist.slice(-1)[0]['Top 10'] + rankingDist.slice(-1)[0]['Top 5']:
                  null
                  }
                  <span style={{fontSize: '12px',}}> /
                  {get_total_kw()}
                  </span>
                </h2>
                <span style={{minHeight: '21px'}}>
                {comparisonMode && rankingDist.length > 0 && compRankingDist.length > 0 && compareRanking('segment','Top 20') < 0 ?
                    <FontAwesomeIcon icon={faArrowUp} style={{color:'green'}} className={'me-2'}/> :
                    comparisonMode && rankingDist.length > 0 && compRankingDist.length > 0 && compareRanking('segment','Top 20') > 0 ?
                    <FontAwesomeIcon icon={faArrowDown} style={{color:'red'}} className={'me-2'}/> :
                    comparisonMode && rankingDist.length > 0 && compRankingDist.length > 0 ?
                    <FontAwesomeIcon icon={faMinus} style={{color:'orange'}} className={'me-2'}/>:
                    null
                    }
                  {comparisonMode && rankingDist.length > 0 && compRankingDist.length > 0 && Math.abs(compareRanking('segment','Top 20'))}
                </span>
              </div>
              <div class="d-flex flex-column col border shadow-sm rounded mx-3 py-2 px-3">
                <b>Top 50</b>
                <h2 className='align-self-center my-3'>
                {rankingDist.length > 0  && displayCategories.length > 0 && (splitByCat === 'Category' || splitByCat === 'Country') ?
                  rankingDist.slice(-1)[0][displayCategories.slice(-1)]['Top 50'] + rankingDist.slice(-1)[0][displayCategories.slice(-1)]['Top 20'] + rankingDist.slice(-1)[0][displayCategories.slice(-1)]['Top 10'] + rankingDist.slice(-1)[0][displayCategories.slice(-1)]['Top 5']:
                  rankingDist.length > 0 ?
                  rankingDist.slice(-1)[0]['Top 50'] + rankingDist.slice(-1)[0]['Top 20'] + rankingDist.slice(-1)[0]['Top 10'] + rankingDist.slice(-1)[0]['Top 5']:
                  null
                  }
                  <span style={{fontSize: '12px',}}> /
                  {get_total_kw()}
                  </span>
                </h2>
                <span style={{minHeight: '21px'}}>
                {comparisonMode && rankingDist.length > 0 && compRankingDist.length > 0 && compareRanking('segment','Top 50') < 0 ?
                    <FontAwesomeIcon icon={faArrowUp} style={{color:'green'}} className={'me-2'}/> :
                    comparisonMode && rankingDist.length > 0 && compRankingDist.length > 0 && compareRanking('segment','Top 50') > 0 ?
                    <FontAwesomeIcon icon={faArrowDown} style={{color:'red'}} className={'me-2'}/> :
                    comparisonMode && rankingDist.length > 0 && compRankingDist.length > 0 ?
                    <FontAwesomeIcon icon={faMinus} style={{color:'orange'}} className={'me-2'}/>:
                    null
                    }
                  {comparisonMode && rankingDist.length > 0 && compRankingDist.length > 0 && Math.abs(compareRanking('segment','Top 50'))}
                </span>
              </div>
              <div class="d-flex flex-column col border shadow-sm rounded mx-3 py-2 px-3">
                <b>Top 100</b>
                <h2 className='align-self-center my-3'>
                {rankingDist.length > 0  && displayCategories.length > 0 && (splitByCat === 'Category' || splitByCat === 'Country') ?
                  rankingDist.slice(-1)[0][displayCategories.slice(-1)]['Top 100'] + rankingDist.slice(-1)[0][displayCategories.slice(-1)]['Top 50'] + rankingDist.slice(-1)[0][displayCategories.slice(-1)]['Top 20'] + rankingDist.slice(-1)[0][displayCategories.slice(-1)]['Top 10'] + rankingDist.slice(-1)[0][displayCategories.slice(-1)]['Top 5']:
                  rankingDist.length > 0 ?
                  rankingDist.slice(-1)[0]['Top 100'] + rankingDist.slice(-1)[0]['Top 50'] + rankingDist.slice(-1)[0]['Top 20'] + rankingDist.slice(-1)[0]['Top 10'] + rankingDist.slice(-1)[0]['Top 5']:
                  null
                  }
                  <span style={{fontSize: '12px',}}> /
                  {get_total_kw()}
                  </span>
                </h2>
                <span style={{minHeight: '21px'}}>
                {comparisonMode && rankingDist.length > 0 && compRankingDist.length > 0 && compareRanking('segment','Top 100') < 0 ?
                    <FontAwesomeIcon icon={faArrowUp} style={{color:'green'}} className={'me-2'}/> :
                    comparisonMode && rankingDist.length > 0 && compRankingDist.length > 0 && compareRanking('segment','Top 100') > 0 ?
                    <FontAwesomeIcon icon={faArrowDown} style={{color:'red'}} className={'me-2'}/> :
                    comparisonMode && rankingDist.length > 0 && compRankingDist.length > 0 ?
                    <FontAwesomeIcon icon={faMinus} style={{color:'orange'}} className={'me-2'}/>:
                    null
                    }
                  {comparisonMode && rankingDist.length > 0 && compRankingDist.length > 0 && Math.abs(compareRanking('segment','Top 100'))}
                  
                </span>
              </div>
            </div>
            <div className='my-5'>
              <div className="d-flex mb-2">
                <div class="dropdown me-3">
                    <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                        <FontAwesomeIcon icon={faFilter} />
                        <span className='ms-2'>Filter by</span>
                    </button>
                    <ul class="dropdown-menu" style={{'z-index':'99'}} aria-labelledby="dropdownMenuButton1">
                        <li onClick={e => filterHandler(e, 'Position')}><a class="dropdown-item" href="#">Position</a></li>
                        {domain === 'bbcincorp.com' && <li onClick={e => filterHandler(e, 'Country')}><a class="dropdown-item" href="#">Country</a></li>}
                        <li onClick={e => filterHandler(e, 'Keywords')}><a class="dropdown-item" href="#">Keywords</a></li>
                        <li onClick={e => filterHandler(e, 'Category')}><a class="dropdown-item" href="#">Category</a></li>
                        <li onClick={e => filterHandler(e, 'URL')}><a class="dropdown-item" href="#">URL</a></li>
                    </ul>
                </div>
                <div className={`d-flex ${filter.length === 0 && 'd-none'}`}>
                    {/* <button class="btn btn-sm btn-close btn-outline-danger p-2 align-self-center me-2" type="button" aria-expanded="false" onClick={clearFilterHandler}/> */}
                    <div class={`input-group flex-nowrap me-2 ${filter.includes('Position') === false && 'd-none'}`}>
                        {/* <span class="input-group-text" id="addon-wrapping">X</span> */}
                        <button class="btn btn-sm btn-outline-danger p-2 align-self-center" id="button-addon1" type="button" onClick={e => clearFilterHandler(e, 'Position')}>X</button>
                        <input type="text" class="form-control" placeholder="From" aria-label="From" aria-describedby="addon-wrapping" ref={lowerBound}/>
                        <input type="text" class="form-control" placeholder="To" aria-label="To" aria-describedby="addon-wrapping" ref={upperBound}/>
                    </div>
                    <div class={`input-group flex-nowrap me-2 ${filter.includes('Keywords') ===false && 'd-none'}`}>
                        <button class="btn btn-sm btn-outline-danger p-2 align-self-center" id="button-addon1" type="button" onClick={e => clearFilterHandler(e, 'Keywords')}>X</button>
                        <button class="btn btn-outline-secondary dropdown-toggle" type="button" id="kw-search-mode" data-bs-toggle="dropdown" aria-expanded="false">M</button>
                        <ul class="dropdown-menu">
                          <li><a class="dropdown-item" href="#" onClick={e => {e.preventDefault(); document.getElementById("kw-search-mode").innerHTML = "E"}}>E</a></li>
                          <li><a class="dropdown-item" href="#" onClick={e => {e.preventDefault(); document.getElementById("kw-search-mode").innerHTML = 'B'}}>B</a></li>
                        </ul>
                        <input type="text" class="form-control" placeholder="Keyword Search..." aria-label="Keyword Search..." aria-describedby="addon-wrapping" ref={keySearch}/>
                    </div>
                    <div class={`input-group flex-nowrap me-2 w-auto ${filter.includes('Category') ===false && 'd-none'}`}>
                        <button class="btn btn-sm btn-outline-danger p-2 align-self-center" id="button-addon1" type="button" onClick={e => clearFilterHandler(e, 'Category')}>X</button>
                        {/* <input type="text" class="form-control" placeholder="Category Search..." aria-label="Category Search..." aria-describedby="addon-wrapping" onChange={(e) => setSearchKey(e.target.value)}/> */}
                        {/* <div class="dropdown"> */}
                            <button class="btn btn-light dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                <span className='ms-2'>{`${selectedCategory === '' ? 'Category' : selectedCategory}`}</span>
                            </button>
                            <ul class="dropdown-menu" style={{'z-index':'99'}} aria-labelledby="dropdownMenuButton1">
                              {category.map(item => {return (
                                <li ><a class="dropdown-item" href="#" onClick={e => {e.preventDefault(); console.log(e.target.outerText);setSelectedCategory(item)}}>{item}</a></li>
                              )}
                              )}
                            </ul>
                        {/* </div> */}
                    </div>
                    <div class={`input-group flex-nowrap ${filter.includes('URL')===false && 'd-none'}`}>
                        <button class="btn btn-sm btn-outline-danger p-2 align-self-center" id="button-addon1" type="button" onClick={e => clearFilterHandler(e, 'URL')}>X</button>
                        <button class="btn btn-outline-secondary dropdown-toggle" type="button" id="url-search-mode" data-bs-toggle="dropdown" aria-expanded="false">M</button>
                        <ul class="dropdown-menu">
                          <li><a class="dropdown-item" href="#" onClick={e => {e.preventDefault(); document.getElementById("url-search-mode").innerHTML = "E"}}>E</a></li>
                          <li><a class="dropdown-item" href="#" onClick={e => {e.preventDefault(); document.getElementById("url-search-mode").innerHTML = 'B'}}>B</a></li>
                        </ul>
                        <input type="text" class="form-control" placeholder="URL Search..." aria-label="URL Search..." aria-describedby="addon-wrapping" ref={urlSearch}/>
                    </div>
                    <div class={`input-group flex-nowrap w-auto ${filter.includes('Country')===false && 'd-none'}`}>
                        <button class="btn btn-sm btn-outline-danger p-2 align-self-center" id="button-addon1" type="button" onClick={e => clearFilterHandler(e, 'Country')}>X</button>
                        <div class="dropdown">
                          <button class="btn btn-light dropdown-toggle" type="button" id="dropdownMenuClickableInside" data-bs-toggle="dropdown" data-bs-auto-close="outside" aria-expanded="false">
                              <span className='ms-2'>{country}</span>
                          </button>
                          <div class="dropdown-menu p-3" style={{'z-index':'99'}} aria-labelledby="dropdownMenuClickableInside">
                            <div className="d-flex flex-column">
                              <div class="form-check" onClick={e => setCountry('Offshore')}>
                                <input class="form-check-input" type="radio" name="flexRadioDefault" value="" id="Offshore"/>
                                <label class="form-check-label w-100" for="Offshore">
                                  Offshore
                                </label>
                              </div>
                              <div class="dropdown-divider"></div>
                              <div className="row" style={{"width":'300px'}}>
                                <div className='col-md-4'>
                                  {
                                    ['Anguilla','Bahamas','Belize','BVI','Cayman','Delaware'].map((item) => {
                                      return(
                                        <div class="form-check" onClick={e => setCountry(item)}>
                                          <input class="form-check-input" type="radio" name="flexRadioDefault" value="" id={`${item}`}/>
                                          <label class="form-check-label w-100" for={`${item}`}>
                                            {item}
                                          </label>
                                        </div>
                                      )
                                    })
                                  }
                                </div>
                                <div className='col-md-4'>
                                  {
                                      ['Panama','Nevis','Vincent','Marshall','Samoa'].map((item) => {
                                        return(
                                          <div class="form-check" onClick={e => setCountry(item)}>
                                            <input class="form-check-input" type="radio" name="flexRadioDefault" value="" id={`${item}`}/>
                                            <label class="form-check-label w-100" for={`${item}`}>
                                              {item}
                                            </label>
                                          </div>
                                        )
                                      })
                                    }
                                </div>
                                <div className='col-md-4'>
                                {
                                    ['Cyprus','Mauritius','Seychelles','RAK','UK'].map((item) => {
                                      return(
                                        <div class="form-check" onClick={e => setCountry(item)}>
                                          <input class="form-check-input" type="radio" name="flexRadioDefault" value="" id={`${item}`}/>
                                          <label class="form-check-label w-100" for={`${item}`}>
                                            {item}
                                          </label>
                                        </div>
                                      )
                                    })
                                  }
                                </div>
                              </div>
                              <div class="dropdown-divider"></div>
                              <div class="form-check" onClick={e => setCountry('HK')}>
                                <input class="form-check-input" type="radio" name="flexRadioDefault" value="" id="HK"/>
                                <label class="form-check-label w-100" for="HK">
                                  HK
                                </label>
                              </div>
                              <div class="dropdown-divider"></div>
                              <div class="form-check" onClick={e => setCountry('SG')}>
                                <input class="form-check-input" type="radio" name="flexRadioDefault" value="" id="SG"/>
                                <label class="form-check-label w-100" for="SG">
                                  SG
                                </label>
                              </div>
                              <div class="dropdown-divider"></div>
                              <div class="form-check" onClick={e => setCountry('VN')}>
                                <input class="form-check-input" type="radio" name="flexRadioDefault" value="" id="VN"/>
                                <label class="form-check-label w-100" for="VN">
                                  VN
                                </label>
                              </div>
                            </div>
                          </div>
                      </div>
                    </div>
                    <button class="btn btn-primary ms-2" type="button" aria-expanded="false"
                    onClick={filterModeHandler}
                    >
                      Apply
                    </button>
                </div>
                <button class="btn btn-primary ms-auto" type="button" aria-expanded="false" onClick={event => downloadRankReportHandler(filteredRank)}>
                    <FontAwesomeIcon icon={faDownload} className="pe-2"/>
                    Download
                </button>
              </div>
              <div className="overflow-auto mb-3" style={{"height":"500px"}}>
                  <table class="table table-striped" style={{'table-layout':'fixed', 'word-wrap': 'break-word'}}>
                      <thead className="table-primary sticky-top" style={{'z-index':'9'}}>
                          <tr>
                          <th scope="col" style={{'width':'5%'}}>#</th>
                          <th scope="col" style={{'width':'22%'}}>
                            <button class="btn" type="button" aria-expanded="false" onClick={event => sortHandler('kw')}>
                                <span className='me-2 fw-bold'>Keyword </span>
                                <FontAwesomeIcon icon={faAngleUp} style={{'color':`${sort === 'keyword_inc' ? '#000' : '#6c757d'}`}}/>
                                <FontAwesomeIcon icon={faAngleDown} style={{'color':`${sort === 'keyword_dec' ? '#000' : '#6c757d'}`}}/>
                            </button>
                          </th>
                          <th scope="col" style={{'width':'7%'}} className="px-0">
                            <button class="btn px-0" type="button" aria-expanded="false" onClick={event => sortHandler('vol')}>
                                <span className='me-2 fw-bold'>Volume</span>
                                <FontAwesomeIcon icon={faAngleUp} style={{'color':`${sort === 'vol_inc' ? '#000' : '#6c757d'}`,'font-size':'0.8rem'}}/>
                                <FontAwesomeIcon icon={faAngleDown} style={{'color':`${sort === 'vol_dec' ? '#000' : '#6c757d'}`, 'font-size':'0.8rem'}}/>
                            </button>
                          </th>
                          <th scope="col" style={{'width':'10%'}}>
                            <button class="btn px-0" type="button" aria-expanded="false" onClick={event => sortHandler('Category')}>
                              <span className='me-2 fw-bold'>Category </span>
                              <FontAwesomeIcon icon={faAngleUp} style={{'color':`${sort === 'Category_inc' ? '#000' : '#6c757d'}`, 'font-size':'0.8rem'}}/>
                              <FontAwesomeIcon icon={faAngleDown} style={{'color':`${sort === 'Category_dec' ? '#000' : '#6c757d'}`, 'font-size':'0.8rem'}}/>
                            </button>
                          </th>
                          <th scope="col" style={{'width':'18%'}}>
                            <button class="btn px-0" type="button" aria-expanded="false" onClick={event => sortHandler('URL')}>
                              <span className='me-2 fw-bold'>URL </span>
                              <FontAwesomeIcon icon={faAngleUp} style={{'color':`${sort === 'URL_inc' ? '#000' : '#6c757d'}`, 'font-size':'0.8rem'}}/>
                              <FontAwesomeIcon icon={faAngleDown} style={{'color':`${sort === 'URL_dec' ? '#000' : '#6c757d'}`, 'font-size':'0.8rem'}}/>
                            </button>
                          </th>
                          <th scope="col" style={{'width':'18%'}}>
                            <button class="btn px-0" type="button" aria-expanded="false" onClick={event => sortHandler('Ranking page')}>
                              <span className='me-2 fw-bold'>Ranking page </span>
                              <FontAwesomeIcon icon={faAngleUp} style={{'color':`${sort === 'Ranking page_inc' ? '#000' : '#6c757d'}`, 'font-size':'0.8rem'}}/>
                              <FontAwesomeIcon icon={faAngleDown} style={{'color':`${sort === 'Ranking page_dec' ? '#000' : '#6c757d'}`, 'font-size':'0.8rem'}}/>
                            </button>
                          </th>
                          <th scope="col" style={{'width':'8%'}} className="px-0">
                            <button class="btn px-0" type="button" aria-expanded="false" onClick={event => sortHandler('pos')}>
                                <span className='me-2 fw-bold'>Avg. Pos</span>
                                <FontAwesomeIcon icon={faAngleUp} style={{'color':`${sort === 'pos_inc' ? '#000' : '#6c757d'}`, 'font-size':'0.8rem'}}/>
                                <FontAwesomeIcon icon={faAngleDown} style={{'color':`${sort === 'pos_dec' ? '#000' : '#6c757d'}`, 'font-size':'0.8rem'}}/>
                            </button>
                          </th>
                          {
                            startDateComp != '' ?
                            <th scope="col" style={{'width':'8%'}} className="px-0">
                              <button class="btn px-0" type="button" aria-expanded="false" onClick={event => sortHandler('prev_pos')}>
                                  <span className='me-2 fw-bold'>Change </span>
                                  <FontAwesomeIcon icon={faAngleUp} style={{'color':`${sort === 'prev_pos_inc' ? '#000' : '#6c757d'}`, 'font-size':'0.8rem'}}/>
                                  <FontAwesomeIcon icon={faAngleDown} style={{'color':`${sort === 'prev_pos_dec' ? '#000' : '#6c757d'}`, 'font-size':'0.8rem'}}/>
                              </button>
                            </th> : null
                          }
                          <th scope="col" style={{'width':'4%'}}>
                            <button class="btn px-0" type="button" aria-expanded="false" onClick={event => sortHandler('First')}>
                                <span className='me-2 fw-bold'>First </span>
                                <FontAwesomeIcon icon={faAngleUp} style={{'color':`${sort === 'First_inc' ? '#000' : '#6c757d'}`, 'font-size':'0.8rem'}}/>
                                <FontAwesomeIcon icon={faAngleDown} style={{'color':`${sort === 'First_dec' ? '#000' : '#6c757d'}`, 'font-size':'0.8rem'}}/>
                            </button>
                          </th>
                          <th scope="col" style={{'width':'4%'}}>
                            <button class="btn px-0" type="button" aria-expanded="false" onClick={event => sortHandler('Best')}>
                              <span className='me-2 fw-bold'>Best </span>
                              <FontAwesomeIcon icon={faAngleUp} style={{'color':`${sort === 'Best_inc' ? '#000' : '#6c757d'}`, 'font-size':'0.8rem'}}/>
                              <FontAwesomeIcon icon={faAngleDown} style={{'color':`${sort === 'Best_dec' ? '#000' : '#6c757d'}`, 'font-size':'0.8rem'}}/>
                            </button>
                          </th>
                          <th scope="col" style={{'width':'4%'}}>
                            <button class="btn px-0" type="button" aria-expanded="false" onClick={event => sortHandler('Last')}>
                              <span className='me-2 fw-bold'>Last </span>
                              <FontAwesomeIcon icon={faAngleUp} style={{'color':`${sort === 'Last_inc' ? '#000' : '#6c757d'}`, 'font-size':'0.8rem'}}/>
                              <FontAwesomeIcon icon={faAngleDown} style={{'color':`${sort === 'Last_dec' ? '#000' : '#6c757d'}`, 'font-size':'0.8rem'}}/>
                            </button>
                          </th>
                          <th scope="col" style={{'width':'5%'}}></th>
                          </tr>
                      </thead>
                      <tbody>
                          {useMemo(() =>
                          filteredRank.slice(showResult*(selectedPage-1),showResult*selectedPage).map((item, index) =>{
                              return (
                                  <tr key={index}>
                                  <th scope="row">{(index+1)+(selectedPage-1)*showResult}</th>
                                  <td>{item.Keyword}</td>
                                  <td>{item.Volume}</td>
                                  <td>{item.Category}</td>
                                  <td>{item.URL.replace(/^https:\/\/bbcincorp\.com\/|^https:\/\/gleads.vn\/vi\/|^https:\/\/bbcincorp\.sg\//i,'')}</td>
                                  <td>{item['Ranking page'].replace(/^https:\/\/bbcincorp\.com\/|^https:\/\/gleads.vn\/vi\/|^https:\/\/bbcincorp\.sg\//i,'')}</td>
                                  <td>
                                      {item.Ranking !== 101 ? item.Ranking : 'Out of 100'}
                                  </td>
                                  {
                                    startDateComp != '' ?
                                  <td style={{'color':`${item.Prev > 0 ? 'green' : parseFloat(item.Prev) === 0 ? "orange" : 'red'}`}}>{item.Prev}</td> : null
                                  }
                                  <td>{item.First !== 'N/A' ? item.First : 'Out of 100'}</td>
                                  <td>{item.Best !== 'N/A' ? item.Best : 'Out of 100'}</td>
                                  <td>{(item.Last !== 'N/A' && item.Last !== 101) ? item.Last : 'Out of 100'}</td>
                                  <td>
                                    <OverlayTrigger trigger="focus" placement="top" overlay={popoverChart}>
                                      <button class="btn me-auto" type="button" aria-expanded="false" onClick={e => KwRankHandler(item.Keyword,item['Ranking page'])}>
                                        <FontAwesomeIcon icon={faLineChart} />
                                      </button>
                                    </OverlayTrigger>
                                    <button class="btn me-auto" type="button" aria-expanded="false" data-bs-toggle="modal" data-bs-target="#ModalCenter" onClick={e => viewRankingHistHandler(item.Keyword, item.URL, item['Ranking page'])}>
                                      <FontAwesomeIcon icon={faEye} />
                                    </button>
                                  </td>
                                  </tr>
                              )
                          }),[filteredRank, sort, showResult, selectedPage, kwRankProgress])
                        }
                      </tbody>
                  </table>
                  {/* Modal PopUP*/}
                  <div class="modal fade" id="ModalCenter" tabindex="-1" role="dialog" aria-labelledby="ModalCenterTitle" aria-hidden="true">
                    <div class="modal-dialog modal-dialog-centered" role="document">
                        <div class="modal-content">
                          <div class="modal-header">
                              <h5 class="modal-title" id="ModalLongTitle">Keyword Ranking History</h5>
                              {/* <h6 class="modal-title" id="ModalLongTitle">Keyword Ranking History</h6> */}
                          </div>
                          <div class="modal-body" style={{'height':'500px', 'overflow':'auto'}}>
                            <div class="d-flex mb-4">
                            <table class="table">
                              <thead>
                                <tr>
                                  <th scope="col" style={{'width':'30%'}}>Date</th>
                                  <th scope="col" style={{'width':'20%'}}>Ranking</th>
                                  <th scope="col" style={{'width':'10%'}}>Match</th>
                                  <th scope="col" style={{'width':'40%'}}>Found URL</th>
                                </tr>
                              </thead>
                              <tbody>
                                { kwRankingHist !== null &&
                                  kwRankingHist.map((item, index) => { return (
                                    <tr key={index}>
                                      <td>{item.Date}</td>
                                      <td>{item.Ranking !== 101 ? item.Ranking : 'Out of 100'}</td>
                                      <td>{
                                        item.Match === true ?
                                        <FontAwesomeIcon icon={faCheck} style={{'color':'green'}}/> :
                                        <FontAwesomeIcon icon={faTimesCircle} style={{'color':'red'}}/> 
                                        }</td>
                                      <td>{item['Found URL'].replace(/^https:\/\/bbcincorp\.com\/|^https:\/\/gleads.vn\/vi\/|^https:\/\/bbcincorp\.sg\//i,'')}</td>
                                    </tr>
                                  )})
                                }
                              </tbody>
                            </table>
                            </div>
                            <div className={`d-flex justify-content-center typingIndicatorContainer ${isLoading === false && 'd-none'}`}>
                                <div class="typingIndicatorBubble">
                                <div class="typingIndicatorBubbleDot"></div>
                                <div class="typingIndicatorBubbleDot"></div>
                                <div class="typingIndicatorBubbleDot"></div>
                                </div>
                            </div>
                            {isLoading === false &&
                            <h4 className={`text-muted text-center ${kwRankingHist.length > 0 && 'd-none'}`}>No Records founded in selected period!</h4>
                            }
                          </div>
                          <div class="modal-footer">
                              <button type="button" class="btn btn-primary" data-bs-dismiss="modal">Close</button>
                          </div>
                        </div>
                    </div>
                  </div>
                  {/* Modal PopUP*/}
                  <h4 className={`text-muted text-center ${rankDetail.length > 0 && 'd-none'}`}>No Records founded in selected period!</h4>
              </div>
              <div className="d-flex">
                <div class="input-group mb-3 me-3 ms-auto w-25">
                  <label class="input-group-text" for="inputGroupSelect01">Results per page</label>
                  <select class="form-select" id="inputGroupSelect01" onChange={e => changeShowResultHandler(e.target.value)}>
                    <option selected value="10">10</option>
                    <option value="20">20</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                    <option value="500">500</option>
                  </select>
                </div>
                <nav aria-label="Page navigation example">
                  <ul class="pagination">
                    <li class={`page-item ${currentPageRange === 1 && 'disabled'}`}
                    onClick = {e => {
                      e.preventDefault();
                      setCurrentPageRange(current => {
                        if(current > 1){
                          return current -1
                        }
                        return current;
                        })}}
                    ><a class="page-link" href="#">Previous</a></li>
                    {pagination.map((item, index) => {return(
                      <li  key={index+1} id={`page-${index+1}`} className={`page-item ${index+1 === selectedPage && 'active'} ${(index+1 > 10*currentPageRange || index + 1 <= 10*(currentPageRange-1)) && 'd-none'}`}>
                        {item}
                      </li>
                      )})
                    }
                    <li
                    class={`page-item ${(pagination.length <= 10 || currentPageRange + 1 > Math.ceil(pagination.length/10)) && 'disabled'}`}
                    onClick = {e => {
                      e.preventDefault();
                      setCurrentPageRange(current => {
                        if(current + 1 <= Math.ceil(pagination.length/10)) {
                          return (current +1);
                        }
                        return current;
                      })}
                      }
                    ><a class="page-link" href="#">Next</a></li>
                  </ul>
                </nav>
                
              </div>
            </div>
        </div>
    )
};

export default RankTracking;