const DEVICE_ID_KEY = 'device_id';

function generateDeviceId() {
    const fingerprint = {
        userAgent: navigator.userAgent,
        language: navigator.language,
        screenResolution: `${window.screen.width}x${window.screen.height}`,
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        // Add more unique identifiers as needed
        // colorDepth: window.screen.colorDepth,
        pixelRatio: window.devicePixelRatio,
        // vendor: navigator.vendor,
    };
    
    // Create a hash of these values
    return btoa(JSON.stringify(fingerprint));
}

export function getDeviceId() {
    let deviceId = localStorage.getItem(DEVICE_ID_KEY);
    if (!deviceId) {
        deviceId = generateDeviceId();
        localStorage.setItem(DEVICE_ID_KEY, deviceId);
    }
    return deviceId;
}