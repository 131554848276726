import { useState, Fragment, useRef, useEffect } from "react";
import {showLoading, hideLoading } from 'react-redux-loading-bar';
import { useDispatch } from "react-redux";

import "bootstrap/dist/css/bootstrap.min.css";
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import * as bootstrap from 'bootstrap/dist/js/bootstrap';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faCircleCheck,
    faDownload, faExpandAlt, faEye, faFloppyDisk, faMinus, faPaperPlane,
    faSpinner,
    faTimesCircle,
    faTrash
} from "@fortawesome/free-solid-svg-icons";
import { v4 as uuid4 } from 'uuid';
import Papa from 'papaparse';

import { Document, Page, pdfjs } from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/Page/TextLayer.css';

// import MatchedItem from "../components/matched-item";
import { getAuthToken } from "../util/token";
// import { select } from "d3";
import apiClient from "../api/client";

function PDFParser () {

    pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;
    // pdfjs.GlobalWorkerOptions.workerSrc = new URL(
    //     'pdfjs-dist/build/pdf.worker.min.mjs',
    //     import.meta.url,
    //   ).toString();

    // get auth token
    const token = getAuthToken();
    const userName = localStorage.getItem('username');
    const dispatch = useDispatch();

    const fileUpload = useRef(null);
    const [uploadedFiles, setUploadedFiles] = useState([]);
    const [filteredUploaded, setfilteredUploaded] = useState([]);
    const [billingItems, setBillingItems] = useState([]);

    // Uploaded files validation
    const [errorMessages, setErrorMessages] = useState([]);

    const [selectKwList, setSelectKwList] = useState([]);
    const [itemPageBreak, setItemPageBreak] = useState({});
    //Popup Expand mode
    const [expandMode, setExpandMode] = useState(false);
    //Set Active File in View
    const [activeFileView, setActiveFileView] = useState();

    //PDF View
    const [pdfFile, setPdfFile] = useState(null);  // To store the uploaded PDF file
    const [numPages, setNumPages] = useState(null); // To track the total number of pages
    const [pageNumber, setPageNumber] = useState(1); // To track the current page number
    const [zoom, setZoom] = useState(0.8); // Initial zoom level
    const [checkedPages, setCheckedPages] = useState({});
    //Image View
    const canvasRef = useRef(null);
    const [image, setImage] = useState(null); // HTML Image object
    const [canvasWidth, setCanvasWidth] = useState(500);  // Initial canvas width
    const [canvasHeight, setCanvasHeight] = useState(500);  // Initial canvas height
    const [canvasBlocks, setCanvasBlocks] = useState({}); // text overlay on image

    const cellRefs = useRef(Array.from({ length: 7 }, () => []));
    const [activePattern, setActivePattern] = useState({});
    // Saved pattern search
    const [selectedPattern, setSelectedPattern] = useState();
    const [savedPatterns, setSavedPatterns] = useState([]);
    const [filteredSavedPatterns, setFilteredSavedPatterns] = useState([]);
    const searchStr = useRef();

    // Modal Ref
    const viewPDFModalRef = useRef(null);
    const savePatternModalRef = useRef(null);

    const [groups, setGroups] = useState({});
    // {
    //   currentPageOriginal: '',
    //   previousPageOriginal: '',
    //   previousPageItem: '',
    //   currentPageItem: ''
    // }

    // State to manage the table rows in Tab 2
    const [tableRows, setTableRows] = useState([
        // {
        //     "date": "20/03/2024",
        //     "item_description": "Invoice No.124-1982: Due 19/04/2024. Sight Gain Markets LTD\n--- Incorporation Fee (BVI) = USD 100.00",
        //     "quantity": 1,
        //     "unit_price": 100,
        //     "vat": 0,
        //     "amount": 100,
        //     "currency_code": "USD"
        // },
        // {
        //     "date": "20/03/2024",
        //     "item_description": "Invoice No.124-1983: Due 19/04/2024. WhitePool Technologies Limited\n--- Certified set by RA (Nevis) = USD 100.00\n--- Notarial + Aspotille (Sey) = USD 75.00",
        //     "quantity": 1,
        //     "unit_price": 75,
        //     "vat": 0,
        //     "amount": 75,
        //     "currency_code": "USD"
        // },
        // {
        //     "date": "20/03/2024",
        //     "item_description": "Invoice No.124-1984: Due 19/04/2024. RLC Lab Limited\n--- Incorporation Fee (BVI) = USD 885.00",
        //     "quantity": 1,
        //     "unit_price": 885,
        //     "vat": 0,
        //     "amount": 885,
        //     "currency_code": "USD"
        // },
        // {
        //     "date": "20/03/2024",
        //     "item_description": "Invoice No.124-2000: Due 19/04/2024. NWS LLC\n--- Certified Collation of documents by Notary (LLC Belize) = USD 35.00",
        //     "quantity": 1,
        //     "unit_price": 35,
        //     "vat": 0,
        //     "amount": 35,
        //     "currency_code": "USD"
        // },
        // {
        //     "date": "21/03/2024",
        //     "item_description": "Invoice No.124-2023: Due 20/04/2024. Synergistic Strategy Inc.\n--- Certificate of Incumbency (Sey) = USD 75.00",
        //     "quantity": 1,
        //     "unit_price": 75,
        //     "vat": 0,
        //     "amount": 75,
        //     "currency_code": "USD"
        // },
        // {
        //     "date": "21/03/2024",
        //     "item_description": "Invoice No.124-2036: Due 20/04/2024. Evo X Labs Limited\n--- Incorporation Fee (Sey) = USD 250.00",
        //     "quantity": 1,
        //     "unit_price": 250,
        //     "vat": 0,
        //     "amount": 250,
        //     "currency_code": "USD"
        // },
        // {
        //     "date": "22/03/2024",
        //     "item_description": "Invoice No.124-2046: Due 21/04/2024. Wired Management Corporation\n--- Annual Economic Substance Filing (BVI) = USD 100.00\n--- Annual Returns Admin Filing Fee (BVI) = USD 150.00",
        //     "quantity": 1,
        //     "unit_price": 250,
        //     "vat": 0,
        //     "amount": 250,
        //     "currency_code": "USD"
        // },
        // {
        //     "date": "22/03/2024",
        //     "item_description": "Invoice No.124-2047: Due 21/04/2024. MAYSEC Ltd.\n--- Annual Economic Substance Filing (BVI) = USD 100.00",
        //     "quantity": 1,
        //     "unit_price": 100,
        //     "vat": 0,
        //     "amount": 100,
        //     "currency_code": "USD"
        // },
        // {
        //     "date": "22/03/2024",
        //     "item_description": "Invoice No.124-2045: Due 21/04/2024. Oceanic Ash Research and Development Limited\n--- Certificate of Incumbency issued by RA (BVI) = USD 80.00",
        //     "quantity": 1,
        //     "unit_price": 80,
        //     "vat": 0,
        //     "amount": 80,
        //     "currency_code": "USD"
        // }
    ]);

    useEffect(() => {
        // Initialize the modals once and store them in refs
        viewPDFModalRef.current = new bootstrap.Modal(document.getElementById('ModalViewPDF'));
        savePatternModalRef.current = new bootstrap.Modal(document.getElementById('ModalSavePattern'));

        // Attach on hide event for the PDF modal
        const modalElement = document.getElementById('ModalViewPDF');
        const handleHide = () => {
            console.log('ModalViewPDF is hiding');
            setPdfFile(null);
            setTableRows([]);
            setGroups(prev => {
                const newGroups = Object.fromEntries(
                    Object.entries(prev).map(([key, value]) => [
                      key,
                      value.filter(item => Object.values(item).every(val => val !== ''))
                    ]).filter(([_, filteredItems]) => filteredItems.length > 0)
                  );
                // console.log(newGroups);
                return newGroups
            });
        };

        modalElement.addEventListener('hide.bs.modal', handleHide);

        // Cleanup: remove event listener when component unmounts
        return () => {
        modalElement.removeEventListener('hide.bs.modal', handleHide);
        };
      }, []);
    
    // useEffect(() => {
    //     // Enhance the text layer of each page after rendering
    //     for (let pageNumber = 1; pageNumber <= numPages; pageNumber++) {
    //         handleTextLayer(pageNumber);
    //     }
    //     console.log('num pages changed');   
    // }, [numPages]);

    const handleFile = (file) => {
        const reader = new FileReader(file);
        reader.readAsDataURL(file); // Example: Read file content as base64 data
        reader.onload = () => {
            // Handle file content here, e.g., send it to a server
            const fileContent = reader.result;
            console.log('file content: ', fileContent);
        };
    };

    const handleDrop = (event) => {
        event.preventDefault();
        console.log(event.dataTransfer.items[0].getAsFile());
        const file = event.dataTransfer.files[0];
        const formData = new FormData();
        formData.append('file', file);
    
    //     fetch(`${process.env.REACT_APP_HOST_IP}/api/upload/image`, {
    //         method: 'POST',
    //         headers: {
    //           'Authorization': 'Bearer ' + token,
    //           'X-User': userName
    //         },
    //         body: formData
    //       })
    //       .then(response => response.json())
    //       .then(data => {
    //         console.log('Image uploaded successfully:', data.imageUrl);
    //       })
    //       .catch(error => {
    //         console.error('Error uploading image:', error);
    //       });
      };
    const handleDragOver = (event) => {
     event.preventDefault();
     event.stopPropagation();
    };

    // Function to validate individual image files
    const validateImageFile = (file) => {
        let acceptedTypes = ['image/jpeg', 'image/png', 'application/pdf', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'];

        return new Promise((resolve, reject) => {
            if (!acceptedTypes.includes(file.type)) {
                reject('File is not within accepted file types.');
                return;
            };

            if (file.size > 10*1024*1024) {
                reject('File size exceeds 10MB.');
                return;
            };

            if (file.size === 0) {
                reject('corrupted or invalid!');
                return;
            };
            
            if (['image/jpeg', 'image/png'].includes(file.type)){
                const img = new Image();

                const objectUrl = URL.createObjectURL(file);
                img.src = objectUrl;

                img.onload = () => {
                    // Image is valid
                    URL.revokeObjectURL(objectUrl);
                    resolve(file.name);

                };

                img.onerror = () => {
                    // Image file is corrupted or invalid
                    URL.revokeObjectURL(objectUrl);
                    reject('corrupted or invalid!');
                };
            }else {
                resolve(true);
            };
        });
    };
    
    const fileUploadHandler = async (event) => {
        const files = Array.from(event.target.files);
        // console.log(files);
        if (files.length === 0){
            return
        };

        // const batchSize = 5; // Set the batch size
        const batchedFiles = [];
        const batchedFileNames = [];
        const batchErrors = [];

        await Promise.all(files.map(async (file) => {
            try {
                await validateImageFile(file); // Validate each image in the batch
                batchedFiles.push(file);
                batchedFileNames.push(file.name);
              } catch (error) {
                batchErrors.push(`${file.name}: ${error}`);
              }
        }));

        // Append valid files to the state
        setUploadedFiles(prev => [...prev, ...batchedFiles]);
        console.log(uploadedFiles);
        setfilteredUploaded(prev => [...prev, ...batchedFileNames]);
        setErrorMessages(batchErrors);

        // Optionally log errors
        if (batchErrors.length > 0) {
            console.log('Errors:', batchErrors);
        }
        
    };


    const cancelHandler = (fileName) => {
        console.log(fileName);
        setBillingItems(prev => prev.filter(item => item.invoice_file_name !== fileName));
        setUploadedFiles(d => d.filter(item => item.name !== fileName));
        setfilteredUploaded(d => d.filter(item => item !== fileName));
        return
    };
    const filterHandler = (item) => {
        let l = uploadedFiles.filter(i => {
            return (
                (item !== '' ? i.name.search(new RegExp(`^${item}`, 'i')) !== -1 : true)
            )
        });
        let d = l.map(item => item.name);
        setfilteredUploaded(d);
    };

    const startParsingHandler = () => {
        if (uploadedFiles.length === 0 || selectKwList.length === 0) return;
        dispatch(showLoading());
        const formData = new FormData();
        uploadedFiles.map(file => {
            console.log(file.name)
            if (selectKwList.includes(file.name)){

                formData.append('file', file);
            }
        });
        console.log(groups);
        formData.append('pattern', JSON.stringify(groups));
        const nonEmptyBreak = Object.fromEntries(Object.entries(itemPageBreak).filter(([k, v]) => v.length > 0));
        // console.log(nonEmptyBreak);
        formData.append('page_break', JSON.stringify(nonEmptyBreak));
        // console.log(formData);
        apiClient.post('/api/invoice-parser-v1', formData, {
            headers: {
                'X-User': userName,
            }
        })
        .then(({data}) => {
            dispatch(hideLoading());
            console.log('File parsed successfully:', data);
            setBillingItems(prev => [
            ...prev,
            ...data
            ]);
        })
        .catch(error => {
            console.error('File parsed error:', error);
        });
    };

    const downloadReportHandler = () => {
        if(billingItems.length === 0) return;
        let d = []
        billingItems.map(item => {
            return item.items.map(i => {
                let VAT = i.vat;
                if (i.vat === null) VAT = 0;
                let QTY = i.quantity;
                if (i.quantity === null) QTY = 1;
                let unitPrice = VAT + i.unit_price;
                if (unitPrice === 0 && i.amount !== null) unitPrice = i.amount;
                let amount = i.amount;
                if (i.amount === null) amount = QTY*unitPrice;
                let total = item.items.reduce((acc, k) => {
                    if (k.amount !== null) {
                        acc += k.amount;
                    } else {
                        let VAT = k.vat;
                        if (k.vat === null) VAT = 0;
                        let QTY = k.quantity;
                        if (k.quantity === null) QTY = 1;
                        let unitPrice = VAT + k.unit_price;
                        acc += unitPrice*QTY;
                    }
                    return acc
                }, 0);
                return d.push({
                    ContactName: item.provider_name, // required
                    EmailAddress: '',
                    POAddressLine1: '',
                    POAddressLine2: '',
                    POAddressLine3: '',
                    POAddressLine4: '',
                    POCity: '',
                    PORegion: '',
                    POPostalCode: '',
                    POCountry: '',
                    SAAddressLine1: '',
                    SAAddressLine2: '',
                    SAAddressLine3: '',
                    SAAddressLine4: '',
                    SACity: '',
                    SARegion: '',
                    SAPostalCode: '',
                    SACountry: '',
                    InvoiceNumber: item.invoice_id, // required
                    Reference: '',
                    InvoiceDate: item.invoice_date, // required
                    DueDate: item.due_date, // required
                    PlannedDate: '',
                    Total: total, //required
                    TaxTotal: '',
                    InvoiceAmountPaid: '',
                    InvoiceAmountDue: '',
                    InventoryItemCode: '',
                    Description: i.item_description, //required
                    Quantity: QTY, // required
                    UnitAmount: unitPrice, //required
                    Discount: '',
                    LineAmount: amount, // required
                    AccountCode: '',
                    TaxType: '',
                    TaxAmount: '',
                    TrackingName1: '',
                    TrackingOption1: '',
                    TrackingName2: '',
                    TrackingOption2: '',
                    Currency: i.currency_code, // required
                    Type: '',
                    Sent: '',
                    Status: '',
                    // 'File Name': item.invoice_file_name,
                    // 'Client Name': item.client_name,
                    // 'Provider Name': item.provider_name,
                    // 'Invoice Id': item.reference_id,
                    // 'Issued Date': item.invoice_date,
                    // 'Due Date': item.due_date,
                    // ...i,
                })
            })
        })
        // console.log(d);
        const csv = Papa.unparse({
            // "fields": ['URL', "Keyword", 'Volume', 'KD', 'CPC'],
            "data": d
        });
        const blob = new Blob([csv]);

        const url = window.URL.createObjectURL(blob, {type: 'text/plain' });
        // console.log(blob);
        const link = document.createElement("a");
        link.download = 'billing.csv';
        link.href = url;
        link.click();
    };

    const changeSelectedKwHandler = (kw, type) => {
        if (kw === undefined || kw === null) {
            if (type === 'select'){
                setSelectKwList(filteredUploaded);
            } else if (type === 'deselect'){
                setSelectKwList([]);
            } 
        } else {
            const item_included = selectKwList.includes(kw)
            if (item_included){
                const new_list = selectKwList.filter(item => !(item === kw));
                setSelectKwList(new_list);
            }else {
                setSelectKwList(c => [...c, kw]);
            }
            
        }
        console.log(selectKwList);
    };

    const updateItemBreakHandler = (page_break) => {
        const page_num = page_break.trim().split(',').filter(item => Number.isInteger(Number(item)) && Number(item) !== 0).map(Number);
        // setItemPageBreak(prev => ({
        //         ...prev,
        //         [activeFileView]: page_num
        //     }));
        const checkItems = page_num.reduce((acc, curr) => {
            acc[curr] = true;  // Set the current array item as a key and assign it a value of true
            return acc;
          }, {});
        setCheckedPages(prevState => ({
            ...prevState,
            [activeFileView] : checkItems
        }));
        console.log(itemPageBreak);
    };

    const viewFileHandler = (file_name) => {
        setZoom(0.8);
        setExpandMode(false);
        setActiveFileView(file_name);
        console.log(file_name);
        const items = billingItems.filter(item => item.invoice_file_name === file_name);
        // console.log(items);
        if (items.length > 0){
            setTableRows(items[0].items);
        };
        // Update checkbox and input value
        if (itemPageBreak[file_name]){
            const checkItems = itemPageBreak[file_name].reduce((acc, curr) => {
                acc[curr] = true;  // Set the current array item as a key and assign it a value of true
                return acc;
              }, {});
            setCheckedPages({
                    [file_name]: checkItems
                });
            // console.log(checkItems);
            document.getElementById('break-item-page').value = itemPageBreak[file_name].join(', ');
        } else {
            setCheckedPages({});
            document.getElementById('break-item-page').value = '';
        }
        // Update pdf view
        let pdfFileObject;
        console.log(uploadedFiles);
        uploadedFiles.map(file => {
            if (file.name === file_name && file.type === 'application/pdf'){
                setPdfFile(file);
                setPageNumber(1);
            } else if (file.name === file_name && file.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'){
                const formData = new FormData();
                formData.append('file', file);
                // convert docx to pdf
                try {
                    apiClient.post('/api/convert-docx-pdf', formData, {responseType: 'blob'})
                    .then( async (response) => {
                            // Check if the response is okay
                            if (response.status !== 200) {
                                throw new Error(response.data.detail || 'Failed to upload the file');
                            };
                            // Create a blob from the response
                            const blob = await response.data;
                    
                            // Convert the Blob to a File object
                            pdfFileObject = new File([blob], `${file_name}`, { type: 'application/pdf' });
                            // console.log(pdfFileObject);
                            // update uploadedFiles
                            setUploadedFiles(prev => {
                                const files = prev.map(f => f.name === file_name ? pdfFileObject : f);
                                return files
                            })
                            setPdfFile(pdfFileObject);
                            setPageNumber(1);
                        })
                } catch (error) {
                    console.log(error.message);
                }
            } else if (file.name === file_name && ['image/jpeg', 'image/png'].includes(file.type)){
                const formData = new FormData();
                formData.append('file', file);
                if (!Object.keys(canvasBlocks).includes(file_name)){
                    try {
                        apiClient.post('/api/extract-text-from-image', formData, {headers: {'Content-type': 'multipart/form-data'}})
                        .then(({data}) => {
                                // Check if the response is okay
                                if (!data) {
                                    throw new Error('Failed to upload the file');
                                }
                                // console.log(data);
                                setCanvasBlocks(prev => ({
                                    ...prev,
                                    [file_name]: data
                                }));
                                setPdfFile(file);
                            })
                    } catch (error) {
                        console.log(error.message);
                    }
                }else {
                    setPdfFile(file);
                }
                
            }
        });


        // const m = new bootstrap.Modal(document.getElementById('ModalViewPDF'));
        // m.show();
        viewPDFModalRef.current.show();
        // Load saved patterns
        if (savedPatterns.length === 0){
            apiClient.post('/api/save-item-pattern', {})
            .then(({data}) => {
                console.log(data);
                setSavedPatterns(data);
                setFilteredSavedPatterns(data);
            })
        } 
    };

    // Function to capture the number of pages in the PDF
    const onDocumentLoadSuccess = ({ numPages }) => {
        setNumPages(numPages);
    };

    const ZoomController = ({ zoom, zoomIn, zoomOut }) => {
        return (
            <div className="d-flex shadow align-items-center justify-content-center p-2 mt-1" style={{backgroundColor: 'white'}}>
                <button className='zoom-button' onClick={zoomOut} disabled={zoom <= 0.5}>
                    −
                </button>
                <span style={{ margin: '0 10px' }}>{(zoom * 100).toFixed(0)}%</span>
                <button className='zoom-button' onClick={zoomIn} disabled={zoom >= 2.0}>
                    +
                </button>
            </div>
        );
    };

    const zoomIn = () => {
        setZoom((prevZoom) => Math.min(prevZoom + 0.1, 2.0)); // Limit zoom to max 3.0
    };

    const zoomOut = () => {
        setZoom((prevZoom) => Math.max(prevZoom - 0.1, 0.5)); // Limit zoom to min 0.5
    };

    useEffect(() => {
        if (pdfFile && ['image/jpeg', 'image/png'].includes(pdfFile.type)) {
          const img = new Image();
          const imageUrl = URL.createObjectURL(pdfFile);  // Create an object URL from the file
          img.src = imageUrl;
    
          img.onload = () => {
            setImage(img);  // Set the image object when loaded
            setCanvasWidth(img.width);  // Set canvas width to image width
            setCanvasHeight(img.height);  // Set canvas height to image height
            URL.revokeObjectURL(imageUrl);  // Revoke the object URL to free memory
          };
        }
      }, [pdfFile]);

    useEffect(() => {
        if (image && ['image/jpeg', 'image/png'].includes(pdfFile.type)) {
            const canvas = canvasRef.current;
            const ctx = canvas.getContext('2d');
            // Dynamically set canvas size based on zoom level and original size
            canvas.width = canvasWidth * zoom;
            canvas.height = canvasHeight * zoom;
            drawImage(image, ctx, zoom);  // Draw the image with the current zoom level
        }
    }, [image, zoom]);

    const drawImage = (img, ctx, zoom) => {
        const canvas = canvasRef.current;
        ctx.clearRect(0, 0, canvas.width, canvas.height);  // Clear the canvas
        // Save the context state (before scaling)
        ctx.save();

        // Translate and scale to zoom the image
        ctx.translate(canvas.width / 2, canvas.height / 2);  // Move the origin to the center
        ctx.scale(zoom, zoom);  // Apply zoom scaling
        ctx.translate(-canvas.width / 2, -canvas.height / 2);  // Move the origin back

        const width = img.width;
        const height = img.height;
        const x = (canvas.width - width) / 2;
        const y = (canvas.height - height) / 2;
        ctx.drawImage(img, x, y, width, height);  // Draw the image with the zoom level

        // Restore the context state (reset transformations)
        ctx.restore();
    };

    const handleCanvasClick = (fileName, pageNumber) => {

        const checked = {
            ...checkedPages,
            [fileName] : {
              ...checkedPages[fileName],
              [pageNumber]: !checkedPages[fileName]?.[pageNumber], // Toggle checkbox state for the clicked page
            }
        };
        const itemBreakStr = checked[fileName] ? Object.keys(checked[fileName]).filter(key => checked[fileName][key] === true).map(Number).join(', ') : '';
        console.log('itemBreakStr', itemBreakStr);
        document.getElementById('break-item-page').value = itemBreakStr
        setCheckedPages(checked);
        
        // console.log(checkedPages);
    };

    const selectSplitItemHandlder = () => {
        console.log(checkedPages);
        if (Object.keys(checkedPages).length > 0){
            // const fileName = Object.keys(checkedPages)[0]
            const splitPages = Object.keys(checkedPages[activeFileView]).filter(key => checkedPages[activeFileView][key] === true).map(Number);
            // Update item page break
            setItemPageBreak(prev => ({
                ...prev,
                [activeFileView]: splitPages
            }));
        };
        // Save edited extracted items
        setBillingItems(prev => {
            // Check if the invoice already exists
            const existingInvoice = prev.find(inv => inv.invoice_file_name === activeFileView);

            if (existingInvoice) {
                // If it exists, update its items
                return prev.map((inv) => 
                        inv.invoice_file_name === activeFileView ? { ...inv, items: tableRows } : inv
                    );
            } else {
                // If it does not exist, add a new invoice
                return [...prev, { invoice_file_name: activeFileView, items: tableRows }];
            }
        });
        // Save item pattern

    };

    // Function to add a new group
    const addGroup = (patternName) => {
        if (patternName !== '' && patternName !== 'default'){
            apiClient.post('/api/save-item-pattern', {'name': patternName})
            .then(({data}) => {
                console.log(data);
                
            setGroups(prev => ({
                ...prev,
                [activeFileView]: [
                    ...(prev[activeFileView] || []),
                    ...data[0]['items']
                ]}));
            })
        } else if (patternName !== 'default') {
            setGroups(prev => ({
                ...prev,
                [activeFileView]: [
                    ...(prev[activeFileView] || []),
                    {
                        currentPageOriginal: '',
                        previousPageOriginal: '',
                        previousPageItem: '',
                        currentPageItem: ''
                    }
                ]})
            );
        }
    };

    // Function to remove a group
    const removeGroup = (index) => {
        const newGroups = groups[activeFileView].filter((_, groupIndex) => groupIndex !== index);
        setGroups(prev => ({
            ...prev,
            [activeFileView]: newGroups
        }));
    };

    // Open item template modal
    const openPatternTemplate  = (index) => {
        const item = {...groups[activeFileView][index]};
        console.log(item);
        setActivePattern(item);
        // viewPDFModalRef.current.hide();
        savePatternModalRef.current.show();
    };

    // Save item template
    const savePatternTemplate = () => {
        apiClient.post('/api/save-item-pattern',
            {
                'name': selectedPattern,
                'items': [activePattern]
            })
        .then(({data}) => {
            console.log(data);
            setSavedPatterns(data);
        })
    };

    // Function to handle changes in group inputs
    const handleGroupChange = (index, field, value) => {
        const newGroups = [...groups[activeFileView]];
        newGroups[index][field] = value;
        setGroups(prev => ({
            ...prev,
            [activeFileView]: newGroups
        }));
    };

    // Function to add a new row in the table in Tab 2
    const addRow = () => {
        setTableRows([...tableRows, {
            date: '',
            item_description: '',
            quantity: '',
            unit_price: '',
            vat: '',
            amount: '',
            currency_code: ''
        }]);
    };

    // Function to delete a row in the table in Tab 2
    const removeRow = (index) => {
        const newRows = tableRows.filter((_, rowIndex) => rowIndex !== index);
        setTableRows(newRows);
    };

    // Function to handle changes in table cells in Tab 2
    const handleTableChange = (index, field, value, columnIndex) => {
        const newRows = [...tableRows];
        newRows[index][field] = value;
        setTableRows(newRows);

        // Move the cursor to the end of the input
        setTimeout(() => {
            const cell = cellRefs.current[columnIndex][index];
            if (cell) {
            const range = document.createRange();
            const sel = window.getSelection();
            range.selectNodeContents(cell);
            range.collapse(false); // Collapse the range to the end
            sel.removeAllRanges(); // Remove any existing selections
            sel.addRange(range); // Add the new range
            cell.focus(); // Focus on the cell
            }
        }, 0);
    };

    const filterPatternHandler = (val) => {
        if (savedPatterns.length > 0){
            const search = savedPatterns.filter(item => {
                return (
                    (val !== '' ? item.search(new RegExp(`^${val}`, 'i')) !== -1 : true)
                )
            });
            setFilteredSavedPatterns(search);
        };
    };

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            console.log(searchStr.current.value);
            setSelectedPattern(searchStr.current.value);
        }
    };

    // Function to copy text to clipboard
    const copyTextToClipboard = (text) => {
        navigator.clipboard.writeText(text);
        // console.log(`Copied to clipboard: ${text}`);
        // .then(() => console.log(`Copied to clipboard: ${text}`))
        // .catch((err) => console.error('Failed to copy text: ', err));
    };
    
    // Function to handle the text layer
    const handleTextLayer = (textItems, pageNumber) => {

        if (!textItems.items) return;
        // Check if textItems contains the actual `items` array (text spans)
        // console.log(textItems, pageNumber);

        let count = 0;
        const checkAndApply = () => {
            const pageElement = document.querySelector(`.react-pdf__Page[data-page-number="${pageNumber}"]`);
            if (!pageElement) {
              // If the page element is not found, try again after a short delay
              count += 1;
              if (count <=5) 
              setTimeout(checkAndApply, 100);
              return;
            };

            const textLayer = pageElement.querySelector('.react-pdf__Page__textContent');
            if (!textLayer) {
                count += 1;
                if (count <=5) setTimeout(checkAndApply, 100);
                return;
            };

            const spans = textLayer.querySelectorAll('span');
            const constNoneEmptyStr = textItems.items.filter(item => item.str != '');
            // console.log(pageNumber, 'spans length check: ', spans.length, constNoneEmptyStr.length);
            if (spans.length !== constNoneEmptyStr.length) {
                count += 1;
                if (count <=5) setTimeout(checkAndApply, 100);
                return;
            };

            // console.log('page element exists');
            spans.forEach((span, idx) => {

                if (span) {
                // console.log('span exits');
                // Add hover effect for highlighting
                span.onmouseenter = () => {
                    span.style.backgroundColor = 'rgba(255, 255, 0, 0.5)'; // Highlight on hover
                };
                
                span.onmouseleave = () => {
                    span.style.backgroundColor = 'transparent'; // Remove highlight when hover out
                };

                // Add click to copy functionality
                span.onclick = () => {
                    copyTextToClipboard(constNoneEmptyStr[idx].str);
                };

                // Add cursor pointer style to indicate that text is clickable
                span.style.cursor = 'pointer';
                }
            });
        };
        checkAndApply();
    };

    // Handle input changes
    const handleChange = (event) => {
        const { name, value } = event.target;
        setBillingItems(prev => ([
            ...prev.filter(item => item.invoice_file_name !== activeFileView),
            {
                ...prev.filter(item => item.invoice_file_name === activeFileView)[0],
                [name]: value
            }
        ]));
    };




    return (
        <div className="d-flex flex-column h-100">
            <h1 className="text-center my-4">Invoice Parser</h1>
            <div className='d-flex my-4'>
                <div className='d-flex col-md-6'>
                    <div className={`d-flex flex-column m-4 w-100`} style={{height: '300px'}}
                        // onDrop={handleDrop}
                        // onDragOver={handleDragOver}
                        >
                        <div className='d-flex bg-light h-100 w-100 p-2 justify-content-center' style={{border: '2px dashed #dee2e6'}}>
                            {/* <h5>Drag and drop images here</h5> */}
                            <div className={`d-flex flex-column ${errorMessages.length > 0 ? 'col-md-6' : ''} align-items-center justify-content-center`}>
                                <p>Supported files: .JPG, .PNG, .PDF, .DOCX</p>
                                <span>Each file size must be less than 10MB.</span>
                                <h5>--- or ---</h5>
                                <input class="form-control d-none" id="formFile" type="file" multiple accept=".jpg,.png,.pdf, .docx" placeholder="" ref={fileUpload} onChange={fileUploadHandler}/>
                                <button type="button" class="btn btn-dark" onClick={e => {fileUpload.current.value = null; fileUpload.current.click()}}>Select from your files</button>
                            </div>
                                {errorMessages.length > 0 && (
                                    <div className='d-flex col-md-6 align-items-center'>
                                        <div className="d-flex flex-column">
                                            <strong>Error!</strong> The following files failed to upload due to validation errors:
                                            <div className='overflow-auto'>
                                                <ul>
                                                    {errorMessages.map((fileName, index) => (
                                                        <li key={index}><b>{fileName}</b></li>
                                                    ))}
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                )}
                        </div>
                    </div>
                </div>
                {/* Modal Save Pattern*/}
                <div class="modal fade" style={{zIndex: 1060}} id="ModalSavePattern" tabindex="-1" role="dialog" aria-labelledby="ModalCenterTitle" aria-hidden="true">
                    <div class={`modal-dialog modal-dialog-centered modal-md`} role="document">
                        <div class="modal-content shadow-lg">
                        <div className="modal-header">
                            <h5 class="modal-title me-auto" id="ModalLongTitle">Save pattern</h5>
                        </div>
                        <div class="modal-body" style={{'min-height':'150px'}}>
                            <div className='d-flex'>
                                <h6 className='me-4'>Name your pattern</h6>
                                <div class="dropdown me-3 w-50">
                                    <button class="btn btn-light dropdown-toggle w-100" type="button" id="location" data-bs-toggle="dropdown" aria-expanded="false">
                                        {selectedPattern}
                                    </button>
                                    <ul class="dropdown-menu p-2" aria-labelledby="dropdownMenuButton1" style={{maxHeight:'150px', 'overflow': 'auto', width: '250px'}}>
                                        <input type="text" className="form-control sticky-top" placeholder="Search or enter new pattern"
                                        onChange = {e => filterPatternHandler(e.target.value)}
                                        onKeyDown={handleKeyDown}
                                        ref={searchStr}
                                        />
                                        {filteredSavedPatterns.length > 0 && filteredSavedPatterns.map((item, index) => {
                                            return (
                                                <li key={index} onClick={e => {setSelectedPattern(item)}}><a class="dropdown-item" href="#">{item}</a></li>
                                            )
                                        })}
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-primary" data-bs-dismiss="modal" onClick={savePatternTemplate}>Save</button>
                            <button type="button" class="btn btn-secondary" onClick={() => {
                                savePatternModalRef.current.hide();
                                // viewPDFModalRef.current.show();
                            }}>Go Back</button>
                        </div>
                        </div>
                    </div>
                </div>
                {/* Modal Save Pattern*/}
                {/* Modal View PDF*/}
                <div class="modal fade" id="ModalViewPDF" tabindex="-1" role="dialog" aria-labelledby="ModalCenterTitle" aria-hidden="true">
                    <div class={`modal-dialog modal-dialog-centered ${expandMode ? 'modal-fullscreen' : 'modal-xl'}`} role="document">
                        <div class="modal-content">
                        <div className="modal-header">
                            <h5 class="modal-title me-auto" id="ModalLongTitle">View Invoice</h5>
                            <button type='button' className='btn btn-light text-right' onClick={() => {setExpandMode(prev => !prev)}}>
                                <FontAwesomeIcon icon={faExpandAlt} />
                            </button>
                        </div>
                        <div class="modal-body" style={{'max-height':'700px', 'overflow': 'hidden'}}>
                            <div className='d-flex'>
                                <div className="d-flex flex-column col-md-6 overflow-auto" style={{height: '680px'}}>
                                    <div className='d-flex sticky-top justify-content-end' style={{ zIndex: '5'}}>
                                        <ZoomController zoom={zoom} zoomIn={zoomIn} zoomOut={zoomOut} />
                                    </div>
                                    <div class="d-flex align-items-left flex-column mb-4" style={{position: 'relative'}}>
                                        {pdfFile && pdfFile.type === 'application/pdf' ?
                                        <Document
                                            file={pdfFile}
                                            onLoadSuccess={onDocumentLoadSuccess}
                                        >
                                            {/* Render each page */}
                                            {Array.from(new Array(numPages), (el, index) => (
                                                <div className='canvas-container' style={{border: '1px solid #D3D3D3', marginBottom: '5px', padding: '5px', position: 'relative'}}
                                                    
                                                > 
                                                    <Page key={`page_${index + 1}`} pageNumber={index + 1}
                                                        renderTextLayer={true}
                                                        scale={zoom}
                                                        onGetTextSuccess={(item) => handleTextLayer(item, index+1)} // Enhance text layer after rendering
                                                        style={{width: '100%', height: 'auto'}}
                                                    />
                                                    <p style={{textAlign: 'left'}}>{`Page ${index+1}`}</p>
                                                    {/* Checkboxes overlay */}
                                                    <div
                                                        style={{
                                                        position: 'absolute',
                                                        top: '5px',
                                                        left: '5px',
                                                        zIndex: 5
                                                        }}
                                                        onClick={() => handleCanvasClick(pdfFile.name, index+1)} // Canvas click handler
                                                    >
                                                        <div
                                                            style={{
                                                                position: 'absolute',
                                                                top: 0,
                                                                left: '10%',
                                                            }}
                                                            
                                                        >
                                                            <input
                                                                type="checkbox"
                                                                className="checkbox-hover"
                                                                checked={checkedPages[pdfFile.name]?.[index + 1]} // Checkbox checked state
                                                                style={{
                                                                width: '20px',
                                                                height: '20px',
                                                                pointerEvents: 'auto', // enables clicking
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                        </Document> :
                                        // <div
                                        //     style={{
                                        //     width: 'auto',  // Fixed container size
                                        //     height: '680px',  // Fixed container size
                                        //     overflow: 'auto',  // Enable scroll if content is larger than container
                                        //     border: '1px solid #ccc',
                                        //     }}
                                        // ></div>
                                            <>
                                                <canvas
                                                ref={canvasRef}
                                                width={canvasWidth}
                                                height={canvasHeight}
                                                style={{
                                                    border: '1px solid #ccc',
                                                    marginTop: '20px',
                                                    display: 'block',
                                                    background: '#f0f0f0',
                                                    position: 'absolute',
                                                }}
                                                ></canvas>
                                                {/* Render the bounding boxes (no text) */}
                                                {canvasBlocks[activeFileView]?.map((block, index) => (
                                                    <div
                                                    key={index}
                                                    className="overlay-box"
                                                    style={{
                                                        position: 'absolute',
                                                        left: block.x * zoom ,
                                                        top: block.y * zoom,
                                                        width: block.width * zoom,
                                                        height: block.height * zoom,
                                                        backgroundColor: 'transparent',
                                                        marginTop: '20px',
                                                        cursor: 'pointer',
                                                    }}
                                                    onMouseEnter={(e) => (e.target.style.backgroundColor = 'rgba(255, 255, 0, 0.5)')}  // Highlight on hover
                                                    onMouseLeave={(e) => (e.target.style.backgroundColor = 'transparent')}  // Remove highlight on mouse leave
                                                    onClick={() => copyTextToClipboard(block.text)}  // Copy text on click
                                                    >
                                                    </div>
                                                ))}
                                            </>
                                        }
                                    </div>
                                </div>
                                <div className="d-flex flex-column col-md-6 overflow-auto" style={{height: '700px'}}>
                                <div className="container">
                                    {/* Tabs Navigation */}
                                    <ul className="nav nav-tabs" id="myTab" role="tablist">
                                        <li className="nav-item" role="presentation">
                                        <button className="nav-link active" id="tab1-tab" data-bs-toggle="tab" data-bs-target="#tab1" type="button" role="tab" aria-controls="tab1" aria-selected="true">Item Pattern</button>
                                        </li>
                                        <li className="nav-item" role="presentation">
                                        <button className="nav-link" id="tab2-tab" data-bs-toggle="tab" data-bs-target="#tab2" type="button" role="tab" aria-controls="tab2" aria-selected="false">Extraction</button>
                                        </li>
                                    </ul>

                                    {/* Tab Content */}
                                    <div className="tab-content m-4" id="myTabContent">
                                        
                                        {/* Tab 1 Content */}
                                        <div className="tab-pane fade show active" id="tab1" role="tabpanel" aria-labelledby="tab1-tab">
                                            <div class="alert alert-info">
                                                <b>Optional Settings for Long-Statement Invoices (Accuracy Enhancement)</b>
                                                <ul>
                                                    <li><b>Pages with Split Items:</b> Enter the page numbers where items are split across pages. This setting is required only if more than 5 consecutive pages contain split items. You can either type the page numbers here or select them from the left panel.</li>
                                                    <li><b>Item Pattern:</b> Specify the pattern used to extract split items. You may provide multiple examples to improve accuracy. It also support saving patterns for reuse.</li>
                                                </ul>
                                            </div>
                                            <div id="group-container" className='mb-4'>
                                                <input id='break-item-page' type="text" className="form-control mb-4" placeholder="Item page break, separated by comma" 
                                                    onChange={e => updateItemBreakHandler(e.target.value)}
                                                />
                                                <div className='d-flex mb-3'>
                                                    <select class="form-select me-2 w-75" onChange = {(e) => addGroup(e.target.value)}>
                                                        <option value= "default" selected>Select saved patterns</option>
                                                        {
                                                            savedPatterns.length > 0 && savedPatterns.map((item, index) => (
                                                                <option value={item}>{item}</option>
                                                            ))
                                                        }
                                                    </select>
                                                    <button onClick={e => addGroup('')} className="btn btn-success">+ Add Pattern</button>
                                                </div>
                                                {groups[activeFileView] && groups[activeFileView].map((group, index) => (
                                                    <div className="group" key={index}>
                                                        <div className="d-flex justify-content-between align-items-center mb-2">
                                                            <h5>Split Pattern Example {index + 1}</h5>
                                                            <button
                                                                className="btn btn-primary btn-sm ms-auto me-2"
                                                                onClick={() => openPatternTemplate(index)}
                                                            >
                                                                <FontAwesomeIcon icon={faFloppyDisk} />
                                                            </button>
                                                            <button
                                                                className="btn btn-danger btn-sm"
                                                                onClick={() => removeGroup(index)}
                                                            >
                                                                <FontAwesomeIcon icon={faTrash} />
                                                            </button>
                                                        </div>
                                                        <div className="mb-3">
                                                        <label className="form-label">Current Page Original Item Content</label>
                                                        <textarea
                                                            type="text"
                                                            className="form-control"
                                                            value={group.currentPageOriginal}
                                                            onChange={(e) => handleGroupChange(index, 'currentPageOriginal', e.target.value)}
                                                            placeholder="Enter content"
                                                        />
                                                        </div>
                                                        <div className="mb-3">
                                                        <label className="form-label">Previous Page Original Item Content</label>
                                                        <textarea
                                                            type="text"
                                                            className="form-control"
                                                            value={group.previousPageOriginal}
                                                            onChange={(e) => handleGroupChange(index, 'previousPageOriginal', e.target.value)}
                                                            placeholder="Enter content"
                                                        />
                                                        </div>
                                                        <div className="mb-3">
                                                        <label className="form-label">Correctly Parsed Previous Page Item</label>
                                                        <textarea
                                                            type="text"
                                                            className="form-control"
                                                            value={group.previousPageItem}
                                                            onChange={(e) => handleGroupChange(index, 'previousPageItem', e.target.value)}
                                                            placeholder="Enter content"
                                                        />
                                                        </div>
                                                        <div className="mb-3">
                                                        <label className="form-label">Correctly Parsed Current Page Item</label>
                                                        <textarea
                                                            type="text"
                                                            className="form-control"
                                                            value={group.currentPageItem}
                                                            onChange={(e) => handleGroupChange(index, 'currentPageItem', e.target.value)}
                                                            placeholder="Enter content"
                                                        />
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>

                                        {/* Tab 2 Content */}
                                        <div className="tab-pane fade" id="tab2" role="tabpanel" aria-labelledby="tab2-tab">
                                        {billingItems.length > 0 && <form>
                                            <div class="mb-4 row">
                                                <div class="row g-3">
                                                    <div className="col-12 col-md-6">
                                                        <label htmlFor="contactName" className="form-label">Contact Name</label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            id="contactName"
                                                            name="invoice_file_name"
                                                            value={billingItems.filter(item => item.invoice_file_name === activeFileView)?.[0]?.provider_name ?? ''}
                                                            onChange={handleChange}
                                                        />
                                                    </div>
                                                    <div className="col-12 col-md-6">
                                                        <label htmlFor="invoiceNumber" className="form-label">Invoice Number</label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            id="invoiceNumber"
                                                            name="invoice_id"
                                                            value={billingItems.filter(item => item.invoice_file_name === activeFileView)?.[0]?.invoice_id ?? ''}
                                                            onChange={handleChange}
                                                        />
                                                    </div>

                                                    <div className="col-12 col-md-6">
                                                        <label htmlFor="invoiceDate" className="form-label">Invoice Date</label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            id="invoiceDate"
                                                            name="invoice_date"
                                                            value={billingItems.filter(item => item.invoice_file_name === activeFileView)?.[0]?.invoice_date ?? ''}
                                                            onChange={handleChange}
                                                        />
                                                    </div>
                                                    <div className="col-12 col-md-6">
                                                        <label htmlFor="dueDate" className="form-label">Due Date</label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            id="dueDate"
                                                            name="due_date"
                                                            value={billingItems.filter(item => item.invoice_file_name === activeFileView)?.[0]?.due_date ?? ''}
                                                            onChange={handleChange}
                                                        />
                                                    </div>

                                                    <div className="col-12 col-md-6">
                                                        <label htmlFor="totalItem" className="form-label">Total Items</label>
                                                        <input
                                                            type="number"
                                                            className="form-control"
                                                            id="totalItem"
                                                            name="totalItem"
                                                            value={billingItems.filter(item => item.invoice_file_name === activeFileView)?.[0]?.items.length ?? ''}
                                                        />
                                                    </div>
                                                    <div className="col-12 col-md-6">
                                                        <label htmlFor="totalValue" className="form-label">Total Value</label>
                                                        <input
                                                            type="number"
                                                            className="form-control"
                                                            id="totalValue"
                                                            name="totalValue"
                                                            value={billingItems.filter(item => item.invoice_file_name === activeFileView)?.[0]?.items.reduce((a, v) => a+v.amount, 0) ?? ''}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </form>}
                                        <table className="table table-bordered">
                                            <thead className='sticky-top bg-white'>
                                            <tr>
                                                <th>Date</th>
                                                <th>Item Description</th>
                                                <th>Quantity</th>
                                                <th>Unit Price</th>
                                                <th>VAT</th>
                                                <th>Amount</th>
                                                <th>Currency Code</th>
                                                <th></th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                                {tableRows.map((row, index) => (
                                                    <tr key={index}>
                                                    <td contentEditable="true" style={{ wordBreak: 'break-word' }} onInput={(e) => handleTableChange(index, 'date', e.target.innerText, 0)}
                                                        ref={el => (cellRefs.current[0][index] = el)} // Set ref to cell
                                                        >
                                                        {row.date}
                                                    </td>
                                                    <td contentEditable="true" style={{ wordBreak: 'break-word' }} onInput={(e) => handleTableChange(index, 'item_description', e.target.innerText, 1)}
                                                        ref={el => (cellRefs.current[1][index] = el)} // Set ref to cell
                                                        >
                                                        {row.item_description}
                                                    </td>
                                                    <td contentEditable="true" style={{ wordBreak: 'break-word' }} onInput={(e) => handleTableChange(index, 'quantity', e.target.innerText, 2)}
                                                        ref={el => (cellRefs.current[2][index] = el)} // Set ref to cell
                                                        >
                                                        {row.quantity}
                                                    </td>
                                                    <td contentEditable="true" style={{ wordBreak: 'break-word' }} onInput={(e) => handleTableChange(index, 'unit_price', e.target.innerText, 3)}
                                                        ref={el => (cellRefs.current[3][index] = el)} // Set ref to cell
                                                        >
                                                        {row.unit_price}
                                                    </td>
                                                    <td contentEditable="true" style={{ wordBreak: 'break-word' }} onInput={(e) => handleTableChange(index, 'vat', e.target.innerText, 4)}
                                                        ref={el => (cellRefs.current[4][index] = el)} // Set ref to cell
                                                        >
                                                        {row.vat}
                                                    </td>
                                                    <td contentEditable="true" style={{ wordBreak: 'break-word' }} onInput={(e) => handleTableChange(index, 'amount', e.target.innerText, 5)}
                                                        ref={el => (cellRefs.current[5][index] = el)} // Set ref to cell
                                                        >
                                                        {row.amount}
                                                    </td>
                                                    <td contentEditable="true" style={{ wordBreak: 'break-word' }} onInput={(e) => handleTableChange(index, 'currency_code', e.target.innerText, 6)}
                                                        ref={el => (cellRefs.current[6][index] = el)} // Set ref to cell
                                                        >
                                                        {row.currency_code}
                                                    </td>
                                                    <td>
                                                        <button className="btn btn-danger btn-sm" onClick={() => removeRow(index)}>
                                                            <FontAwesomeIcon icon={faMinus} />
                                                        </button>
                                                    </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                        <button onClick={addRow} className="btn btn-success">+ Add Row</button>
                                        </div>
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-primary" data-bs-dismiss="modal" onClick={selectSplitItemHandlder}>Save</button>
                            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                        </div>
                        </div>
                    </div>
                </div>
                {/* Modal View PDF*/}
                <div className='d-flex col-md-6 flex-column'>
                    <div className='d-flex pb-3 text-nowrap align-items-center'>
                        <h5 className='me-auto'>Uploaded Files:</h5>
                        <input type="text" class="w-50 form-control" placeholder="Search file name..."
                            onChange = {e => filterHandler(e.target.value)}
                        />
                    </div>
                    <div className='d-flex flex-column overflow-auto mb-2' style={{maxHeight: '250px'}}>
                        <ul class="d-flex list-group list-group-flush w-100">
                            {
                                filteredUploaded.map(item => (
                                    <li class="d-flex list-group-item align-items-center justify-content-between">
                                        <div className='d-flex me-2 w-50' role='button' onClick={e => {changeSelectedKwHandler(item)}}>
                                            <input class="form-check-input row-checkbox me-2" style={{minWidth: '14px'}} type="checkbox" checked={selectKwList.includes(item)}/>
                                            <div className='me-2 text-wrap text-break'>{item}</div>
                                        </div>
                                        {
                                            billingItems.length > 0 && billingItems.map(item => item.invoice_file_name).includes(item) &&
                                            <FontAwesomeIcon className='me-2' icon={faCircleCheck} style={{color: 'green'}} />
                                        }
                                        {/* <FontAwesomeIcon className='me-2 fa-spin fa-solid' icon={faSpinner} style={{color: 'blue'}} /> */}
                                        <button type="button" class="btn ms-auto" onClick={e => viewFileHandler(item)}>
                                            <FontAwesomeIcon icon={faEye} style={{color: 'grey'}} />
                                        </button>
                                        <button type="button" class="btn" onClick={e => cancelHandler(item)}>
                                            <FontAwesomeIcon icon={faTimesCircle} style={{color: 'grey'}} />
                                        </button>
                                    </li>
                                ))
                            }
                        </ul>
                    </div>
                    <div className='d-flex align-items-center mt-auto'>
                        <strong className='me-auto'>Total: {filteredUploaded.length} file(s)</strong>
                        <div className='d-flex'>
                            <a href="#" className='me-2' onClick={e => changeSelectedKwHandler(null, 'select')}>Select all</a>
                            <span className='me-2'>|</span>
                            <a href="#" className='me-2' onClick={e => changeSelectedKwHandler(null, 'deselect')}>De-select all</a>
                            <span className='me-2'>|</span>
                            <a href="#" className='me-4' onClick={e => {e.preventDefault(); setUploadedFiles([]); setSelectKwList([]); setfilteredUploaded([])}}>Remove all</a>
                        </div>
                        <button type="button" disabled={selectKwList.length === 0} class="btn btn-primary" onClick={startParsingHandler}>Start parsing</button>
                    </div>
                </div>
            </div>
            <div className='d-flex my-4 flex-column'>
                <div className='d-flex'>
                    <button class="btn btn-primary" type="button" aria-expanded="false" onClick={downloadReportHandler}>
                        <FontAwesomeIcon icon={faDownload} className="pe-2"/>
                        Download
                    </button>
                </div>
                <div className='d-flex overflow-auto my-2' style={{maxHeight: '500px'}}>
                    <table class="table" style={{'table-layout':'fixed', 'word-wrap': 'break-word'}}>
                        <thead className='sticky-top table-primary'>
                            <tr>
                                <th scope="col" style={{'width':'5%'}}>#</th>
                                <th scope="col" style={{'width':'10%'}}>File Name</th>
                                <th scope="col" style={{'width':'10%'}}>Provider Name</th>
                                <th scope="col" style={{'width':'5%'}}>Ref Id</th>
                                <th scope="col" style={{'width':'5%'}}>Issued</th>
                                <th scope="col" style={{'width':'5%'}}>Due</th>
                                <th scope="col" style={{'width':'5%'}}>Item Date</th>
                                <th scope="col" style={{'width':'25%'}}>Item Description</th>
                                <th scope="col" style={{'width':'5%'}}>$</th>
                                <th scope="col" style={{'width':'5%'}}>Qty</th>
                                <th scope="col" style={{'width':'5%'}}>Unit</th>
                                <th scope="col" style={{'width':'5%'}}>VAT</th>
                                <th scope="col" style={{'width':'5%'}}>Amount</th>
                            </tr>
                        </thead>
                            <tbody>
                                {
                                    billingItems.length > 0 && billingItems.map(item => (
                                        item.items.map((d, idx) => (
                                            <tr>
                                                <th scope="row">{idx+1}</th>
                                                <td>{item.invoice_file_name}</td>
                                                <td>{item.provider_name}</td>
                                                <td>{item.reference_id}</td>
                                                <td>{item.invoice_date}</td>
                                                <td>{item.due_date}</td>
                                                <td>{d.date}</td>
                                                <td>{d.item_description}</td>
                                                <td>{d.currency_code}</td>
                                                <td>{d.quantity}</td>
                                                <td>{d.unit_price}</td>
                                                <td>{d.vat}</td>
                                                <td>{d.amount}</td>
                                            </tr>
                                        ))
                                    ))           
                                }
                            </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
};

export default PDFParser;
