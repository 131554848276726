import { useState, useRef, Fragment, useEffect, useMemo } from 'react';
import Menu from "../components/menu";

import "bootstrap/dist/css/bootstrap.min.css";
// import "bootstrap/dist/js/bootstrap.bundle.min.js";
import "bootstrap/dist/js/bootstrap.min.js";
import * as bootstrap from 'bootstrap/dist/js/bootstrap';
import VennChart from '../components/venn_chart';
import { OverlayTrigger, Popover } from 'react-bootstrap';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTrash, faDownload, faRefresh, faFilter, faMinus, faCalendar, faAngleUp, faAngleDown, faTimesCircle, faLineChart, faEye, faCheck, faArrowUp, faArrowDown, faCircle, faGlobe, faSquareCheck, faLanguage, faCheckCircle, faCirclePlus
} from "@fortawesome/free-solid-svg-icons";

import {showLoading, hideLoading } from 'react-redux-loading-bar';
import Papa from 'papaparse';
import { v4 as uuid4 } from 'uuid';
import { useDispatch } from "react-redux";

import { getAuthToken } from '../util/token';
import { lang } from 'moment';
import apiClient from '../api/client';

  
function ContentGap() {
    
    // get auth token
    const token = getAuthToken();
    const userName = localStorage.getItem('username');

    const dispatch = useDispatch();

    const colors = ["#1f77b4", "#ff7f0e", "#2ca02c", "#d62728", "#9467bd", "#8c564b", "#e377c2", "#7f7f7f", "#bcbd22", "#17becf"]
    const [location, setLocation] = useState('');
    const [locationName, setLocationName] = useState('Global');
    const [language, setLanguage] = useState('');
    const [filteredList, setFilteredList] = useState([]);
    const [countryList, setCountryList] = useState([]);
    const [sort,setSort] = useState('');
    const [data, setData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [filterArr, setFilterArr] = useState([{
        id: uuid4(),heading: 'keyword', operation: 'contains', value: ''
      }]);
    const [vennChart, setVennChart] = useState([]);
    const [clustered, setClustered] = useState(false);
    const [clusteredData, setClusteredData] = useState([]);
    const [filteredClustered, setFilteredClustered] = useState([]);
    const [displayClustered, setDisplayClustered] = useState(false);

    const searchStr = useRef('');
    const [domain1, setDomain1] = useState('');
    const [domain2, setDomain2] = useState('');
    const [domain3, setDomain3] = useState('');
    const [screenMode, setScreenMode] = useState(false);

    const domain_1 = useRef('');
    const domain_2 = useRef('');
    const domain_3 = useRef('');

    // save outline
    const [importStat, setImportStat] = useState('');
    const topic = useRef('');
    const domain = useRef('');
    const category = useRef('');
    const description = useState('');
    const [activeCluster, setActiveCluster] = useState([]);



    useEffect(() => {
        apiClient.post('/api/country-geo-target', {headers: {'X-User': userName}})
        .then(
            ({data}) => {
                setCountryList(data);
                setFilteredList(data);
            }
        )
    }, []);

    const locationFilterHandler = (val) => {

        const search = countryList.filter(item => {
            return (
                (val !== '' ? item.Name.search(new RegExp(`^${val}`, 'i')) !== -1 : true)
            )
        });
        setFilteredList(search);
    };

    const sortHandler = (mode) => {
        if (mode === 'kw'){
            if (clustered){
                let d = {}
                Object.entries(clusteredData).map(([key, value]) => {
                    if (sort !== 'keyword_inc' && sort !== 'keyword_dec'){
                        setSort('keyword_inc');
                        value.sort((a,b) => a.keyword.localeCompare(b.keyword));
                        d[key] = value;
                    } else if(sort === 'keyword_dec'){
                        setSort('keyword_inc');
                        value.sort((a,b) => a.keyword.localeCompare(b.keyword));
                        d[key] = value;
                    } else if(sort === 'keyword_inc'){
                        setSort('keyword_dec');
                        value.sort((a,b) => b.keyword.localeCompare(a.keyword));
                        d[key] = value;
                    };
                });
                console.log(d);
                setFilteredClustered(d);
            } else {
                let d = {}
                if (sort !== 'keyword_inc' && sort !== 'keyword_dec'){
                    setSort('keyword_inc');
                    const sortedKeys = Object.keys(filteredData).sort();
                    sortedKeys.forEach(key => {
                        d[key] = filteredData[key]
                    });
                } else if(sort === 'keyword_dec'){
                    setSort('keyword_inc');
                    const sortedKeys = Object.keys(filteredData).sort();
                    sortedKeys.forEach(key => {
                        d[key] = filteredData[key]
                        });
                } else if(sort === 'keyword_inc'){
                    setSort('keyword_dec');
                    const sortedKeys = Object.keys(filteredData).sort().reverse();
                    sortedKeys.forEach(key => {
                        d[key] = filteredData[key]
                        });
                };
                setFilteredData(d);
            }
        } else if (!['Volume', 'avg_cpc', 'kd'].includes(mode)) {
            if (clustered){
                let m = {};
                let pattern = new RegExp(mode, 'i');
                Object.entries(clusteredData).map(([mKey, mValue]) => {
                    let d = [];
                    const sortedKeys = mValue.flatMap(item => { 
                        let result = [];
                        for (let i = 0; i < Object.entries(item).length; i++) {
                            const [k, v] = Object.entries(item)[i];
                            if (pattern.test(k)) {
                                result.push({ [item['keyword']]: v });
                                break; // Exit the loop early if the condition is met
                            } else if (!pattern.test(k) && !['Volume', 'avg_cpc', 'kd', 'keyword'].includes(k) && i === Object.entries(item).length - 2) {
                                result.push({ [[item['keyword']]]: 0 });
                            }
                        }
                        return result;
                    });
                    if (sort !== `${mode}_inc` && sort !== `${mode}_dec`){
                        setSort(`${mode}_inc`);
                        sortedKeys.sort((a, b) => Object.values(a)[0] - Object.values(b)[0]);
                        let s = sortedKeys.map(item => Object.keys(item)[0]);
                        s.forEach(key => {
                            d.push(...mValue.filter(item => item.keyword === key));
                        });
                    } else if(sort === `${mode}_dec`){
                        setSort(`${mode}_inc`);
                        sortedKeys.sort((a, b) => Object.values(a)[0] - Object.values(b)[0])
                        let s = sortedKeys.map(item => Object.keys(item)[0])
                        s.forEach(key => {
                            d.push(...mValue.filter(item => item.keyword === key));
                        });
                    } else if(sort === `${mode}_inc`){
                        setSort(`${mode}_dec`);
                        sortedKeys.sort((a, b) => Object.values(b)[0] - Object.values(a)[0])
                        let s = sortedKeys.map(item => Object.keys(item)[0])
                        s.forEach(key => {
                            d.push(...mValue.filter(item => item.keyword === key));
                        });
                    };
                    m[mKey] = d;
                });
                setFilteredClustered(m);
            } else {
                let d = {};
                let pattern = new RegExp(mode, 'i');
                const sortedKeys = Object.entries(filteredData).flatMap(([key, value]) => { 
                    const result = [];
                    for (let i = 0; i < Object.entries(value).length; i++) {
                        const [k, v] = Object.entries(value)[i];
                        if (pattern.test(k)) {
                            result.push({ [key]: v });
                            break; // Exit the loop early if the condition is met
                        } else if (!pattern.test(k) && !['Volume', 'avg_cpc', 'kd'].includes(k) && i === Object.entries(value).length - 1) {
                            result.push({ [key]: 0 });
                        }
                    }
                    return result;
                });
                if (sort !== `${mode}_inc` && sort !== `${mode}_dec`){
                    setSort(`${mode}_inc`);
                    sortedKeys.sort((a, b) => Object.values(a)[0] - Object.values(b)[0])
                    let s = sortedKeys.map(item => Object.keys(item)[0])
                    s.forEach(key => {
                        d[key] = filteredData[key]
                    });
                } else if(sort === `${mode}_dec`){
                    setSort(`${mode}_inc`);
                    sortedKeys.sort((a, b) => Object.values(a)[0] - Object.values(b)[0])
                    let s = sortedKeys.map(item => Object.keys(item)[0])
                    s.forEach(key => {
                        d[key] = filteredData[key]
                        });
                } else if(sort === `${mode}_inc`){
                    setSort(`${mode}_dec`);
                    sortedKeys.sort((a, b) => Object.values(b)[0] - Object.values(a)[0])
                    let s = sortedKeys.map(item => Object.keys(item)[0])
                    s.forEach(key => {
                        d[key] = filteredData[key]  
                        });
                };
                setFilteredData(d);
            }
        } else {
            if (clustered){
                let d = {}
                Object.entries(clusteredData).map(([key, value]) => {
                    if (sort !== `${mode}_inc` && sort !== `${mode}_dec`){
                        setSort(`${mode}_inc`);
                        value.sort((a,b) => a[mode] - b[mode]);
                        d[key] = value;
                    } else if(sort === `${mode}_dec`){
                        setSort(`${mode}_inc`);
                        value.sort((a,b) => a[mode] - b[mode]);
                        d[key] = value;
                    } else if(sort === `${mode}_inc`){
                        setSort(`${mode}_dec`);
                        value.sort((a,b) => b[mode] - a[mode]);
                        d[key] = value;
                    };
                });
                console.log(d);
                setFilteredClustered(d);
            } else {
                let d = {}
                const sortedKeys = Object.entries(filteredData).map(([key, value]) => { return {[key]:value[mode]}});
                if (sort !== `${mode}_inc` && sort !== `${mode}_dec`){
                    setSort(`${mode}_inc`);
                    sortedKeys.sort((a, b) => Object.values(a)[0] - Object.values(b)[0])
                    let s = sortedKeys.map(item => Object.keys(item)[0])
                    s.forEach(key => {
                        d[key] = filteredData[key]
                    });
                } else if(sort === `${mode}_dec`){
                    setSort(`${mode}_inc`);
                    sortedKeys.sort((a, b) => Object.values(a)[0] - Object.values(b)[0])
                    let s = sortedKeys.map(item => Object.keys(item)[0])
                    s.forEach(key => {
                        d[key] = filteredData[key]
                        });
                } else if(sort === `${mode}_inc`){
                    setSort(`${mode}_dec`);
                    sortedKeys.sort((a, b) => Object.values(b)[0] - Object.values(a)[0])
                    let s = sortedKeys.map(item => Object.keys(item)[0])
                    s.forEach(key => {
                        d[key] = filteredData[key]  
                        });
                };
                setFilteredData(d);
            }
        }
    };
    const updateFilterHandler = (filterID, type, value) => {
        const newArr = filterArr.filter(item => {
            if(item.id === filterID){
                const d = item;
                item[type] = value;
                return d
            }else {
                return item
            }
        });
        setFilterArr(newArr);
        // console.log(filterArr);
      };
      const deleteFilterHandler = (filterID) => {
        let arr = []
        if (filterArr.length === 1){
          arr = [{
            id: uuid4(),heading: 'keyword', operation: 'contains', value: ''
        }]
        } else {
          arr = filterArr.filter(item => item.id !== filterID)
        }
        setFilterArr(arr);
        console.log(filterArr);
      };
      const incFilterHandler = (filterID) => {
        if(filterArr.length === 3 ) return;
        setFilterArr( c => {
            const d = [...c];
            d.push({id: filterID ,heading: 'keyword', operation: 'contains', value: ''});
            return d
        });
        // console.log(filterArr);
      };
      const applyFilterHandler = () => {
        //filterArr[{id, heading, operation, value}]
        console.log('re render');
        let arr = {};
        if(clustered){
            Object.entries(clusteredData).map(([key, value]) => {
                let m = [];
                value.map(item => {
                    let count = 0;
                    filterArr.map(condition => {
                        if (condition.operation === 'contains'){
                            count += eval(`"${item[condition.heading]}".toLowerCase().includes("${condition.value}".toLowerCase())`)
                        }else if (condition.operation === 'does not contain'){
                            count += eval(`!"${item[condition.heading]}".toLowerCase().includes("${condition.value}".toLowerCase())`)
                        }else if (condition.operation === 'is'){
                            // console.log(`"${item[condition.heading]}" === "${condition.value}"`);
                            count += eval(`"${item[condition.heading]}" === "${condition.value}"`)
                        }else if (condition.operation === 'is not'){
                            count += eval(`"${item[condition.heading]}" !== "${condition.value}"`)
                        }else if(condition.operation === 'greater than') {
                            count += eval(`${item[condition.heading]} > ${condition.value}`)
                        }else if (condition.operation === 'greater than or equal'){
                            count += eval(`${item[condition.heading]} >= ${condition.value}`)
                        }else if (condition.operation === 'less than'){
                            count += eval(`${item[condition.heading]} < ${condition.value}`)
                        }else if (condition.operation === 'less than or equal'){
                            count += eval(`${item[condition.heading]} <= ${condition.value}`)
                        }
                    })
                    if (count === filterArr.length){
                        m.push(item)
                    }
                })
                if (m.length > 0){
                    arr[key] = m
                }
            });
            setFilteredClustered(arr);
        } else {
            Object.entries(data).map(([key, value]) => {
                let count = 0;
                filterArr.map(condition => {
                    if (condition.heading === 'keyword'){
                        if (condition.operation === 'contains'){
                            count += eval(`"${key}".toLowerCase().includes("${condition.value}".toLowerCase())`)
                        }else if (condition.operation === 'does not contain'){
                            count += eval(`!"${key}".toLowerCase().includes("${condition.value}".toLowerCase())`)
                        }else if (condition.operation === 'is'){
                            // console.log(`"${item[condition.heading]}" === "${condition.value}"`);
                            count += eval(`"${key}" === "${condition.value}"`)
                        }else if (condition.operation === 'is not'){
                            count += eval(`"${key}" !== "${condition.value}"`)
                        }else if(condition.operation === 'greater than') {
                            count += eval(`"${key}" > "${condition.value}"`)
                        }else if (condition.operation === 'greater than or equal'){
                            count += eval(`"${key}" >= "${condition.value}"`)
                        }else if (condition.operation === 'less than'){
                            count += eval(`"${key}" < "${condition.value}"`)
                        }else if (condition.operation === 'less than or equal'){
                            count += eval(`"${key}" <= "${condition.value}"`)
                        } 
                    } else {
                        if (condition.operation === 'contains'){
                            count += eval(`"${value[condition.heading]}".toLowerCase().includes("${condition.value}".toLowerCase())`)
                        }else if (condition.operation === 'does not contain'){
                            count += eval(`!"${value[condition.heading]}".toLowerCase().includes("${condition.value}".toLowerCase())`)
                        }else if (condition.operation === 'is'){
                            // console.log(`"${item[condition.heading]}" === "${condition.value}"`);
                            count += eval(`"${value[condition.heading]}" === "${condition.value}"`)
                        }else if (condition.operation === 'is not'){
                            count += eval(`"${value[condition.heading]}" !== "${condition.value}"`)
                        }else if(condition.operation === 'greater than') {
                            console.log(eval(`"${value[condition.heading]}" !== "${condition.value}"`))
                            console.log(`"${value[condition.heading]}" > "${condition.value}"`)
                            count += eval(`${value[condition.heading]} > ${condition.value}`)
                        }else if (condition.operation === 'greater than or equal'){
                            count += eval(`${value[condition.heading]} >= ${condition.value}`)
                        }else if (condition.operation === 'less than'){
                            count += eval(`${value[condition.heading]} < ${condition.value}`)
                        }else if (condition.operation === 'less than or equal'){
                            count += eval(`${value[condition.heading]} <= ${condition.value}`)
                        } 
                    };  
                });
                if (count === filterArr.length){
                    arr[key] = value
                }
            });
            setFilteredData(arr);
        }
        
        console.log(arr)
      };
    
    const urlFilter = (
        <Popover id="popover-positioned-top" title="Popover bottom" style={{"max-width":"500px", 'border': 'none'}}>
            <div className='d-flex flex-column p-4 bg-white shadow'>
                <p>Filter URLs</p>
                {
                    filterArr.map(item => {
                        // console.log(item);
                        return (
                            <div className='d-flex align-items-center' key={item}>
                                where
                                <select class="form-select form-select-sm" aria-label="Default select example" className='ms-3 me-2' style={{maxWidth: '100px'}}
                                    onChange={e => updateFilterHandler(item.id, 'heading', e.target.value)}
                                >
                                  {/* <Form.Select aria-label="Default select example"> */}
                                    <option value='keyword' selected={item['heading'] === 'keyword'}>keyword</option>
                                    <option value='Volume' selected={item['heading'] === 'Volume'}>Volume</option>
                                    <option value='kd' selected={item['heading'] === 'kd'}>kd</option>
                                    <option value='avg_cpc' selected={item['heading'] === 'avg_cpc'}>avg_cpc</option>
                                  {/* </Form.Select> */}
                                </select>
                                <select class="form-select form-select-sm" aria-label="Default select example" className='me-2'
                                    onChange={e => updateFilterHandler(item.id, 'operation', e.target.value)}
                                >
                                    <option value='contains' selected={item.operation === '' ? true : item.operation === 'contains' ? true : false}>contains</option>
                                    <option value="does not contain" selected={item.operation === 'does not contain'}>does not contain</option>
                                    <option value="is" selected={item.operation === 'is'}>is</option>
                                    <option value="is not" selected={item.operation === 'is not'}>is not</option>
                                    <option value="greater than" selected={item.operation === 'greater than'}>greater than</option>
                                    <option value="greater than or equal" selected={item.operation === 'greater than or equal'}>greater than or equal</option>
                                    <option value="less than" selected={item.operation === 'less than'}>less than</option>
                                    <option value="less than or equal" selected={item.operation === 'less than or equal'}>less than or equal</option>
                                </select>
    
                                <input type="text" className="form-control me-2" style={{width: '120px'}} placeholder="Enter a value"
                                    defaultValue={item.value}
                                    onChange={e => updateFilterHandler(item.id, 'value', e.target.value)}
                                />
                                <button className='btn'
                                    onClick={e => deleteFilterHandler(item.id)}
                                ><FontAwesomeIcon icon={faTrash} /></button>
                            </div>
                        )
                    })
                }
                <div className='d-flex mt-4'>
                    <button type="button" class={`btn btn-light me-auto ${filterArr.length === 3 && 'disabled'}`} onClick={e => incFilterHandler(uuid4())}>+ Add condition</button>
                    <button type="button" class="btn btn-primary" onClick={applyFilterHandler}>Apply</button>
                </div>
                
            </div>
        </Popover>
      );
    
    const compareHandler = () => {
        if (domain1 === '' || (domain2 === '' && domain3 === '') || language === '') return;
        if (Object.keys(data).length !== 0){
            setDomain1(domain_1.current.value);
            setDomain2(domain_2.current.value);
            setDomain3(domain_3.current.value);
        };
        let k = [];
        let arr = [domain_1.current.value, domain_2.current.value, domain_3.current.value].filter(item => item !== '')
        const input = {
            target: arr,
            location_code: location,
            // location_code: 2704,
            language_code: language,
            refresh: '',
        };
        console.log(input);
        dispatch(showLoading());
        apiClient.post('/api/content-gap', input, {headers: {'X-User': userName}})
        .then(
                ({data}) => {
                    dispatch(hideLoading());
                    setScreenMode(true);
                    console.log(data);
                    if (typeof data === 'string'){
                        const m = new bootstrap.Modal(document.getElementById('ModalAlert'));
                        m.toggle();
                        return;
                    };
                    setData(data);
                    setFilteredData(data);
                    for(let i =0 ; i < arr.length; i++){
                        let pattern = new RegExp(arr[i], 'i');
                        let count = 0
                        let kws = []
                        Object.entries(data).map(([key, value]) => {
                            Object.keys(value).every(k => {
                                if(pattern.test(k)){
                                    count += 1;
                                    kws.push(key)
                                    return false
                                }
                                return true
                            });
                        });
                        k.push({
                            sets: [i],
                            label: arr[i],
                            size: count,
                            keywords: kws
                        })
                    };
                    console.log(k);
                    let result = k.map(item => {return {sets: item.sets, label: item.label, size: item.size}});
                    for(let i = 0; i < k.length; i++){
                        let nextIndex = i + 1;
                        if (i === k.length - 1) nextIndex = 0;
                        let set1 = new Set(k[i]['keywords']);
                        let set2 = new Set(k[nextIndex]['keywords'])
            
                        let differenceSet = new Set([...set1].filter(item => set2.has(item)));
                        let difference = Array.from(differenceSet);
                        if(difference.length > 0){
                            result.push({
                                sets:[i, nextIndex],
                                size: difference.length
                            })
                        }
                    };
                    if (arr.length === 3){
                        let set1 = new Set(k[0]['keywords']);
                        let set2 = new Set(k[1]['keywords']);
                        let set3 = new Set(k[2]['keywords']);
                        let differenceSet = new Set([...set1].filter(item => set2.has(item) && set3.has(item)));
                        let difference = Array.from(differenceSet);
                        if(difference.length > 0){
                            result.push({
                                sets:[0,1,2],
                                size: difference.length
                            })
                        }
                    }
                    
                    console.log(result);
                    setVennChart(result)
            })
    };

    const changeModeHandler = (mode) => {
        setClustered(false);
        if(mode==='Untapped') {
            setDisplayClustered(true)
        } else {setDisplayClustered(false)};
        let d = {};
        let domain = [domain1, domain2, domain3].filter(item => item !== '');
        if (mode === 'Shared'){
            Object.entries(data).map(([key, value]) => {
                let count = 0;
                domain.map(dom => {
                    let pattern = new RegExp(dom, 'i');
                    Object.entries(value).every(([k, v]) => {   
                        if (pattern.test(k)) {
                            count += 1;
                            return false;
                        };
                        return true
                    });
                })
                if (count === 3) d[key] = value;
            });
        } else if (mode === 'Missing'){
            let pattern = new RegExp(domain1, 'i');
            Object.entries(data).map(([key, value]) => {
                let count = 0;
                let youCount = 0;
                Object.keys(value).map(item => {
                    if(!pattern.test(item) && !['Volume', 'kd', 'avg_cpc'].includes(item)) count += 1
                    if(pattern.test(item)) youCount += 1
                });
                if (youCount === 0 && count === [domain1, domain2, domain3].filter(item => item !== '').length - 1) d[key] = value;
            })
        } else if (mode === 'Weak'){
            let pattern = new RegExp(domain1, 'i');
            Object.entries(data).filter(([key, value]) => {
                let max = 0;
                let count = 0;
                Object.keys(value).map(item => {
                    if(pattern.test(item)) max = value[item];
                });
                if (max > 0){
                    Object.keys(value).map(item => {
                        if(!pattern.test(item) && !['Volume', 'avg_cpc', 'kd'].includes(item) && value[item] < max) count += 1;
                    });
                    if(count > 0 && count === (Object.keys(value).length - 4)) d[key] = value;
                }
            });
        }else if (mode === 'Strong'){
            let pattern = new RegExp(domain1, 'i');
            Object.entries(data).filter(([key, value]) => {
                let max = 0;
                let count = 0;
                Object.keys(value).map(item => {
                    if(pattern.test(item)) max = value[item];
                });
                if(max > 0){
                    Object.keys(value).map(item => {
                        if(!pattern.test(item) && !['Volume', 'avg_cpc', 'kd'].includes(item) && value[item] > max) count += 1;
                    });
                    if(count > 0 && count === (Object.keys(value).length - 4)) d[key] = value;
                }
            });
        } else if (mode === 'Untapped'){
            let pattern = new RegExp(domain1, 'i');
            Object.entries(data).map(([key, value]) => {
                let count = 0;
                Object.keys(value).map(item => {
                    if(pattern.test(item)) count += 1
                });
                if (count === 0) d[key] = value;
            })
        } else if (mode === 'Unique'){
            let pattern = new RegExp(domain1, 'i');
            Object.entries(data).map(([key, value]) => {
                let count = 0;
                Object.keys(value).map(item => {
                    if(!pattern.test(item) && !['Volume', 'avg_cpc', 'kd'].includes(item)) count += 1
                });
                if (count === 0) d[key] = value;
            })
        } else if (mode === 'All') {
            d = data;
        };
        // console.log(d);
        setFilteredData(d);
    };
    const clusterHandler = (checked) => {
        if (checked){
            setClustered(checked);
            let d = {};
            Object.entries(filteredData).map(([key, value]) => {
                let keys = Object.keys(value);
                let min = 101;
                let idx;
                for (let i = 0; i < keys.length; i++){
                    if(!['Volume', 'kd', 'avg_cpc'].includes(keys[i])){
                        if (value[keys[i]] < min){
                            idx = i;
                            min = value[keys[i]];
                        }
                    }
                };
                let m = {
                    ...value,
                    keyword: key,
                };
                if(!Object.keys(d).includes(keys[idx])){
                    d[keys[idx]] = [m]
                }else{
                    d[keys[idx]].push(m)
                }
            });
            // console.log(d);
            setClusteredData(d);
            setFilteredClustered(d);
        } else {
            setClustered(checked);
        }
    };

    const downloadReportHandler = () => {
        if((clustered && Object.keys(clusteredData).length === 0) || (!clustered && Object.keys(filteredData).length === 0)) return;
        let data = []
        if (clustered){
            Object.entries(clusteredData).map(([url, value]) => {
                value.map(item => {
                    data.push({
                        URL: url,
                        Keyword: item.keyword,
                        Volume: item.Volume,
                        KD: item.kd,
                        CPC: item.avg_cpc
                    })
                })  
            })
        } else {
            Object.entries(clusteredData).map(([key, value]) => {
                value.map(item => {
                    data.push({
                        Keyword: key,
                        Volume: item.Volume,
                        KD: item.kd,
                        CPC: item.avg_cpc
                    })
                })  
            })
        };
        console.log(data);
        const csv = Papa.unparse({
            // "fields": ['URL', "Keyword", 'Volume', 'KD', 'CPC'],
            "data": data
        });
        // console.log(csv);
        const blob = new Blob([csv]);

        const url = window.URL.createObjectURL(blob, {type: 'text/plain' });
        // console.log(blob);
        const link = document.createElement("a");
        link.download = 'keyword_gap.csv';
        link.href = url;
        link.click();
    };

    const openOutlineModelHandler = () => {
        setImportStat('');
        topic.current.value = domain.current.value = description.current.value = category.current.value = '';
        const m = new bootstrap.Modal(document.getElementById('ModalOutline'));
        m.toggle();
    };

    const createOutlineHandler = () => {
        const kws = activeCluster.map(item => {
            return `${item.keyword}<<>>${item.Volume}<<>>${item.kd}`
        });
        const input = {
            topic: topic.current.value,
            category: category.current.value,
            domain: domain.current.value,
            description: description.current.value,
            outline: '',
            keywords: kws,
            date: new Date().getTime(),
            task: 'outline',
            username: localStorage.getItem('username')
          };
        console.log(input);
        apiClient.post('/api/import-outline', input, {headers: {'X-User': userName}})
        .then (
            ({data}) =>{
            if(data.acknowledged === true){
                setImportStat(true);
            }else{
                setImportStat(false);
            }
            console.log(data);
            }
        );
    }

    return (
        <div className="d-flex flex-column">
            <h1 className="text-center mt-4 mb-5">Keyword Gap</h1>
            <div className={`d-flex ${screenMode ? 'flex-row' : 'flex-column'} mb-4 align-items-center`}>
                <div class={`input-group flex-nowrap w-50 ${screenMode ? 'me-2' : 'mb-3'}`}>
                    <div class="input-group-text" style={{backgroundColor: 'white'}}>
                        <span className='badge bg-primary rounded-pill' >You</span>
                    </div>
                    <input type="text" class="form-control" placeholder="Add domain" ref={domain_1} onChange={e => Object.keys(data).length === 0 && setDomain1(e.target.value)} />
                </div>
                <div class={`input-group flex-nowrap w-50 ${screenMode ? 'me-2' : 'mb-3'}`}>
                    <div class="input-group-text" style={{backgroundColor: 'white'}}>
                        <FontAwesomeIcon icon={faCircle} style={{color: domain2 !== '' ? colors[1] : 'grey'}}/>
                    </div>
                    <input type="text" class="form-control" placeholder="Add domain" ref={domain_2} onChange={e => Object.keys(data).length === 0 && setDomain2(e.target.value)}/>
                </div>
                <div class={`input-group flex-nowrap w-50 ${screenMode ? 'me-2' : 'mb-3'}`}>
                    <div class="input-group-text" style={{backgroundColor: 'white'}}>
                        <FontAwesomeIcon icon={faCircle} style={{color: domain3 ? colors[2] : 'grey'}}/>
                    </div>
                    <input type="text" class="form-control" placeholder="Add domain" ref={domain_3} onChange={e => Object.keys(data).length === 0 && setDomain3(e.target.value)}/>
                </div>
                <div className='d-flex justify-content-start w-50'>
                    <div class="dropdown me-3">
                        <button class="btn btn-secondary dropdown-toggle" type="button" id="location" data-bs-toggle="dropdown" aria-expanded="false">
                            <FontAwesomeIcon icon={faGlobe} className='me-2' />
                            {locationName}
                        </button>
                        <ul class="dropdown-menu p-2" aria-labelledby="dropdownMenuButton1" style={{maxHeight:'300px', 'overflow': 'auto', width: '250px'}}>
                            <input type="text" class="form-control" placeholder="Search country..." aria-label="Search country..." aria-describedby="basic-addon1"
                            onChange = {e => locationFilterHandler(e.target.value)}
                            ref={searchStr}
                            />
                            {filteredList.map((item, index) => {
                                return (
                                    <li key={index} onClick={e => {setLocationName(item.Name); setLocation(item['Criteria ID'])}}><a class="dropdown-item" href="#">{item.Name}</a></li>
                                )
                            })}
                        </ul>
                    </div>
                    <div class="dropdown align-self-center me-auto">
                        <button class="btn btn-secondary dropdown-toggle" type="button" id="language" data-bs-toggle="dropdown" aria-expanded="false">
                            <FontAwesomeIcon icon={faLanguage} className='me-2' />
                            {language === '' ? 'Language' : language === 'vi' ? 'VI' : language === 'en' ? 'EN' : language}
                        </button>
                        <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                            <li><a class="dropdown-item" href="#"
                            onClick={e => {e.preventDefault(); setLanguage('vi')}}
                            >VI</a></li>
                            <li><a class="dropdown-item" href="#"
                            onClick={e => {e.preventDefault(); setLanguage('en')}}
                            >EN</a></li>
                        </ul>
                    </div>
                    <button class="btn btn-primary" type="button" onClick={compareHandler}>Compare</button>
                </div>
            </div>
            {/* Modal Alert*/}
            {/* Modal Save Outline*/}
            <div class="modal fade" id="ModalOutline" tabindex="-1" role="dialog" aria-labelledby="ModalCenterTitle" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered" role="document">
                    <div class="modal-content">
                    <div className="modal-header">
                        <h5 class="modal-title me-auto" id="ModalLongTitle">Save Outline</h5>
                        <div className={`${[false,'',null].includes(importStat) ? 'd-none' : ''}`} id="success-noti">
                            <FontAwesomeIcon icon={faCheckCircle} style={{'color':'green'}}/>
                            <span className='ms-1'>Success</span>
                        </div>
                        <div className={`${[true,'',null].includes(importStat) ? 'd-none' : ''}`} id="failed-noti">
                            <FontAwesomeIcon icon={faTimesCircle} style={{'color':'red'}}/>
                            <span className='ms-1'>Failed. Pls Retry!</span>
                        </div>
                    </div>
                    <div class="modal-body" style={{'max-height':'500px', 'overflow':'auto'}}>
                        <div class="d-flex flex-column mb-4">
                            <div class="input-group mb-3">
                                <span class="input-group-text">Topic</span>
                                <input type="text" class="form-control" placeholder="Topic" aria-label="Topic" aria-describedby="basic-addon1" 
                                    ref={topic}
                                />
                            </div>
                            <div class="input-group mb-3">
                                <span class="input-group-text">Domain</span>
                                <input type="text" class="form-control" placeholder="Domain" aria-label="Domain" aria-describedby="basic-addon1"
                                ref={domain}/>
                            </div>
                            <div class="input-group mb-3">
                                <span class="input-group-text">Category</span>
                                <input type="text" class="form-control" placeholder="Domain" aria-label="Domain" aria-describedby="basic-addon1"
                                ref={category}/>
                            </div>
                            <div class="form-floating">
                                <textarea class="form-control" rows="2" placeholder="Leave a description" id="floatingTextarea2" style={{height: '100px'}} 
                                    ref={description}
                                />
                                <label for="floatingTextarea2">Description</label>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" onClick={e => setImportStat('')}>Close</button>
                        <button type="button" className={`btn btn-primary`} id='import-button' onClick={createOutlineHandler}>Save</button>
                    </div>
                    </div>
                </div>
            </div>
            {/* Modal Save Outline*/}
            <div class="modal fade" id="ModalAlert" tabindex="-1" role="dialog" aria-labelledby="ModalCenterTitle" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered" role="document">
                    <div class="modal-content">
                    <div className="modal-header">
                        <h5 class="modal-title me-auto" id="ModalLongTitle">Resources Exhausted</h5>
                    </div>
                    <div class="modal-body" style={{'max-height':'500px', 'overflow':'auto'}}>
                        <p>URL audit exceeded 30 reports/day</p>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" >Close</button>
                    </div>
                    </div>
                </div>
            </div>
            {/* Modal Alert*/}
            {/* Result Screen */}
            <div className={`${!screenMode && 'd-none'}`}>
                <div className='d-flex flex-column my-4'>
                    <h5>Keyword overlap</h5>
                    <hr />
                    <div className='d-flex'>
                        <VennChart 
                            data={vennChart}
                        />
                        <div className='d-flex'>
                            <ul class="list-group list-group-flush">
                            {
                                vennChart.map((item, idx) => {
                                    if (item.label){
                                        return (
                                            <li class="list-group-item border-0">
                                                <FontAwesomeIcon icon={faSquareCheck} className='me-2' style={{color: colors[idx]}}/>
                                                {`${item.label}: ${item.size.toLocaleString('en-US')}`}
                                            </li>
                                        )
                                    }
                                })
                            }
                            </ul>
                        </div>
                    </div>
                    
                </div>
                <div className='d-flex flex-column mb-4'>
                    <div className='d-flex align-items-center'>
                        <h5>All keyword detail</h5>
                        <div className='d-flex me-2 ms-4'>
                            <OverlayTrigger trigger="click" placement="bottom" rootClose overlay={urlFilter} container={this} containerPadding={20}>
                                <button type="button" class="btn btn-light">
                                    <FontAwesomeIcon className='me-2' icon={faFilter} />
                                    Filter
                                </button>
                            </OverlayTrigger>
                        </div>
                        <div className={`form-check form-switch ${!displayClustered && 'd-none'}`}>
                            <input class="form-check-input" type="checkbox" id="flexSwitchCheckChecked" checked={clustered} onChange={e => clusterHandler(e.target.checked)}/>
                            <label class="form-check-label" for="flexSwitchCheckChecked">Cluster</label>
                        </div>
                        <div class="btn-group ms-auto" role="group" aria-label="Basic radio toggle button group">
                            <input type="radio" class="btn-check" name="btnradio" id="Shared" autocomplete="off" value="Shared" onChange={e => changeModeHandler(e.target.value)}/>
                            <label class="btn btn-outline-primary" for="Shared">Shared</label>
                            <input type="radio" class="btn-check" name="btnradio" id="Missing" autocomplete="off" value="Missing" onChange={e => changeModeHandler(e.target.value)}/>
                            <label class="btn btn-outline-primary" for="Missing">Missing</label>
                            <input type="radio" class="btn-check" name="btnradio" id="Weak" autocomplete="off" value="Weak" onChange={e => changeModeHandler(e.target.value)}/>
                            <label class="btn btn-outline-primary" for="Weak">Weak</label>
                            <input type="radio" class="btn-check" name="btnradio" id="Strong" autocomplete="off" value="Strong" onChange={e => changeModeHandler(e.target.value)}/>
                            <label class="btn btn-outline-primary" for="Strong">Strong</label>
                            <input type="radio" class="btn-check" name="btnradio" id="Untapped" autocomplete="off" value="Untapped" onChange={e => changeModeHandler(e.target.value)}/>
                            <label class="btn btn-outline-primary" for="Untapped">Untapped</label>
                            <input type="radio" class="btn-check" name="btnradio" id="Unique" autocomplete="off" value="Unique" onChange={e => changeModeHandler(e.target.value)}/>
                            <label class="btn btn-outline-primary" for="Unique">Unique</label>
                            <input type="radio" class="btn-check" name="btnradio" id="All" autocomplete="off" value="All" onChange={e => changeModeHandler(e.target.value)}/>
                            <label class="btn btn-outline-primary" for="All">All</label>
                        </div>
                        <button class="btn btn-primary ms-4" type="button" aria-expanded="false" onClick={downloadReportHandler}>
                            <FontAwesomeIcon icon={faDownload} className="pe-2"/>
                            Download
                        </button>
                    </div>
                    <hr />
                    <div className="overflow-auto mb-3" style={{"height":"500px"}}>
                        <table class="table " style={{'table-layout':'fixed', 'word-wrap': 'break-word'}}>
                            <thead className="table-primary sticky-top" style={{'z-index':'9'}}>
                                <tr>
                                    <th scope="col" style={{'width':'5%'}}>#</th>
                                    <th scope="col" style={{'width':'25%'}}>
                                        <button class="btn" type="button" aria-expanded="false" onClick={event => sortHandler('kw')}>
                                            <span className='me-2 fw-bold'>Keyword </span>
                                            <FontAwesomeIcon icon={faAngleUp} style={{'color':`${sort === 'keyword_inc' ? '#000' : '#6c757d'}`}}/>
                                            <FontAwesomeIcon icon={faAngleDown} style={{'color':`${sort === 'keyword_dec' ? '#000' : '#6c757d'}`}}/>
                                        </button>
                                    </th>
                                    {
                                        [domain1, domain2, domain3].filter(item => item !== '').map((item, idx) => {
                                            // console.log(item)
                                            if (item !== ''){
                                                return (
                                                    <th scope="col" style={{'width':'12%'}}>
                                                        <button class="btn px-0" type="button" aria-expanded="false" onClick={event => sortHandler(item)}>
                                                            <FontAwesomeIcon icon={faCircle} className='me-2' style={{color: colors[idx], fontSize: '10px'}} />
                                                            <span className='me-2 fw-bold'>{item}</span>
                                                            <FontAwesomeIcon icon={faAngleUp} style={{'color':`${sort === `${item}_inc` ? '#000' : '#6c757d'}`,'font-size':'0.8rem'}}/>
                                                            <FontAwesomeIcon icon={faAngleDown} style={{'color':`${sort ===  `${item}_dec` ? '#000' : '#6c757d'}`, 'font-size':'0.8rem'}}/>
                                                        </button>
                                                    </th>
                                                )
                                            }
                                        })
                                    }
                                    <th scope="col" style={{'width':'10%'}}>
                                        <button class="btn px-0" type="button" aria-expanded="false" onClick={event => sortHandler('Volume')}>
                                            <span className='me-2 fw-bold'>Volume</span>
                                            <FontAwesomeIcon icon={faAngleUp} style={{'color':`${sort === 'Volume_inc' ? '#000' : '#6c757d'}`,'font-size':'0.8rem'}}/>
                                            <FontAwesomeIcon icon={faAngleDown} style={{'color':`${sort === 'Volume_dec' ? '#000' : '#6c757d'}`, 'font-size':'0.8rem'}}/>
                                        </button>
                                    </th>
                                    <th scope="col" style={{'width':'8%'}}>
                                        <button class="btn px-0" type="button" aria-expanded="false" onClick={event => sortHandler('kd')}>
                                            <span className='me-2 fw-bold'>KD</span>
                                            <FontAwesomeIcon icon={faAngleUp} style={{'color':`${sort === 'kd_inc' ? '#000' : '#6c757d'}`,'font-size':'0.8rem'}}/>
                                            <FontAwesomeIcon icon={faAngleDown} style={{'color':`${sort === 'kd_dec' ? '#000' : '#6c757d'}`, 'font-size':'0.8rem'}}/>
                                        </button>
                                    </th>
                                    <th scope="col" style={{'width':'8%'}}>
                                        <button class="btn px-0" type="button" aria-expanded="false" onClick={event => sortHandler('avg_cpc')}>
                                            <span className='me-2 fw-bold'>CPC</span>
                                            <FontAwesomeIcon icon={faAngleUp} style={{'color':`${sort === 'avg_cpc_inc' ? '#000' : '#6c757d'}`,'font-size':'0.8rem'}}/>
                                            <FontAwesomeIcon icon={faAngleDown} style={{'color':`${sort === 'avg_cpc_dec' ? '#000' : '#6c757d'}`, 'font-size':'0.8rem'}}/>
                                        </button>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {   clustered ?
                                    Object.keys(filteredClustered).map((item, idx) => 
                                    <>
                                        <tr className='table-warning'>
                                            <td>{idx+1}</td>
                                            <td colspan={[domain1, domain2, domain3].filter(item => item !== '').length + 4}>
                                                <button className='btn me-2' onClick={e => {setActiveCluster(filteredClustered[item]); openOutlineModelHandler()}}>
                                                    <FontAwesomeIcon icon={faCirclePlus} />
                                                </button>
                                                <span>{item}</span>
                                            </td>
                                        </tr>
                                        {
                                            filteredClustered[item].map((key, index) => (
                                                <tr >
                                                    <td scope="row">{index+1}</td>
                                                    <td>{key['keyword']}</td>
                                                    {
                                                        [domain1, domain2, domain3].filter(item => item !== '').map((it, j) => {
                                                            let pattern = new RegExp(it, 'i');
                                                            let count = 0;
                                                            let max = Math.min(...Object.entries(key).map(([k,v]) => {
                                                                if (!['Volume', 'kd', 'avg_cpc', 'keyword'].includes(k)) return parseInt(v)
                                                                return 101
                                                            }))
                                                            // console.log(max)
                                                            return Object.entries(key).map(([k, v], i) => {
                                                                // console.log(k, v)
                                                                if(count === 1) return
                                                                if (pattern.test(k)){
                                                                    count += 1;
                                                                    return <td style={{backgroundColor: v === max && '#9ef2c9'}}>{v}</td>
                                                                } else if (!['Volume', 'kd', 'avg_cpc', 'keyword'].includes(k) && i === (Object.keys(key).length - 2)){
                                                                    return <td></td>
                                                                }
                                                            }) 
                                                        })
                                                    }
                                                    <td>{key['Volume'].toLocaleString('en-US')}</td>
                                                    <td>{key['kd']}</td>
                                                    <td>{key['avg_cpc'] ? key['avg_cpc'].toFixed(2) : null}</td>
                                                </tr>
                                            ))
                                        }
                                        
                                    </>
                                    ) :
                                    Object.keys(filteredData).map((item, idx) => 
                                        <>
                                            <tr >
                                                <td scope="row">{idx+1}</td>
                                                <td>{clustered ? filteredData[item]['keyword'] : item}</td>
                                                {
                                                    [domain1, domain2, domain3].filter(item => item !== '').map((it, j) => {
                                                        let pattern = new RegExp(it, 'i');
                                                        let count = 0;
                                                        let max = Math.min(...Object.entries(filteredData[item]).map(([k,v]) => {
                                                            if (!['Volume', 'kd', 'avg_cpc'].includes(k)) return parseInt(v)
                                                            return 101
                                                        }))
                                                        // console.log(max)
                                                        return Object.entries(filteredData[item]).map(([k, v], i) => {
                                                            // console.log(k, v)
                                                            if(count === 1) return
                                                            if (pattern.test(k)){
                                                                count += 1;
                                                                return <td style={{backgroundColor: v === max && '#9ef2c9'}}>{v}</td>
                                                            } else if (!['Volume', 'kd', 'avg_cpc'].includes(k) && i === (Object.keys(filteredData[item]).length - 1)){
                                                                return <td></td>
                                                            }
                                                        }) 
                                                    })
                                                }
                                                <td>{filteredData[item]['Volume'] ? filteredData[item]['Volume'].toLocaleString('en-US') : null}</td>
                                                <td>{filteredData[item]['kd']}</td>
                                                <td>{filteredData[item]['avg_cpc'] ? filteredData[item]['avg_cpc'].toFixed(2) : null}</td>
                                            </tr>
                                        </>
                                        )
                                }
                            </tbody>
                        </table>
                        <h4 className={`text-muted text-center ${Object.keys(filteredData).length > 0 && 'd-none'}`}>No Records founded in selected period!</h4>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default ContentGap;