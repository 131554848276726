import { redirect } from "react-router-dom";


export function getTokenDuration(){
    const storedExpirationDate = localStorage.getItem('expiration');
    const expirationDate = new Date(storedExpirationDate);
    const now = new Date();
    const duration = expirationDate.getTime() - now.getTime();
    return duration;
}

export function getAuthToken() {
    const token = localStorage.getItem('token');

    if(!token){
        return null
    };

    const tokenDuration = getTokenDuration();
    if(tokenDuration < 0){
        return 'EXPIRED';
    };

    return token;
};

export const getUserRole = () => {
    return localStorage.getItem('role');
  };

export function tokenLoader() {
    return getAuthToken();
};

export function checkAuth(allowedRoles = []){
    const token = getAuthToken();
    if (!token || token === 'EXPIRED'){
        localStorage.removeItem('token');
        return redirect('/login');
    };
    if (allowedRoles.length !== 0){
        const role = getUserRole();
        if (!allowedRoles.includes(role)) {
            return redirect('/403-forbidden');
        };
    };
    
    return null
}