import React, { useContext, useState, useEffect } from 'react';
import { Form, Link, NavLink, useRouteLoaderData, useNavigate } from 'react-router-dom';
import { useWebSocket } from './websocket_context';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBell } from '@fortawesome/free-solid-svg-icons';
import { getAuthToken } from '../util/token';


const Menu = () => {
    // const token = useRouteLoaderData('root');
    const token = getAuthToken();
    const username = localStorage.getItem('username');
    const { messages } = useWebSocket();
    const [showModal, setShowModal] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        if (messages.some(msg => msg.includes('session invalidated'))) {
            setShowModal(true);
        }
    }, [messages]);

    const handleClose = () => {
        setShowModal(false);
        window.location.href = '/login';
    };

    return (
        <>
            <header class="navbar navbar-expand-lg navbar-light bg-light shadow-sm" style={{height: '50px'}}>
                <div class="container-fluid">
                    <div class="collapse navbar-collapse justify-content-end" id="navbarNav">
                        <ul class="navbar-nav me-4">
                            {token &&
                                <>
                                    <li className="nav-item dropdown">
                                        <a className="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                            <FontAwesomeIcon icon={faBell} />
                                            {messages.length > 0 && (
                                                <span className="badge bg-danger ms-1">{messages.length}</span>
                                            )}
                                        </a>
                                        <ul className="dropdown-menu dropdown-menu-end">
                                            {messages.length > 0 ? (
                                                messages.map((msg, index) => (
                                                    <li key={index}>
                                                        <span className="dropdown-item">{msg}</span>
                                                    </li>
                                                ))
                                            ) : (
                                                <li>
                                                    <span className="dropdown-item">No new notifications</span>
                                                </li>
                                            )}
                                        </ul>
                                    </li>
                                    <li class="nav-item dropdown">
                                        <a class="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                            <img src="gleads-logo.png" class="rounded-circle img-fluid" width="20" height="20" alt="..." />
                                            <span className='ms-2'>{username}</span>
                                        </a>
                                        <ul className="dropdown-menu" style={{left: '-20px'}}>
                                            <li ><NavLink className="dropdown-item" to="/user-profile">Profile</NavLink></li>
                                            <li ><NavLink className="dropdown-item" to="/manage-tasks">Pending Tasks</NavLink></li>
                                            <li >
                                                <Form action='/logout' method="post">
                                                    <button className="btn dropdown-item"><a>Log out</a></button>
                                                </Form>
                                            </li>
                                        </ul>
                                    </li>
                                </>
                            }
                        </ul>
                    </div>
                </div>
            </header>
            {/* Session Invalidation Modal */}
            <div className={`modal fade ${showModal ? 'show' : ''}`} 
                 id="sessionModal" 
                 tabIndex="-1" 
                 aria-labelledby="sessionModalLabel" 
                 aria-hidden={!showModal}
                 style={{ display: showModal ? 'block' : 'none' }}>
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="sessionModalLabel">Session Alert</h5>
                            <button type="button" 
                                    className="btn-close" 
                                    onClick={handleClose} 
                                    aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            Your session has been invalidated. Please login again.
                        </div>
                        <div className="modal-footer">
                            <button type="button" 
                                    className="btn btn-primary" 
                                    onClick={handleClose}>
                                Understood
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            {showModal && <div className="modal-backdrop fade show"></div>}
        </>
    )
};

export default Menu;