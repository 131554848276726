import { createBrowserRouter, RouterProvider } from "react-router-dom";
import {WebSocketProvider} from './components/websocket_context';

import OutlineEditor from './pages/outline_editor';
import CheckPlagiarism from './pages/plagia';
import Cluster from './pages/kw_cluster';
import RankTracking from "./pages/rank_tracker";
import ManageRankTracking from "./pages/manage_rank_track";
import KPIReport from "./pages/kpi_monthly_report";
import KwResearch from './pages/kw_research';
import OnpageDetail from "./pages/onpage_detail";
import Onpage from './pages/onpage';
import Demo from './pages/demo';
import QABot from "./pages/qa_bot";
import SerpVolatility from "./pages/serp_volatility";
import KWSuggestion from "./pages/kw_suggestion";
import RankedKws from "./pages/ranked_kws";
import Login, {action as authAction} from './pages/login';
import Backlink from "./pages/backlink";
import ContentGap from "./pages/content_gap";
import { tokenLoader, checkAuth } from './util/token';
import {action as logoutAction} from './pages/logout';
import RootPage from "./pages/root_page";
import ErrorPage from "./pages/error_page";
import UserProfile from "./pages/profile";
import ManageTasks from "./pages/task";
import ContentAudit from "./pages/content_audit";
import MailChimp from "./pages/mailchimp";
import Traffic from "./pages/traffic";
import TawkTo from "./pages/tawkto";
import Accounting from "./pages/accounting_report";
import PDFParser from "./pages/pdf_parser";
import DataAnnotate from "./pages/data_annotate";
import ForbiddenPage from "./pages/role_based";
import NotFoundPage from "./pages/404_page";
import OAuthCallback from "./pages/oauth_callback";



const router = createBrowserRouter([
  {path: '/',
  element: <RootPage />,
  errorElement: <ErrorPage />,
  id: 'root',
  loader: () => checkAuth([]),
  children:[
    {path: 'outline', element: <OutlineEditor />, loader: () => checkAuth(['admin', 'content', 'seo'])},
    {path: 'check-duplicate', element: <CheckPlagiarism />, loader: () => checkAuth(['admin', 'content', 'seo'])},
    {path: 'keyword-cluster', element: <Cluster />, loader: () => checkAuth(['admin', 'content', 'seo'])},
    {path: 'rank-tracking', element: <RankTracking />, loader: () => checkAuth(['admin', 'content', 'seo'])},
    {path: 'kpi-report', element: <KPIReport /> , loader: () => checkAuth(['admin', 'content', 'int_accounting'])},
    {path: 'manage-rank-tracking', element: <ManageRankTracking />, loader: () => checkAuth(['admin', 'content', 'seo'])},
    {path: 'qa-bot', element: <QABot />},
    {path: 'serp-volatility', element: <SerpVolatility />, loader: () => checkAuth(['admin', 'content', 'seo'])},
    {path: 'logout', action: logoutAction},
    {path: 'user-profile', element: <UserProfile />, loader: () => checkAuth(['admin', 'content', 'seo'])},
    {path: 'ranked-keywords', element: <RankedKws />, loader: () => checkAuth(['admin', 'content', 'seo'])},
    {path:'manage-tasks', element: <ManageTasks />, loader: () => checkAuth(['admin', 'content', 'seo'])},
    {path:'keyword-research', element: <KwResearch />, loader: () => checkAuth(['admin', 'content', 'seo'])},
    {path:'technical-audit', element: <Onpage />, loader: () => checkAuth(['admin', 'seo'])},
    {path:'technical-audit/detail', element: <OnpageDetail />, loader: () => checkAuth(['admin', 'seo'])},
    {path:'keyword-suggestion', element: <KWSuggestion />, loader: () => checkAuth(['admin', 'content', 'seo'])},
    {path:'backlink-audit', element: <Backlink />, loader: () => checkAuth(['admin', 'seo'])},
    {path:'keyword-gap', element: <ContentGap />, loader: () => checkAuth(['admin', 'content', 'seo'])},
    {path:'content-audit', element: <ContentAudit />, loader: () => checkAuth(['admin', 'content', 'seo'])},
    {path:'mailchimp-report', element: <MailChimp />, loader: () => checkAuth(['admin', 'content'])},
    {path:'traffic-analytic', element: <Traffic />, loader: () => checkAuth(['admin', 'content', 'seo'])},
    {path:'tawkto-message', element: <TawkTo /> , loader: () => checkAuth(['admin', 'cs', 'bod'])},
    {path:'data-annotate', element: <DataAnnotate />, loader: () => checkAuth(['admin', 'content', 'seo'])},
    {path:'accounting-report', element: <Accounting />, loader: () => checkAuth(['admin', 'accounting', 'bod'])},
    {path:'invoice-parser', element: <PDFParser />, loader: () => checkAuth(['admin', 'int_accounting', 'bod'])},
    // {path:'demo', element: <Demo />}
    {path: '403-forbidden', element: <ForbiddenPage />},
  ]
  },
  {path: '/login',
  element: <Login />,
  action: authAction
  },
  {
    path: '*',  // Catch all unmatched routes
    element: <NotFoundPage />
  },
  {path: '/oauth-callback', element: <OAuthCallback />}
]);

function App() {
  return (
    <WebSocketProvider >
      <RouterProvider router={router} />
    </WebSocketProvider>
  );
}

export default App;
