import {forwardRef, useState, useRef, useMemo} from 'react';

import 'bootstrap/dist/css/bootstrap.min.css'
import {AddCartItem, AddQuestionItem} from './cart-item';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faRefresh,
} from "@fortawesome/free-solid-svg-icons";

import { getAuthToken } from "../util/token";
import apiClient from '../api/client';

const CartBoardEditor = forwardRef((props, ref) => {

    const[isLoading, setIsLoading] = useState(false)

    const rank = props.items.rank;
    const number_of_heading = props.items.number_of_heading;
    const url = props.items.url;
    const root_domain = props.items.root_domain;
    const title = props.items.title;
    const description = props.items.description;
    const content = props.items.content || [];

    //auth token
    const token = getAuthToken();
    const userName = localStorage.getItem('username');


    const updateItemHandler = (e, keyword, ranking_url, loc) => {

      props.onUpdateItem('starting');

      let location = 'VN-vi'
      if (loc !== 'Location') {
        location = loc
      }
      setIsLoading(!isLoading);

      const payload = {
        keyword: keyword,
        url: ranking_url,
        location: location
      }

      apiClient.post('/api/render-js', payload, {headers: {'X-User': userName}})
      .then(({data}) =>{
        props.onUpdateItem(data[0]); //list
        setIsLoading(prevLoading => !prevLoading);
        // console.log(data2);
        // console.log(data[0]);
      }
      );
    };

    const onDragStartHandler = (e, heading) => {
      props.onDragStart(e, heading);
    };

    const insertContentHandler = (e, heading, body) => {
      props.onInsertContent(e, heading, body);
    };

    const llmSummarizeHandler = (body) => {
      props.onLLMSummarize(body);
    };


    return (
      <>
          <div className='col' ref={(el) => ref.current[rank] = el}>
            <div className='card'>
              <div className='d-flex align-items-center card-header'>
                  <span className='me-4'>Rank: {rank}</span>
                  <span className='me-4'>Headings: {number_of_heading}</span>
                  <span className='me-auto'>Word count: {props.items.word_count}</span>
                  <button className={`btn btn-light ${props.activeJS === 2 && 'disabled'}`} type="button" onClick={event => updateItemHandler(event,props.items.Keyword, url, props.location)}>
                    <span className={`spinner-border spinner-border-sm ${!isLoading && 'd-none'}`} role="status" aria-hidden="true"></span>
                    <FontAwesomeIcon className ={`${isLoading && 'd-none'}`} icon={faRefresh} />
                    <span className ={`${isLoading && 'd-none'}`}>  JS render</span>
                    <span className={`${!isLoading && 'visually-hidden'}`}>  Loading...</span>
                  </button>
              </div>
              <div className='card-body'>
                <div className='card-title mb-2'>
                <img className='me-2' src={props.items.logo} style={{'width': '20px'}} />
                <a href={url} target='_blank'>
                  {root_domain}
                </a>
                </div>
                <div className='card-text'>
                    <ul className="list-group"
                      >
                      <li className="list-group-item d-flex justify-content-between align-items-start">
                        <div className='me-auto'>
                          <div className="fw-bold">{title}</div>
                          {description}
                        </div>
                      </li>
                      {useMemo(() => 
                      content.length > 0 &&
                      content.map((item, index) => {
                        return (
                            <li className={`list-group-item list-group-item-action d-flex justify-content-between align-items-center w-auto ${
                              item.content_type === "H2" && "ms-2"
                            } 
                            ${props.detail === false && item.content_type === "P" && "d-none"} 
                            ${item.content_type === "H3" && "ms-3"} 
                            ${item.content_type === "H4" && "ms-4"} ${item.content_type === "P" && "ms-5"} 
                            ${props.disabled.includes(item.id) && 'disabled bg-light'}`}
                            >

                            <AddCartItem 
                            disabled={props.disabled} 
                            items={item} 
                            onDragStart={onDragStartHandler}
                            onInsertContent={insertContentHandler}
                            onLLMSummarize={llmSummarizeHandler}
                            detail={props.detail}
                            /> 
                            </li>
                        )}
                        ),[content, props.detail])}
                    </ul>
                </div>
              </div>
            </div>
          </div>
        </>
    )
});

const QuestionBoardEditor = (props) => {

  const forum = props.items.Forum;
  // const url = props.items.url;
  // const title = props.items.title;
  // const description = props.items.description;
  const questions = props.items.questions

  const onDragStartHandler = (e, heading) => {
    props.onDragStart(e, heading);
  };

  const insertContentHandler = (e, heading, body) => {
    props.onInsertContent(e, heading, body);
  }

  return (
    <>
        <div className={`col ${props.forumFilter !== 'All' && props.forumFilter !== forum && 'd-none'}`}>
          <div className='card'>
            <div className='d-flex align-items-center card-header'>
                <img className='me-2' src={`${forum === 'Quora' ? 'https://qsf.fs.quoracdn.net/-4-ans_frontend_assets.favicon-new.ico-26-e7e93fe0a7fbc991.ico' : forum === 'Reddit' ? 'https://www.redditstatic.com/shreddit/assets/favicon/64x64.png' :  'gg-logo.png'}`} style={{'width': '20px'}} />
                <span className='me-auto'>{forum}</span>
            </div>
            <div className='card-body'>
              {/* <div className='card-title mb-2'> */}
              {/* <a href={url}>
                {forum === 'Quora' ? 'quora.com':'reddit.com'}
              </a> */}
              {/* </div> */}
              <div className='card-text'>
                  <ul className="list-group"
                  >
                  {questions.map((item, index) => 
                    <AddQuestionItem
                    key={item.id}
                    disabled={props.disabled}
                    items={item}
                    index={index}
                    onDragStart={onDragStartHandler}
                    onInsertContent={insertContentHandler}
                    /> 
                    )}
                  </ul>
              </div>
            </div>
          </div>
        </div>
      </>
  )
};


export default CartBoardEditor;
export const Question = QuestionBoardEditor;