import { Link } from 'react-router-dom';
import '../styles/404.css';

const NotFoundPage = () => {
  return (
    <div className="error-page">
      <div className="error-content">
        <div className="error-number">
          <div className="four">4</div>
          <div className="zero">0</div>
          <div className="four">4</div>
        </div>
        <div className="error-text">
          <h2>Oops! Page not found</h2>
          <p>The page you're looking for might have been removed, had its name changed, or is temporarily unavailable.</p>
          <Link to="/" className="home-button">
            <span>Return Home</span>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
              <path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path>
              <polyline points="9 22 9 12 15 12 15 22"></polyline>
            </svg>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default NotFoundPage;