import axios from 'axios';
import { getDeviceId } from "../util/deviceManager";

const apiClient = axios.create({
    baseURL: process.env.REACT_APP_HOST_IP
});

// Request interceptor
apiClient.interceptors.request.use(config => {
    const token = localStorage.getItem('token');
    const deviceId = localStorage.getItem('device_id');
    
    config.headers = {
        ...config.headers,
        // 'Content-Type': 'application/json',
        'Authorization': token ? `Bearer ${token}` : '',
        'X-DEVICE-ID': deviceId || getDeviceId()
    };
    
    return config;
});

// Debounce function
function debounce(func, delay) {
    let timeout;
    return (...args) => {
        clearTimeout(timeout);
        timeout = setTimeout(() => func(...args), delay);
    };
}

// Debounced function to update expiration
const updateExpiration = debounce(() => {
    const newExpiration = new Date();
    newExpiration.setHours(newExpiration.getHours() + 1);
    localStorage.setItem('expiration', newExpiration.toISOString());
}, 5000); // 5000ms debounce

// Response interceptor
apiClient.interceptors.response.use(
    response => {
        if (response.status === 200) {
            updateExpiration();
        }
        return response;
    },
    error => {
        return Promise.reject(error);
    }
);

export default apiClient;