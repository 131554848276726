import { useState, useRef, Fragment, useEffect, useMemo } from 'react';

import "bootstrap/dist/css/bootstrap.min.css";
// import "bootstrap/dist/js/bootstrap.bundle.min.js";
import "bootstrap/dist/js/bootstrap.min.js";
import * as bootstrap from 'bootstrap/dist/js/bootstrap'

import { OverlayTrigger, Popover } from 'react-bootstrap';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTrash, faDownload, faRefresh, faFilter, faMinus, faCalendar, faAngleUp, faAngleDown, faTimesCircle, faLineChart, faUpload, faGlobe, faPencil, faCheckCircle
} from "@fortawesome/free-solid-svg-icons";

import {showLoading, hideLoading } from 'react-redux-loading-bar';
import { useDispatch } from "react-redux";

import { getAuthToken } from '../util/token';
import { useNavigate, useParams } from 'react-router-dom';
import apiClient from '../api/client';


function ManageTasks() {
    const [taskList, setTaskList] = useState([]);
    const [filteredTaskList, setFilteredTaskList] = useState([]);
    const [filter, setFilter] = useState([]);
    const [sort,setSort] = useState('');
    const [showResult, setShowResult] = useState(10);
    const [pagination, setPagination] = useState([]);
    const [selectedPage, setSelectedPage] = useState(1);
    const [currentPageRange, setCurrentPageRange] = useState(1);

    const [ selectedType, setSelectedType] = useState('');
    const [ selectedTopic, setSelectedTopic] = useState('');
    const [selectedDomain, setSelectedDomain] = useState('');
    const [selectTaskList, setSelectTaskList] = useState([]);

    //Modal states:
    const [mKey, setMKey] = useState('');
    const [mDomain, setMDomain] = useState('');
    const [mCountry, setMCountry] = useState('');
    const [mCategory, setMCategory] = useState('');
    const [mURL, setMURL] = useState('');
    const [cFreq, setCFreq] = useState();
    const [mFreq, setMFreq] = useState('');

    const [importStat, setImportStat] = useState('');

    // Bulk Edit
    const multiFreq = useRef();
    const multiCat = useRef();
    const multiURL = useRef();
    const multiDays = useRef();

    //auth token
    const token = getAuthToken();
    const userName = localStorage.getItem('username');

    //go to detail task
    const navigate = useNavigate();
    const [ username, setUsername] = useState('');
    const [ taskId, setTaskId] = useState('');
    const [ deleteMode, setDeleteMode] = useState('');

    const domainSearch = useRef();
    const topicSearch = useRef();
    



    const dispatch = useDispatch();


    useEffect(() => {
      const input = {}
      apiClient.post('/manage-tasks', input, {headers: {'X-User': userName}})
      .then (
          ({data}) =>{
            console.log(data);
              setTaskList(data);
              setFilteredTaskList(data);
              let newPagination = [];
              for (let i = 1; i <= Math.ceil(data.length/showResult); i++){
                // console.log(i);
                newPagination.push(
                  <a class="page-link" href="#" onClick={(e) => {e.preventDefault();
                    setSelectedPage(i);
                    const el = document.querySelectorAll('li[class*=active]')[0];
                    el.className = 'page-item';
                    document.getElementById(`page-${i}`).className = 'page-item active';
                    }}>{i}
                  </a>
                )
              };
              setPagination(newPagination);
              // const cat = [...new Set(data.map(item => item.Domain))];
              // setDomain([...cat]);
              // setSelectedDomain(data[0].Domain);
              // const ct = [...new Set(data.map(item => item.Country))];
              // console.log(cat, ct);
              // setCountry([...ct]);
          })
    },[]);


    useEffect(() => {
      setCurrentPageRange(1);
      setSelectedPage(1);
      let newPagination = [];
      const filtered_range = taskList.filter((item, index) => applyFilterHandler(item));
      setFilteredTaskList(filtered_range);
      for (let i = 1; i <= Math.ceil(filtered_range.length/showResult); i++){
        newPagination.push(
          <a class="page-link" href="#" onClick={(e) => {e.preventDefault();
            setSelectedPage(i);
            const el = document.querySelectorAll('li[class*=active]')[0];
            el.className = 'page-item';
            document.getElementById(`page-${i}`).className = 'page-item active';
            }}>{i}
          </a>
        )
      };
      setPagination(newPagination);
    },[selectedDomain, showResult, selectedType, selectedTopic, taskList]);


    const filterHandler = (e, mode) => {
      e.preventDefault();
      setFilter(current => [...current, mode]);
    };

    const sortHandler = (mode) => {
      if (mode === 'topic'){
        if (sort !== 'topic_inc' && sort !== 'topic_dec'){
          setSort('topic_inc');
          filteredTaskList.sort((a,b) => (b.topic > a.topic) ? -1 : (b.topic < a.topic) ? 1 : 0);
        } else if(sort === 'topic_dec'){
          setSort('topic_inc');
          filteredTaskList.sort((a,b) => (b.topic > a.topic) ? -1 : (b.topic < a.topic) ? 1 : 0);
        } else if(sort === 'topic_inc'){
          setSort('topic_dec');
          filteredTaskList.sort((a,b) => (b.topic > a.topic) ? 1 : (b.topic < a.topic) ? -1 : 0);
        };
      }};

  const clearFilterHandler = (e, mode) =>{
    const newFilter = filter.filter(item => item !== mode);
    setFilter(newFilter);
    if(mode === 'Type'){
      setSelectedType('');
    }else if (mode === 'Topic'){
      setSelectedTopic('');
    }else if (mode === 'Domain'){
      setSelectedDomain('');
    }
  };

  const changeShowResultHandler= (result) => {
    setCurrentPageRange(1);
    setSelectedPage(1);
    setShowResult(result);
  };



  const filterModeHandler = () => {
    // setFilterMode(current => !current);
    if(filter.includes('Topic') === true) {
      const search_cat_mode = document.getElementById("topic-search-mode").innerHTML;
      let search_category = topicSearch.current.value;
      if (search_cat_mode === 'E') {
        search_category = `^${topicSearch.current.value}$`;
      }
      setSelectedTopic(c => search_category)
    };
    if(filter.includes('Domain') === true) {
      const search_key_mode = document.getElementById("domain-search-mode").innerHTML;
      let search_key = domainSearch.current.value;
      if (search_key_mode === 'E') {
        search_key = `^${domainSearch.current.value}$`;
      }
      setSelectedDomain(c => search_key)
    };
    // if(filter.includes('Country') === true) setSelectedCountry(country);
    // if(filter.includes('Domain') === true) setSelectedDomain(selectedCategory);
  };


  const applyFilterHandler = (item) => {
    console.log('re render');
    return(
      (selectedType !== '' && selectedType !== 'Type' ? item.task === selectedType : true) &&
      (selectedTopic !== '' ? item.topic.search(new RegExp(selectedTopic, 'i')) !== -1 : true) &&
      (selectedDomain !== '' ? item.domain.search(new RegExp(selectedDomain, 'i')) !== -1 : true)
    )
  };


    const changeSelectedKwHandler = (e, kw) => {
      if (e.target.checked) {
        if (kw === undefined) {
          const d = document.getElementsByClassName('row-checkbox');
          for ( let i = 0; i < d.length; i++) d[i].checked = true;
          setSelectTaskList(filteredTaskList);
        } else {
          setSelectTaskList(c => [...c, kw]);
        }
      } else {
        if (kw === undefined){
          const d = document.getElementsByClassName('row-checkbox');
          for ( let i = 0; i < d.length; i++) d[i].checked = false;
          setSelectTaskList([]);
        } else {
          const new_list = selectTaskList.filter(item => !(item.topic === kw.topic && item.domain === kw.domain && item.task === kw.task));
          setSelectTaskList(new_list);
        }
      };
      // console.log(selectKwList, kw);
    };

    const deleteTaskHandler = () => {

      let input = [];
      if (deleteMode === 'one'){
        input = [{
          'id': taskId
        }]
      } else if (deleteMode === 'many'){
        input = selectTaskList.map(item => {return {'id': item._id}})
      }
      apiClient.delete('/api/delete-tasks', input, {headers: {'X-User': userName}})
      .then (
          ({data}) =>{
            if(data === null){
              setImportStat(false);
              return
            }
            if(deleteMode === 'many') setSelectTaskList([]);
            setImportStat(true);
            console.log(data);
            setTaskList(data);
          }
      );
      const d = document.getElementsByClassName('row-checkbox');
      for ( let i = 0; i < d.length; i++) d[i].checked = false;
      setDeleteMode('');
    }

    return (
        <div className="d-flex flex-column">
            <h1 className="text-center mt-4 mb-5">Pending Tasks</h1>
            <div className='my-5'>
              <div className="d-flex mb-2">
              <div class="dropdown me-3">
                    <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-expanded="false">
                        <FontAwesomeIcon icon={faFilter} />
                        <span className='ms-2'>Filter by</span>
                    </button>
                    <ul class="dropdown-menu" style={{'z-index':'99'}} aria-labelledby="dropdownMenuButton1">
                        <li onClick={e => filterHandler(e, 'Topic')}><a class="dropdown-item" href="#">Topic</a></li>
                        <li onClick={e => filterHandler(e, 'Domain')}><a class="dropdown-item" href="#">Domain</a></li>
                        <li onClick={e => filterHandler(e, 'Type')}><a class="dropdown-item" href="#">Type</a></li>
                    </ul>
                </div>
                <div className={`dropdown me-3 ${selectTaskList.length === 0 && 'd-none'}`}>
                    <button class="btn btn-danger" type="button" data-bs-toggle="modal" data-bs-target="#ModalAlert" aria-expanded="false" onClick={e => {setDeleteMode('many'); setImportStat('')}}>
                        <span className='ms-2'>Delete</span>
                    </button>
                </div>
                <div className={`d-flex ${filter.length === 0 && 'd-none'}`}>
                    {/* <button class="btn btn-sm btn-close btn-outline-danger p-2 align-self-center me-2" type="button" aria-expanded="false" onClick={clearFilterHandler}/> */}
                    <div class={`input-group flex-nowrap me-2 ${filter.includes('Topic') ===false && 'd-none'}`}>
                        <button class="btn btn-sm btn-outline-danger p-2 align-self-center" id="button-addon1" type="button" onClick={e => clearFilterHandler(e, 'Topic')}>X</button>
                        <button class="btn btn-outline-secondary dropdown-toggle" type="button" id="topic-search-mode" data-bs-toggle="dropdown" aria-expanded="false">M</button>
                        <ul class="dropdown-menu">
                          <li><a class="dropdown-item" href="#" onClick={e => {e.preventDefault(); document.getElementById("topic-search-mode").innerHTML = "E"}}>E</a></li>
                          <li><a class="dropdown-item" href="#" onClick={e => {e.preventDefault(); document.getElementById("topic-search-mode").innerHTML = 'B'}}>B</a></li>
                        </ul>
                        <input type="text" class="form-control" placeholder="Topic Search..." aria-label="Category Search..." aria-describedby="addon-wrapping" ref={topicSearch}/>
                    </div>
                    <div class={`input-group flex-nowrap me-2 ${filter.includes('Domain') ===false && 'd-none'}`}>
                        <button class="btn btn-sm btn-outline-danger p-2 align-self-center" id="button-addon1" type="button" onClick={e => clearFilterHandler(e, 'Domain')}>X</button>
                        <button class="btn btn-outline-secondary dropdown-toggle" type="button" id="domain-search-mode" data-bs-toggle="dropdown" aria-expanded="false">M</button>
                        <ul class="dropdown-menu">
                          <li><a class="dropdown-item" href="#" onClick={e => {e.preventDefault(); document.getElementById("domain-search-mode").innerHTML = "E"}}>E</a></li>
                          <li><a class="dropdown-item" href="#" onClick={e => {e.preventDefault(); document.getElementById("domain-search-mode").innerHTML = 'B'}}>B</a></li>
                        </ul>
                        <input type="text" class="form-control" placeholder="Domain Search..." aria-label="Keyword Search..." aria-describedby="addon-wrapping" ref={domainSearch}/>
                    </div>
                    <div class={`input-group flex-nowrap me-2 w-auto ${filter.includes('Type') ===false && 'd-none'}`}>
                        <button class="btn btn-sm btn-outline-danger p-2 align-self-center" id="button-addon1" type="button" onClick={e => clearFilterHandler(e, 'Type')}>X</button>
                        <button class="btn btn-light dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                            <span className='ms-2'>{`${selectedType === '' ? 'Type' : selectedType}`}</span>
                        </button>
                        <ul class="dropdown-menu" style={{'z-index':'99'}} aria-labelledby="dropdownMenuButton1">
                          <li ><a class="dropdown-item" href="#" onClick={e => {e.preventDefault(); setSelectedType('outline')}}>outline</a></li>
                          <li ><a class="dropdown-item" href="#" onClick={e => {e.preventDefault(); setSelectedType('grouping')}}>cluster</a></li>
                        </ul>
                    </div>
                    <button class="btn btn-primary ms-2" type="button" aria-expanded="false"
                    onClick={filterModeHandler}
                    >
                      Apply
                    </button>
                </div>
              </div>
              <div className="overflow-auto mb-3" style={{"height":"500px"}}>
                  <table class="table table-striped" style={{'table-layout':'fixed', 'word-wrap': 'break-word'}}>
                      <thead className="table-primary sticky-top" style={{'z-index':'9'}}>
                          <tr>
                          <th scope="col" style={{'width':'5%'}}>
                            <div>
                              <input class="form-check-input" type="checkbox" id='master-checkbox' aria-label="..." onChange={e => {changeSelectedKwHandler(e)}}/>
                            </div>
                          </th>
                            <th scope="col" style={{'width':'5%'}}>#</th>
                            <th scope="col" style={{'width':'15%'}}>
                              <button class="btn" type="button" aria-expanded="false" onClick={event => sortHandler('topic')}>
                                  <span className='me-2 fw-bold'>Topic </span>
                                  <FontAwesomeIcon icon={faAngleUp} style={{'color':`${sort === 'topic_inc' ? '#000' : '#6c757d'}`}}/>
                                  <FontAwesomeIcon icon={faAngleDown} style={{'color':`${sort === 'topic_dec' ? '#000' : '#6c757d'}`}}/>
                              </button>
                            </th>
                            <th scope="col" style={{'width':'10%'}}>Domain</th>
                            <th scope="col" style={{'width':'15%'}} >Description</th>
                            <th scope="col" style={{'width':'10%'}}>Date</th>
                            <th scope="col" style={{'width':'5%'}}>Type</th>
                            <th scope="col" style={{'width':'10%'}} >Action</th>
                          </tr>
                      </thead>
                      <tbody>
                          {useMemo(() =>
                            filteredTaskList.slice(showResult*(selectedPage-1),showResult*selectedPage).map((item, index) =>{
                              return (
                                  <tr key={index}>
                                    <th><div>
                                          <input class="form-check-input row-checkbox" type="checkbox" value="" aria-label="..." 
                                          onChange={e => {changeSelectedKwHandler(e, item)}}
                                          />
                                        </div>
                                    </th>
                                    <th scope="row">{(index+1)+(selectedPage-1)*showResult}</th>
                                    <td>{item.topic}</td>
                                    <td>{item.domain}</td>
                                    <td>{item.description}</td>
                                    <td>{new Date(item.date).toDateString()}</td>
                                    <td>{item.task}</td>
                                    <td>
                                        <button class="btn btn-light" type="button" aria-expanded="false" 
                                        onClick = {e => 
                                          item.task === 'outline' ?
                                          navigate(`/?id=${item._id}`) :
                                          item.task === 'grouping' ?
                                          navigate(`/keyword-cluster?id=${item._id}`) :
                                          item.task === 'audit' ?
                                          navigate(`/technical-audit?id=${item.project_id}`) :
                                          item.task === 'draft' ?
                                          navigate(`/content-audit?id=${item._id}`) :
                                          null
                                        }
                                        >
                                          <FontAwesomeIcon icon={faPencil} size='sm'/>
                                        </button>
                                        <button className="btn btn-danger ms-3" type="button" aria-expanded="false" data-bs-toggle="modal" data-bs-target="#ModalAlert"
                                        onClick = {e => {
                                          setDeleteMode('one');
                                          setImportStat('');
                                          setUsername(item.username);
                                          setTaskId(item._id);
                                        }}
                                        >
                                          <FontAwesomeIcon icon={faTrash} size='sm'/>
                                        </button>
                                    </td>
                                  </tr>
                              )
                          }),[filteredTaskList, sort, selectedPage, showResult, selectTaskList])
                          }
                      </tbody>
                  </table>
                  {/* Modal Import*/}
                  <div class="modal fade" id="ModalAlert" tabindex="-1" role="dialog" aria-labelledby="ModalCenterTitle" aria-hidden="true">
                          <div class="modal-dialog modal-dialog-centered" role="document">
                              <div class="modal-content">
                                <div className="modal-header">
                                    <h5 class="modal-title me-auto" id="ModalLongTitle">Warning Alert</h5>
                                    <div className={`${[false,'',null].includes(importStat) ? 'd-none' : ''}`} id="success-noti">
                                      <FontAwesomeIcon icon={faCheckCircle} style={{'color':'green'}}/>
                                      <span className='ms-1'>Success</span>
                                    </div>
                                    <div className={`${[true,'',null].includes(importStat) ? 'd-none' : ''}`} id="failed-noti">
                                      <FontAwesomeIcon icon={faTimesCircle} style={{'color':'red'}}/>
                                      <span className='ms-1'>Failed. Pls Retry!</span>
                                    </div>
                                </div>
                                <div class="modal-body" style={{'height':'100px', 'overflow':'auto'}}>
                                      <h5 className='text-center'>Are you sure?</h5>
                                </div>
                                <div class="modal-footer">
                                    <button type="button" class="btn btn-secondary me-2" data-bs-dismiss="modal" onClick={e => setImportStat('')}>Close</button>
                                    <button type="button" className={`btn btn-primary`} id='import-button' onClick={deleteTaskHandler}>Confirm</button>
                                </div>
                              </div>
                          </div>
                        </div>
                  {/* Modal Import*/}
              </div>
              <div className="d-flex">
                <div class="input-group mb-3 me-3 ms-auto w-25">
                  <label class="input-group-text" for="inputGroupSelect01">Results per page</label>
                  <select class="form-select" id="inputGroupSelect01" onChange={e => changeShowResultHandler(e.target.value)}>
                    <option selected value="10">10</option>
                    <option value="20">20</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                    <option value="500">500</option>
                  </select>
                </div>
                <nav aria-label="Page navigation example">
                  <ul class="pagination">
                    <li class={`page-item ${currentPageRange === 1 && 'disabled'}`}
                    onClick = {e => {
                      e.preventDefault();
                      setCurrentPageRange(current => {
                        if(current > 1){
                          return current -1
                        }
                        return current;
                        })}}
                    ><a class="page-link" href="#">Previous</a></li>
                    {pagination.map((item, index) => {return(
                      <li  key={index+1} id={`page-${index+1}`} className={`page-item ${index+1 === selectedPage && 'active'} ${(index+1 > 10*currentPageRange || index + 1 <= 10*(currentPageRange-1)) && 'd-none'}`}>
                        {item}
                      </li>
                      )})
                    }
                    <li
                    class={`page-item ${(pagination.length <= 10 || currentPageRange + 1 > Math.ceil(pagination.length/10)) && 'disabled'}`}
                    onClick = {e => {
                      e.preventDefault();
                      setCurrentPageRange(current => {
                        if(current + 1 <= Math.ceil(pagination.length/10)) {
                          return (current +1);
                        }
                        return current;
                      })}
                      }
                    ><a class="page-link" href="#">Next</a></li>
                  </ul>
                </nav>
                
              </div>
            </div>
        </div>
    )
};

export default ManageTasks;