import Head from "../components/loading-bar";
import Menu from "../components/menu";

import "bootstrap/dist/css/bootstrap.min.css";
import 'bootstrap/dist/js/bootstrap.bundle.min.js';

function ForbiddenPage() {
    return (
        <>
            {/* <Head /> */}
            <div className="container vh-100">
                {/* <Menu /> */}
                <div className="d-flex flex-column">
                    <h1 className="text-center mt-4 mb-5">Permission Denied</h1>
                    <h2 className="text-center">You Don't Have Access To This Resource.</h2>
                </div>
            </div>
        </>
    ) 
};
export default ForbiddenPage;