import React, { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { getDeviceId } from '../util/deviceManager';
import apiClient from '../api/client';

const OAuthCallback = () => {
    const [searchParams] = useSearchParams();

    useEffect(() => {
        const code = searchParams.get('code');
        const incomingState = searchParams.get('state');
            
        // Get and immediately clear the stored state
        const savedState = localStorage.getItem('oauth_state');
        localStorage.removeItem('oauth_state');

        // Verify state parameter
        if (!incomingState || !savedState || incomingState !== savedState) {
            localStorage.setItem('oauth_result', JSON.stringify({
                success: false,
                error: 'Invalid state parameter.' //Possible CSRF attack.
            }));
            document.write(`
                <html>
                  <head>
                    <title>Invalid state parameter.</title>
                    <script type="text/javascript">
                      window.close();
                    </script> 
                  </head>
                  <body>
                    <h1>Validation failed.</h1>
                  </body>
                </html>
              `);
            return;
        }
        
        if (code) {
            const device_id = getDeviceId();
            
            apiClient.get(`/api/callback?code=${code}&device_id=${device_id}`)
                .then(({ data }) => {
                    if (data?.token) {
                        // Store auth result in localStorage
                        localStorage.setItem('oauth_result', JSON.stringify({
                            success: true,
                            data
                        }));
                    } else {
                        throw new Error('Authentication failed');
                    }
                })
                .catch(error => {
                    localStorage.setItem('oauth_result', JSON.stringify({
                        success: false,
                        error: error.message
                    }));
                })
                .finally(() => {
                    // Add script to close window
                    document.write(`
                        <html>
                          <head>
                            <title>Thank you for validation.</title>
                            <script type="text/javascript">
                              window.close();
                            </script> 
                          </head>
                          <body>
                            <h1>Validation completed.</h1>
                          </body>
                        </html>
                      `);
                });
        }
    }, [searchParams]);

    return <div>Processing authentication...</div>;
};

export default OAuthCallback;