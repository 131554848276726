import "bootstrap/dist/css/bootstrap.min.css";
// import "bootstrap/dist/js/bootstrap.bundle.min.js";
import "bootstrap/dist/js/bootstrap.min.js";
// import * as bootstrap from 'bootstrap/dist/js/bootstrap'

import { useState, useRef, Fragment, useEffect, useMemo } from 'react';
import Accordion from 'react-bootstrap/Accordion';
import Collapse from 'react-bootstrap/Collapse';
import Offcanvas from 'react-bootstrap/Offcanvas';
import { OverlayTrigger, Popover } from 'react-bootstrap';

import AuditURL from "./audit_url_detail";

import {showLoading, hideLoading } from 'react-redux-loading-bar';
import { useDispatch } from "react-redux";
import { v4 as uuid4 } from 'uuid';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTrash, faDownload, faRefresh, faFilter, faMinus, faCalendar, faAngleUp, faAngleDown, faTimesCircle, faLineChart, faUpload, faGlobe, faPencil, faCheckCircle, faSearch, faCircleCheck, faArrowDown, faCaretDown, faFileExcel
} from "@fortawesome/free-solid-svg-icons";

import apiClient from '../api/client';




const AuditIssue = (props) => {
    
    const [issueURL, setIssueURL] = useState([]);
    const [filteredIssueURL, setFilteredIssueURL] = useState([]);
    const [open, setOpen] = useState([]);
    const [sidebarDisplayMode, setSidebarDisplayMode] = useState('category');
    const [byPriority, setByPriority] = useState([]);
    const [filteredList, setFilteredList] = useState([]);
    const [noFilter, setNoFilter] = useState(1);
    const searchIssue = useRef('');
    const [sort,setSort] = useState('');
    const [filterArr, setFilterArr] = useState([{
        id: uuid4(),heading: 'URL', operation: 'contains', value: ''
    }]);

    //table pagination controller
    const [showResult, setShowResult] = useState(20);
    const [pagination, setPagination] = useState([]);
    const [selectedPage, setSelectedPage] = useState(1);
    const [currentPageRange, setCurrentPageRange] = useState(1);
    const [wdth, setWdth] = useState('300px');
    const [firstColWidth, setfirstColWidth] = useState('300px');

    //canvas
    const [showCanvas, setShowCanvas] = useState(false);
    const [URLDetail, setURLDetail] = useState([]);
    const [container, setContainer] = useState({});

    const dispatch = useDispatch();
    
    useEffect(() => {
        const data = props.issueURL;
        // console.log(data);
        setIssueURL(data);
        setFilteredIssueURL(data);
        let newPagination = [];
        for (let i = 1; i <= Math.ceil(data.length/showResult); i++){
            // console.log(i);
            newPagination.push(
            <a class="page-link" href="#" onClick={(e) => {e.preventDefault();
                setSelectedPage(i);
                const el = document.querySelectorAll('li[class*=active]')[0];
                el.className = 'page-item';
                document.getElementById(`page-${i}`).className = 'page-item active';
                }}>{i}
            </a>
            )
        };
        setPagination(newPagination);
    },[props.issueURL]);

    useEffect(() => {
        setWdth(`${document.getElementsByClassName('second-col')[0].clientWidth + document.getElementsByClassName('first-col')[0].clientWidth}px`);
        setfirstColWidth(`${document.getElementsByClassName('first-col')[0].clientWidth}px`);
        let newPagination = [];
        for (let i = 1; i <= Math.ceil(filteredIssueURL.length/showResult); i++){
            // console.log(i);
            newPagination.push(
            <a class="page-link" href="#" onClick={(e) => {e.preventDefault();
                setSelectedPage(i);
                const el = document.querySelectorAll('li[class*=active]')[0];
                el.className = 'page-item';
                document.getElementById(`page-${i}`).className = 'page-item active';
                }}>{i}
            </a>
            )
        };
        setPagination(newPagination);
    },[filteredIssueURL, selectedPage, showResult]);

    // useEffect(() => {
    //     setCurrentPageRange(1);
    //     setSelectedPage(1);
    //     let newPagination = [];
    //     for (let i = 1; i <= Math.ceil(issueURL.length/showResult); i++){
    //       newPagination.push(
    //         <a class="page-link" href="#" onClick={(e) => {e.preventDefault();
    //           setSelectedPage(i);
    //           const el = document.querySelectorAll('li[class*=active]')[0];
    //           el.className = 'page-item';
    //           document.getElementById(`page-${i}`).className = 'page-item active';
    //           }}>{i}
    //         </a>
    //       )
    //     };
    //     setPagination(newPagination);
    //   },[showResult]);

    const changeShowResultHandler= (result) => {
        setCurrentPageRange(1);
        setSelectedPage(1);
        setShowResult(result);
      };
    
    const countIssue = (obj, category, type) => {
        let count = 0;
        if (type === 'pass'){
            Object.keys(obj[category]).map((issue, idx) => {
                if (parseInt(obj[category][issue]['value']) === 0 && obj[category][issue]['title'] !== 'All'){
                    count += 1;
                }
            });
        } else if (!['Critical', 'Warning', 'Info'].includes(category)) {
            Object.keys(obj[category]).map((issue, idx) => {
                if (parseInt(obj[category][issue]['value']) > 0 && obj[category][issue]['title'] !== 'All'){
                    count += 1;
                }
            });
        } else if (['Critical', 'Warning', 'Info'].includes(category)){
            Object.keys(obj[category]).map((issue, idx) => {
                if (parseInt(obj[category][issue]['value']) > 0 && issue !== 'All'){
                    count += 1;
                }
            });
        }
        return count
    };

    const switchModeHandler = (mode) => {
        setSidebarDisplayMode(mode);
        const prior = {
            'Critical':{},
            'Warning': {},
            'Info': {}
        }
        console.log(props.stat)
        if (mode = 'priority'){
            Object.keys(props.stat).map(category => {
                Object.keys(props.stat[category]).map(issue => {
                    if(props.stat[category][issue]['value'] > 0){
                        if (!(issue in prior[props.stat[category][issue]['importance']])){

                            prior[props.stat[category][issue]['importance']][issue] = {};
                        }
                        prior[props.stat[category][issue]['importance']][issue] = {
                            'title': props.stat[category][issue]['title'],
                            'value': props.stat[category][issue]['value'],
                            'description': props.stat[category][issue]['description'],
                            'category': category,
                            'link': props.stat[category]['link']
                        }
                    }
                })
            });
            // console.log(prior);
            setByPriority(prior);
        }
    };
    const filterHandler = (val) => {
        let filter = {};
        // console.log(props.stat);
        if (sidebarDisplayMode === 'category'){
            Object.keys(props.stat).map(category => {
                filter[category] = {'link': props.stat[category]['link']};
                Object.keys(props.stat[category]).map(issue => {
                    if (issue !== 'link' && props.stat[category][issue]['title'].search(new RegExp(`^${val}`, 'i')) !== -1){
                        if ( !(issue in filter[category])){
                            filter[category][issue] = {}
                        };
                        filter[category][issue] = props.stat[category][issue];
                    }
                })
            })
        } else if (sidebarDisplayMode === 'priority'){
            Object.keys(byPriority).map(category => {
                filter[category] = {};
                Object.keys(byPriority[category]).map(issue => {
                    if (byPriority[category][issue]['title'].search(new RegExp(`^${val}`, 'i')) !== -1){
                        if ( !(issue in filter[category])){
                            filter[category][issue] = {}
                        };
                        filter[category][issue] = byPriority[category][issue];
                    }
                })
            })
        }
        setFilteredList(filter);

    };

    const onSetToggleDetail = () => {
        props.toggleDetail();
    };

    const auditDetailHandler = (category, issue, link, title, description, importance, displayCategory) => {
        // console.log(category, issue, link, title, description, importance);
        props.auditDetailHandler(category, issue, link, title, description, importance, displayCategory);
    };

    const exportFileHandler = (type, obj) => {
        // console.log(obj);
        props.exportFile(type, obj);
    }
    
    
    const triggerCanvasHandler = (crawlDate) => {
        if (Object.keys(container).includes(crawlDate)){
            return
        }
        apiClient.post(`/api/${props.endPoint}`, {
            category: props.category,
            url: props.url,
            issue: props.issue,
            projectId: props.projectId,
            audit_type: props.audit_type,
            dateAdded: crawlDate
        }).then(({data}) => {
            setContainer(c => {
            const d = {...c};
            d[crawlDate] = data;
            return d;
            });
        });
    };

    const sortHandler = (heading) => {
        // console.log(issueURL);
        if (sort !== `${heading}_inc` && sort !== `${heading}_dec`){
        setSort(`${heading}_inc`);
        filteredIssueURL.sort((a,b) => (b[heading] > a[heading]) ? -1 : (b[heading] < a[heading]) ? 1 : 0);
        } else if(sort === `${heading}_dec`){
        setSort('keyword_inc');
        filteredIssueURL.sort((a,b) => (b[heading] > a[heading]) ? -1 : (b[heading] < a[heading]) ? 1 : 0);
        } else if(sort === `${heading}_inc`){
        setSort(`${heading}_dec`);
        filteredIssueURL.sort((a,b) => (b[heading] > a[heading]) ? 1 : (b[heading] < a[heading]) ? -1 : 0);
        };
    };

    const incFilterHandler = (filterID) => {
        if(filterArr.length === 3 ) return;
        setFilterArr( c => {
            const d = [...c];
            d.push({id: filterID ,heading: 'URL', operation: 'contains', value: ''});
            return d
        });
        console.log(filterArr);
    };
    const updateFilterHandler = (filterID, type, value) => {
        const newArr = filterArr.filter(item => {
            if(item.id === filterID){
                const d = item;
                item[type] = value;
                return d
            }else {
                return item
            }
        });
        setFilterArr(newArr);
        // console.log(filterArr);
    };

    const applyFilterHandler = () => {
        //filterArr[{id, heading, operation, value}]
        console.log('re render');
        const arr = issueURL.filter(item => {
            let count = 0;
            filterArr.map(condition => {
                if (condition.operation === 'contains'){
                    count += eval(`"${item[condition.heading]}".includes("${condition.value}")`)
                }else if (condition.operation === 'does not contain'){
                    count += eval(`!"${item[condition.heading]}".includes("${condition.value}")`)
                }else if (condition.operation === 'is'){
                    // console.log(`"${item[condition.heading]}" === "${condition.value}"`);
                    count += eval(`"${item[condition.heading]}" === "${condition.value}"`)
                }else if (condition.operation === 'is not'){
                    console.log(`"${item[condition.heading]}" !== "${condition.value}"`);
                    count += eval(`"${item[condition.heading]}" !== "${condition.value}"`)
                }else if(condition.operation === 'greater than') {
                    count += eval(`"${item[condition.heading]}" > "${condition.value}"`)
                }else if (condition.operation === 'greater than or equal'){
                    count += eval(`"${item[condition.heading]}" >= "${condition.value}"`)
                }else if (condition.operation === 'less than'){
                    count += eval(`"${item[condition.heading]}" < "${condition.value}"`)
                }else if (condition.operation === 'less than or equal'){
                    count += eval(`"${item[condition.heading]}" <= "${condition.value}"`)
                } 
            })
            return count === filterArr.length
        });
        setFilteredIssueURL(arr);
        console.log(filterArr)
      };
    const deleteFilterHandler = (filterID) => {
        const arr = filterArr.filter(item => item.id !== filterID)
        setFilterArr(arr);
        console.log(filterArr);
    }
    
    const urlFilter = (
            <Popover id="popover-positioned-top" title="Popover bottom" style={{"max-width":"500px", 'border': 'none'}}>
                <div className='d-flex flex-column p-4 bg-white shadow'>
                    <p>Filter URLs</p>
                    {
                        filterArr.map(item => {
                            // console.log(item);
                            return (
                                <div className='d-flex align-items-center' key={item}>
                                    where
                                    <select class="form-select form-select-sm" aria-label="Default select example" className='ms-3 me-2'
                                        onChange={e => updateFilterHandler(item.id, 'heading', e.target.value)}
                                    >
                                        <option value='URL' selected={item.heading === '' ? true : item.heading === 'URL' ? true : false}>Page URL</option>
                                        { issueURL.length > 0 &&
                                            Object.keys(issueURL[0]).map(heading =>{
                                                if (heading !== 'URL') {
                                                    return (<option value={heading} selected={item['heading'] === heading}>{heading}</option>)
                                                } 
                                            })
                                        }
                                    </select>
                                    <select class="form-select form-select-sm" aria-label="Default select example" className='me-2'
                                        onChange={e => updateFilterHandler(item.id, 'operation', e.target.value)}
                                    >
                                        <option value='contains' selected={item.operation === '' ? true : item.operation === 'contains' ? true : false}>contains</option>
                                        <option value="does not contain" selected={item.operation === 'does not contain'}>does not contain</option>
                                        <option value="is" selected={item.operation === 'is'}>is</option>
                                        <option value="is not" selected={item.operation === 'is not'}>is not</option>
                                        <option value="greater than" selected={item.operation === 'greater than'}>greater than</option>
                                        <option value="greater than or equal" selected={item.operation === 'greater than or equal'}>greater than or equal</option>
                                        <option value="less than" selected={item.operation === 'less than'}>less than</option>
                                        <option value="less than or equal" selected={item.operation === 'less than or equal'}>less than or equal</option>
                                    </select>

                                    <input type="text" className="form-control me-2" style={{width: '120px'}} placeholder="Enter a value" aria-label="Enter a value" aria-describedby="basic-addon2" 
                                        defaultValue={item.value}
                                        onChange={e => updateFilterHandler(item.id, 'value', e.target.value)}
                                    />
                                    <button className='btn'
                                        onClick={e => {return}}
                                    ><FontAwesomeIcon icon={faTrash} onClick={e => deleteFilterHandler(item.id)}/></button>
                                </div>
                            )
                        })
                    }
                    <div className='d-flex mt-4'>
                        <button type="button" class={`btn btn-light me-auto ${filterArr.length === 3 && 'disabled'}`} onClick={e => incFilterHandler(uuid4())}>+ Add condition</button>
                        <button type="button" class="btn btn-primary" onClick={applyFilterHandler}>Apply</button>
                    </div>
                    
                </div>
            </Popover>
    );
    
    const showURLDetailHandler = (url) => {
        // console.log({
        //     category: props.category,
        //     url: url,
        //     inputURL: props.url,
        //     issue: props.issue,
        //     projectId: props.projectId,
        //     audit_type: props.audit_type,
        //     dateAdded: props.crawlDate
        // })
        dispatch(showLoading());
        apiClient.post('/api/url-detail', {
            category: props.category,
            url: url,
            inputURL: props.url,
            issue: props.issue,
            projectId: props.projectId,
            audit_type: props.audit_type,
            dateAdded: props.crawlDate
        }, {
            headers: {
            'X-User': props.userName
            }
        }).then(
            response => {
            dispatch(hideLoading());
            return response.data;
            }
        ).then(
            (data) => {
            console.log(data);
            setURLDetail(data);
            }
        );
    }

    return (
        <>
        <div className='d-flex my-5'>
            <div className='d-flex flex-column col-md-9'>
                <div className='mb-4'>
                    <a href='#' onClick= {e => {e.preventDefault(); onSetToggleDetail();}}>
                        Back to Overview
                    </a>
                </div>
                <div>
                    <div className='d-flex my-2'>
                        <span className={`me-3 rounded ${props.importance === 'Critical' ? 'bg-danger' : props.importance === 'Warning' ? 'bg-warning' : 'bg-light'}`} style={{width: '15px', height: '30px'}}> </span>
                        <h5 className='me-auto'>{props.title}</h5>
                        <button class="btn btn-primary me-2" onClick={e => setShowCanvas(true)}>
                            View History
                        </button>
                        {/* Offcanvas */}
                        <Offcanvas show={showCanvas} placement='end' onHide={e => setShowCanvas(false)} {...props}>
                            <Offcanvas.Header closeButton>
                                <Offcanvas.Title>{props.title}</Offcanvas.Title>
                            </Offcanvas.Header>
                            <Offcanvas.Body>
                                    <Accordion >
                                        {props.projectData.sort((a,b) =>  b['crawlDate'] - a['crawlDate']).map((item, index) => {
                                            const date = new Date(item['crawlDate']).toDateString() + ' ' + new Date(item['crawlDate']).toLocaleTimeString()
                                                return (
                                                    <Accordion.Item eventKey={index} >
                                                        <Accordion.Header onClick={e => triggerCanvasHandler(item['crawlDate'])}>
                                                            <span>
                                                                {date} - {item['summary'][props.category][props.issue]['value']} pages detected
                                                            </span>
                                                        </Accordion.Header>
                                                        <Accordion.Body id={item['crawlDate']} style={{maxHeight: '300px'}} className='overflow-auto'>
                                                            
                                                                {   Object.keys(container).includes(item['crawlDate'].toString()) &&
                                                                    <ul class="list-group list-group-flush text-wrap text-break">
                                                                        {container[item['crawlDate']].map((it, idx) => 
                                                                                <li class="list-group-item"><i className='me-4'>{idx + 1}</i><a href={it['URL']} target='_blank'>{it['URL']}</a></li>
                                                                        
                                                                        )}
                                                                    </ul>
                                                                }
                                                        </Accordion.Body>
                                                  </Accordion.Item>
                                                )
                                            })}
                                  </Accordion>
                            </Offcanvas.Body>
                        </Offcanvas>
                        {/* Offcanvas */}
                        <button type="button" className="btn btn-primary" onClick={e => exportFileHandler('excel-detail', filteredIssueURL)}>
                            <FontAwesomeIcon icon={faFileExcel} className='me-2 fa-xl' />
                            Export
                        </button>
                    </div>
                    <p>Category: {props.category}</p>
                    <p>{props.description}</p>
                </div>
                <div className='d-flex'>
                    <OverlayTrigger trigger="click" placement="bottom" rootClose overlay={urlFilter} container={this} containerPadding={20}>
                        <button type="button" class="btn btn-light">
                            <FontAwesomeIcon className='me-2' icon={faFilter} />
                            Filter URLs
                        </button>
                    </OverlayTrigger>
                </div>
                <div className="table-responsive text-nowrap mt-4 overflow-auto mb-3" style={{"height":"800px", width: '100%'}}>
                    <table className="table table-fixed" style={{'word-wrap': 'break-word', width: ''}}>
                        <thead className="table-primary sticky-top">
                            <tr>
                                <th scope="col" style={{position: 'sticky', left: 0, backgroundColor: '#cfe2ff',}} className='first-col'>#</th>
                                <th scope="col" style={{position: 'sticky', left: `${firstColWidth}`, backgroundColor: '#cfe2ff', width: '150px'}} className='second-col'>
                                    <button class="btn" type="button" aria-expanded="false" onClick={event => sortHandler('URL')}>
                                        <span className='me-2 fw-bold'>URL </span>
                                        <FontAwesomeIcon icon={faAngleUp} style={{'color':`${sort === 'URL_inc' ? '#000' : '#6c757d'}`}}/>
                                        <FontAwesomeIcon icon={faAngleDown} style={{'color':`${sort === 'URL_dec' ? '#000' : '#6c757d'}`}}/>
                                    </button>
                                </th>
                                <th style={{position: 'sticky', left: `${wdth}` , padding: 0, backgroundColor: '#cfe2ff'}}>&nbsp;</th>
                                {filteredIssueURL.length > 0 && 
                                    Object.keys(filteredIssueURL[0]).map(heading => {
                                        if (heading !== 'URL'){
                                            return (
                                                <th scope="col" className='w-auto'>
                                                    <button class="btn" type="button" aria-expanded="false" onClick={event => sortHandler(heading)}>
                                                        <span className='me-2 fw-bold'>{heading} </span>
                                                        <FontAwesomeIcon icon={faAngleUp} style={{'color':`${sort === `${heading}_inc` ? '#000' : '#6c757d'}`}}/>
                                                        <FontAwesomeIcon icon={faAngleDown} style={{'color':`${sort === `${heading}_dec` ? '#000' : '#6c757d'}`}}/>
                                                    </button>
                                                </th>
                                                )
                                        }
                                    })
                                }
                            </tr>
                        </thead>
                        <tbody>
                            {filteredIssueURL.length > 0 && filteredIssueURL.slice(showResult*(selectedPage-1),showResult*selectedPage).map((item, index) => {
                                    return (
                                        <tr key={index} onClick={e => showURLDetailHandler(item['URL'])} role='button'>
                                            <th scope="row" style={{position: 'sticky', left: 0}} className='bg-white w-auto first-col'>{(index+1)+(selectedPage-1)*showResult}</th>
                                            <td style={{position: 'sticky', left: `${firstColWidth}`,  width: '150px'}} className='bg-white second-col' data-bs-toggle='tooltip'
                                                data-bs-html="true"
                                                title={`${item['URL'] || ''}`}
                                            >
                                                <a href={item['URL']} target='_blank'>{
                                                item['URL'].length > 60 ?
                                                item['URL'].substring(0,60) + '...' :
                                                item['URL']
                                                }</a>
                                            </td>
                                            <td style={{position: 'sticky', left: `${wdth}`, backgroundColor: '#dee2e6', padding: 0}} className='w-auto'> </td>
                                            {
                                                Object.keys(item).map(heading => {
                                                    if (heading !== 'URL' && ['title', 'meta description', 'h1', 'h2', 'meta robots'].includes(heading)){
                                                            return(
                                                            <td className='w-auto' data-bs-toggle='tooltip' title={`${item[heading] || ''}`}>{
                                                                item[heading] && item[heading].length > 60 ?
                                                                item[heading].substring(0,60) + '...' :
                                                                item[heading]
                                                            }</td>)
                                                    } else if (heading !== 'URL'){
                                                        return (
                                                            <td className='w-auto'>{item[heading]}</td>
                                                        )
                                                    }  
                                                    }
                                                )
                                            }
                                        </tr>
                                    )
                                })
                            }   
                        </tbody>
                    </table>
                </div>
                <div className="d-flex">
                    <div class="input-group mb-3 me-3 ms-auto w-25">
                    <label class="input-group-text" for="inputGroupSelect01">Results per page</label>
                    <select class="form-select" id="inputGroupSelect01" onChange={e => changeShowResultHandler(e.target.value)}>
                        <option value="10">10</option>
                        <option selected value="20">20</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                        <option value="500">500</option>
                    </select>
                    </div>
                    <nav aria-label="Page navigation example">
                    <ul class="pagination">
                        <li class={`page-item ${currentPageRange === 1 && 'disabled'}`}
                        onClick = {e => {
                        e.preventDefault();
                        setCurrentPageRange(current => {
                            if(current > 1){
                            return current -1
                            }
                            return current;
                            })}}
                        ><a class="page-link" href="#">Previous</a></li>
                        {pagination.map((item, index) => {return(
                        <li  key={index+1} id={`page-${index+1}`} className={`page-item ${index+1 === selectedPage && 'active'} ${(index+1 > 10*currentPageRange || index + 1 <= 10*(currentPageRange-1)) && 'd-none'}`}>
                            {item}
                        </li>
                        )})
                        }
                        <li
                        class={`page-item ${(pagination.length <= 10 || currentPageRange + 1 > Math.ceil(pagination.length/10)) && 'disabled'}`}
                        onClick = {e => {
                        e.preventDefault();
                        setCurrentPageRange(current => {
                            if(current + 1 <= Math.ceil(pagination.length/10)) {
                            return (current +1);
                            }
                            return current;
                        })}
                        }
                        ><a class="page-link" href="#">Next</a></li>
                    </ul>
                    </nav>
                    
                </div>
            </div>
            <div className='d-flex flex-column col-md-3 ps-3 overflow-auto' style={{maxHeight:'800px'}}>
                <div class="btn-group mb-3" role="group" aria-label="Basic radio toggle button group" >
                    <input type="radio" class="btn-check" name="btnradio" id="btnradio1" autocomplete="off" checked={sidebarDisplayMode === 'category'} onClick={e => switchModeHandler('category')}/>
                    <label class="btn btn-outline-primary" for="btnradio1">by Category</label>

                    <input type="radio" class="btn-check" name="btnradio" id="btnradio2" autocomplete="off"  checked={sidebarDisplayMode === 'priority'} onClick={e => switchModeHandler('priority')}/>
                    <label class="btn btn-outline-primary" for="btnradio2">by Priority</label>
                </div>
                <div class="input-group mb-3" >
                    <div className='d-flex align-content-start flex-fill border border-light border-2 p-2' >
                        <FontAwesomeIcon icon={faSearch} className='fa-3 align-self-center me-2'/>
                        <input type="text" class="form-control" placeholder="Search..." aria-label="Search..." aria-describedby="button-addon2"
                        style={{border: 'none'}}
                        ref={searchIssue}
                        onChange={e => filterHandler(e.target.value)}
                        />                        
                    </div>
                </div>
                <ul className='list-group'>
                    { sidebarDisplayMode === 'category' && searchIssue.current.value === '' ?
                    Object.keys(props.stat).map((category, index) => {
                        return (
                            !['Summary', 'Internal', 'External'].includes(category) &&
                            <>
                                <a className='list-group-item list-group-item-action d-flex flex-column mb-2 border-0' index={index} role='button'
                                href={`#${category}-${index}`}
                                onClick={(e) => {e.preventDefault();
                                    if (category===open){
                                        setOpen('');
                                    }else{
                                        setOpen(category);
                                    }   
                                }}
                                >
                                    <div className='d-flex mb-2 w-100'>
                                        {category}
                                        <span className='ms-auto'>
                                            {Math.round(countIssue(props.stat, category))}
                                        </span>
                                    </div>
                                </a>
                                <Collapse in={open ===  category ? true : false}>
                                    <ul className="" id={category}>
                                        {Object.keys(props.stat[category]).map((issue, idx) => 
                                            // console.log(data[category][issue])
                                            parseInt(props.stat[category][issue]['value']) > 0 && props.stat[category][issue]['title'] !== 'All' &&
                                            <a class="list-group-item list-group-item-action d-flex align-items-center mb-2" index={idx} role='button'
                                                onClick={e => auditDetailHandler(category, issue, props.stat[category]['link'], props.stat[category][issue]['title'], props.stat[category][issue]['description'], props.stat[category][issue]['importance'], category)}
                                            >
                                                <span className={`me-3 rounded ${props.stat[category][issue]['importance'] === 'Critical' ? 'bg-danger' : props.stat[category][issue]['importance'] === 'Warning' ? 'bg-warning' : 'bg-light' }`} style={{width: '15px', height: '30px'}}> </span>
                                                <span className='align-self-center me-auto'>{props.stat[category][issue]['title']}: {props.stat[category][issue]['value']} pages</span>
                                            </a>
                                        )}
                                    </ul>
                                </Collapse>
                            </>
                        )
                    }) : searchIssue.current.value === '' ?
                    Object.keys(byPriority).map((priority, index) => {
                        return (
                            <>
                                <a className='list-group-item list-group-item-action d-flex flex-column mb-2 border-0' index={index} role='button'
                                href={`#${priority}-${index}`}
                                onClick={(e) => {e.preventDefault(); 
                                    if (priority===open){
                                        setOpen('');
                                    }else{
                                        setOpen(priority);
                                    }   
                                }}
                                >
                                    <div className='d-flex mb-2 w-100'>
                                        <span className={`me-3 rounded ${priority === 'Critical' ? 'bg-danger' : priority === 'Warning' ? 'bg-warning' : 'bg-light' }`} style={{width: '15px', height: '30px'}}> </span>
                                        {priority}
                                        <span className='ms-auto'>
                                            {Math.round(countIssue(byPriority, priority))}
                                        </span>
                                    </div>
                                </a>
                                <Collapse in={open ===  priority ? true : false}>
                                    <ul className="" id={priority}>
                                        {Object.keys(byPriority[priority]).map((issue, idx) => 
                                            // console.log(data[category][issue])
                                            parseInt(byPriority[priority][issue]['value']) > 0 && issue !== 'All' &&
                                            <a class="list-group-item list-group-item-action d-flex align-items-center mb-2" index={idx} role='button'
                                                onClick={e => auditDetailHandler(priority, issue, byPriority[priority][issue]['link'], byPriority[priority][issue]['title'], byPriority[priority][issue]['description'], priority,  byPriority[priority][issue]['category']) }
                                            >
                                                <span className='align-self-center me-auto'>{byPriority[priority][issue]['title']}: {byPriority[priority][issue]['value']} pages</span>
                                            </a>
                                        )}
                                    </ul>
                                </Collapse>
                            </>
                        )
                    }) :
                    Object.keys(filteredList).map((category, index) => {
                        return (
                            !['Summary', 'Internal', 'External'].includes(category) &&
                            <>
                                <a className='list-group-item list-group-item-action d-flex flex-column mb-2 border-0' index={index} role='button'
                                href={`#${category}-${index}`}
                                onClick={(e) => {e.preventDefault();
                                    if (category===open){
                                        setOpen('');
                                    }else{
                                        setOpen(category);
                                    }   
                                }}
                                >
                                    <div className='d-flex mb-2 w-100'>
                                        {sidebarDisplayMode === 'priority' && <span className={`me-3 rounded ${category === 'Critical' ? 'bg-danger' : category === 'Warning' ? 'bg-warning' : 'bg-light' }`} style={{width: '15px', height: '30px'}}> </span>}
                                        {category}
                                        <span className='ms-auto'>
                                            {Math.round(countIssue(filteredList, category))}
                                        </span>
                                    </div>
                                </a>
                                <Collapse in={open ===  category ? true : false}>
                                    <ul className="" id={category}>
                                        {Object.keys(filteredList[category]).map((issue, idx) => 
                                            // console.log(data[category][issue])
                                            parseInt(filteredList[category][issue]['value']) > 0 && filteredList[category][issue]['title'] !== 'All' &&
                                            <a class="list-group-item list-group-item-action d-flex align-items-center mb-2" index={idx} role='button'
                                                onClick={e => auditDetailHandler(category, issue, `${sidebarDisplayMode === 'category' ? filteredList[category]['link'] : filteredList[category][issue]['link']}`, filteredList[category][issue]['title'], filteredList[category][issue]['description'], filteredList[category][issue]['importance'], 
                                                `${sidebarDisplayMode === 'category' ? category : filteredList[category][issue]['category']}`
                                                ) }
                                            >
                                                {sidebarDisplayMode === 'category' && <span className={`me-3 rounded ${filteredList[category][issue]['importance'] === 'Critical' ? 'bg-danger' : filteredList[category][issue]['importance'] === 'Warning' ? 'bg-warning' : 'bg-light' }`} style={{width: '15px', height: '30px'}}> </span>}
                                                <span className='align-self-center me-auto'>{filteredList[category][issue]['title']}: {filteredList[category][issue]['value']} pages</span>
                                            </a>
                                        )}
                                    </ul>
                                </Collapse>
                            </>
                        )
                    })
                    }
                </ul>
            </div>
        </div>
        <hr />
        <div className='d-flex w-100 flex-column'>
            <h3 className='my-3'>URL Detail</h3>
            <AuditURL 
                data = {URLDetail}
            />
        </div>
        </>
    )
};

export default AuditIssue;